import PropTypes from 'prop-types';
import { Checkbox } from 'antd';

import TagBadge from 'components/TagBadge';

// checkbox with tag button
const TagCheckboxes = ({
  defaultChecked,
  handleTagChecked,
  className,
  tags,
  isSearching,
  searchedIds,
}) => {
  const list = tags.map((tagData) => {
    if (isSearching && !searchedIds.includes(tagData._id)) {
      return null;
    }

    return (
      <li className="tag-li" key={tagData._id}>
        <Checkbox
          defaultChecked={defaultChecked}
          className="tag-checkbox"
          onChange={(e) => {
            handleTagChecked(tagData._id, e.target.checked, tagData);
          }}>
          <TagBadge {...tagData} />
        </Checkbox>
      </li>
    );
  });

  if (!tags?.length) {
    return null;
  }

  return <div className={`${className || ''} tags-list`}>{list}</div>;
};

TagCheckboxes.propTypes = {
  defaultChecked: PropTypes.bool,
  handleTagChecked: PropTypes.func,
  className: PropTypes.string,
  tags: PropTypes.array,
};

export default TagCheckboxes;
