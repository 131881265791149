import React from 'react';
import { CompositeDecorator } from 'draft-js';
import PropTypes from 'prop-types';

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    if (entityKey) {
      const entity = contentState.getEntity(entityKey);
      const { type } = entity;
      return type === 'LINK';
    }
  }, callback);
}

findLinkEntities.defaultProps = {
  contentBlock: '',
  callback: () => {},
};

findLinkEntities.propTypes = {
  contentBlock: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  callback: PropTypes.func,
};

const Link = ({ entityKey, children, contentState }) => {
  const entity = contentState.getEntity(entityKey);
  const {
    data: { url },
  } = entity;

  return (
    <a
      href={url}
      style={{
        color: '#3b5998',
        textDecoration: 'underline',
      }}>
      {children}
    </a>
  );
};

Link.defaultProps = {
  entityKey: '',
};

Link.propTypes = {
  entityKey: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

const BRACKETS_REGEX = /{{((?:(?=([^{}]+|{{[^}]*}}))\2)*)}}/gi;

const findWithRegex = (regex, contentBlock, callback) => {
  const text = contentBlock.getText();
  let matchArr, start;
  // eslint-disable-next-line
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
};

function findVariableEntities(contentBlock, callback, contentState) {
  findWithRegex(BRACKETS_REGEX, contentBlock, callback);
}

findVariableEntities.defaultProps = {
  contentBlock: '',
  callback: () => {},
};

findVariableEntities.propTypes = {
  contentBlock: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  callback: PropTypes.func,
};

const Variable = (props) => {
  return (
    <span {...props} style={{ color: '#f03f3b', fontSize: '80%' }}>
      {props.children}
    </span>
  );
};

Variable.defaultProps = {
  entityKey: '',
};

Variable.propTypes = {
  entityKey: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
  {
    strategy: findVariableEntities,
    component: Variable,
  },
]);

export default decorator;
