import React from 'react';
import PropTypes from 'prop-types';
import { Button, Slider } from 'antd';

import TadaIcon from 'components/Icons/TadaIcon';

import './Upgrade.scss';

const daystrial = process.env.REACT_APP_DAYS_TRAIL || 14;

const Upgrade = ({ onPlanClick, upgradeValue, isNotActive, shouldShow, everPayed }) => {
  const numberOfDaysLeft = daystrial - Math.ceil(upgradeValue);

  return (upgradeValue > 0 && !isNotActive && shouldShow) || isNotActive ? (
    <li className="upgrade">
      <div className="upgrade-button-wrapper">
        <Button
          size="middle"
          type="button"
          className={`button ${window.location.pathname === '/plans' ? 'disable-upgrade' : ''}`}
          onClick={onPlanClick}
          disabled={window.location.pathname === '/plans' ? true : false}>
          <TadaIcon />
          {`${everPayed ? 'Renew' : 'Upgrade'} Now!`}
        </Button>
      </div>
      {upgradeValue > 0 && (
        <div className="upgrade-progress-wrapper">
          <span>
            <span>{daystrial - numberOfDaysLeft}</span> days left
          </span>
          <Slider
            defaultValue={numberOfDaysLeft}
            tooltipVisible={false}
            value={numberOfDaysLeft}
            min={0}
            max={daystrial}
          />
        </div>
      )}
    </li>
  ) : null;
};

Upgrade.defaultProps = {
  isNotActive: false,
  everPayed: 0,
};

Upgrade.propTypes = {
  onPlanClick: PropTypes.func.isRequired,
  upgradeValue: PropTypes.number.isRequired,
  isNotActive: PropTypes.bool,
  everPayed: PropTypes.number,
};

export default Upgrade;
