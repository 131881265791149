/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 12.7778H6.46499L8.16807 8.62963L10.2969 14.8519L13.2773 5L16.2577 19L17.535 12.7778H20"
      stroke="#05034D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ActivityNotificationIcon = (props) => <Icon component={svg} {...props} />;

export default ActivityNotificationIcon;
