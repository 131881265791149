import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Popover, Button } from 'antd';
import { sanitizeString } from 'utils/xss';

import TagBadge from 'components/TagBadge';
import { ADD_TAG, UPDATE_TAG, DELETE_TAG } from 'graphql/mutations/tagsMutation';
import CreateTags from './CreateTags';
import ManageTags from './ManageTags';

import './ProposalItemTag.scss';

const ProposalItemTag = ({
  tags,
  tagsData,
  pid,
  auid,
  visibleTags,
  setvisibleTags,
  handleKeyCommand,
  tagWrapperWidth,
  setTagsData,
  proposalsVar,
  proposalList,
  updateProposalTags,
  tagIdValues,
  setIgnoreUpdates,
  tagsFilter,
}) => {
  const [displayableTags, setDisplayableTags] = useState([]);
  const [tagsLoader, setTagsLoader] = useState(false);
  const [createTag, setCreateTag] = useState(false);
  const [editTags, setEditTags] = useState(false);
  const [selectedTagColor, setSelectedTagColor] = useState(false);
  const [openDeletePopover, setOpenDeletePopover] = useState(false);

  const [searchedCheckedIds, setSearchedCheckedIds] = useState([]);
  const [searchedUncheckedIds, setSearchedUncheckedIds] = useState([]);
  const [searchText, setSearchText] = useState('');

  const [proposalTags, setProposalTags] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [updatedProposal, setUpdatedProposal] = useState(null);

  useEffect(() => {
    if (!visibleTags) {
      setCreateTag(false);
      setEditTags(false);
      setOpenDeletePopover(false);

      if (updatedProposal) {
        updateProposalTags(updatedProposal, [...new Set(updatedProposal.tags)]);
        setUpdatedProposal(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleTags]);

  useEffect(() => {
    createDisplayableTags(tags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagWrapperWidth, tagIdValues, tags]);

  useEffect(() => {
    if (!visibleTags) {
      return;
    }
    const allTags = [];
    const proposalTagsId = {};
    const tagIds = Object.keys(tagIdValues);

    // tagsData - all tags
    if (tagIds && tagIds.length) {
      // const tagsIds = tags.map((t) => t._id);

      tagIds.forEach((id) => {
        if (tags.includes(id)) {
          proposalTagsId[id] = true;
        } else {
          allTags.push(tagIdValues[id]);
        }
      });

      setProposalTags(
        tags.reduce((acc, id) => {
          if (proposalTagsId[id]) {
            acc.push(tagIdValues[id]);
          }
          return acc;
        }, [])
      );

      setAllTags(allTags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagIdValues, tags, visibleTags]);

  const createDisplayableTags = (tags) => {
    if (!tags) {
      return;
    }
    if (tags.length < 2) {
      // 0 or 1 item display as it is
      setDisplayableTags(tags.map((id) => tagIdValues[id] || null).filter(Boolean));
    } else if (tagWrapperWidth) {
      // if tag wrapper width is calculated
      // then find how many tags can be diaplyed without going out the div
      const _displayableTags = [];
      let totalLength = 0;
      let deletedTags = [];

      tags.forEach((_id) => {
        const tag = tagIdValues[_id];
        if (tag) {
          // each character = 7px, padding and border = 14px, gap = 6px, last count tag width = 30px
          const itemLength = tag.name.length * 7 + 14 + 6;

          if (totalLength + itemLength < tagWrapperWidth - 30) {
            totalLength += itemLength;
            _displayableTags.push(tag);
          }
        } else {
          deletedTags.push(_id);
        }
      });

      setDisplayableTags(_displayableTags);

      // delete the tags from proposal, which might have failed to delete due to some reason
      if (deletedTags.length) {
        const newProposalList = proposalList.map((proposal) => {
          let newProposal = { ...proposal };

          if (newProposal?.tags) {
            // delete the tag from proposal
            newProposal.tags = newProposal.tags.filter((id) => !deletedTags.includes(id));
            updateProposalTags(newProposal, [...new Set(newProposal.tags)]);
          }

          return newProposal;
        });
        proposalsVar(newProposalList);
      }
    }
  };

  const createTagHandle = (tagName, color, tagType) => {
    tagName = sanitizeString(tagName.trim());

    if (!tagName.length || !color || !tagType) {
      return;
    }

    setTagsLoader(true);

    addTag({
      variables: {
        name: tagName,
        color: color,
        type: tagType,
        auid,
        pid: [pid],
      },
    });

    // add the tag to current proposal
    // setProposalTags((prev) => [...prev, addTag]);
  };

  const handleEditTag = (tagId, name, color, type) => {
    if (!tagId) {
      return;
    }

    const index = tagsData.findIndex((tag) => tag._id === tagId);
    if (index < 0) {
      return;
    }

    const newData = { ...tagsData[index] };

    if (color && type) {
      newData.color = color;
      newData.type = type;
    }
    if (name) {
      name = sanitizeString(name.trim());
      if (name) {
        newData.name = name;
      }
    }

    updateTag({
      variables: { ...newData },
    });

    // update current tag
    setProposalTags((prev) => {
      const newProposalTags = [...prev];
      return newProposalTags.map((tag) => {
        if (tag._id === tagId) {
          return { ...newData };
        }
        return tag;
      });
    });

    // update the tag in tagsData with latest value
    setTagsData((prev) => {
      const newTagData = [...prev];
      newTagData[index] = { ...newData };
      return newTagData;
    });
  };

  const handleTagChecked = (tagId, checked) => {
    const index = tagsData.findIndex((tag) => tag._id === tagId);
    const selectedTagData = { ...tagsData[index] };

    const newData = {
      ...selectedTagData,
    };

    if (checked) {
      addToProposalTags(tagId, newData);
    } else {
      removeFromProposalTags(tagId);
    }

    setAllTags((prevAllTags) => {
      let newAllTags = [...prevAllTags];

      if (checked) {
        // checked, remove from all tags
        newAllTags = newAllTags.filter((tag) => tag._id !== tagId);
      } else {
        // unchecked, add to all tags
        newAllTags = newAllTags.concat(newData);
      }

      return newAllTags;
    });

    // find all tags which are seelcted in filter
    let selectTags = [];
    if (!checked) {
      selectTags = tagsFilter.filter((t) => t._id && t.checked).map((t) => t._id);
    }

    // update current proposal in all proposals list
    const newProposalList = proposalList
      .map((proposal) => {
        let newproposal = { ...proposal };
        let shouldkeep = true;

        if (proposal._id === pid) {
          if (!newproposal?.tags) {
            newproposal.tags = [];
          }

          if (checked) {
            // checked, add to checked list
            newproposal.tags = [...new Set([...newproposal.tags, tagId])];
          } else {
            // unchecked, remove from checked list
            newproposal.tags = newproposal.tags.filter((id) => id !== tagId);

            if (selectTags.length) {
              // remove the proposal from list if it doesnt have any 1 of the filtered tag
              shouldkeep = selectTags.some((st) => newproposal.tags.includes(st));
            }
          }
          // proposal will be updated once modal is closed
          setUpdatedProposal(newproposal);
          setIgnoreUpdates((prev) => {
            const newData = { ...prev };
            const currentTime = Date.now();

            // delete expired keys
            Object.keys(newData).forEach((key) => {
              if (newData[key] < currentTime) {
                delete newData[key];
              }
            });

            // ignore accepting BE response for this proposal for next 15 seconds
            // (to prevent dancing effect)
            newData[pid] = currentTime + 15000;

            return newData;
          });
        }

        if (!shouldkeep) {
          // update proposal before it is deleted from ui
          updateProposalTags(newproposal, [...new Set(newproposal.tags)]);
          return null;
        }

        return newproposal;
      })
      .filter(Boolean);
    proposalsVar(newProposalList);
  };

  const deleteTagHandle = (tagId) => {
    setOpenDeletePopover('');

    deleteTag({
      variables: { _id: tagId },
    });

    // remove the tag from current proposal
    removeFromProposalTags(tagId);

    // remove from all proposals
    const newProposalList = proposalList.map((proposal) => {
      // remove the delete tag from all tags from proposal
      if (proposal?.tags) {
        proposal.tags = proposal.tags.filter((id) => id !== tagId);
      }
      return proposal;
    });
    proposalsVar(newProposalList);

    // remove from tags data
    setTagsData((prev) => {
      return prev.filter((f) => f._id !== tagId);
    });
  };

  const addToProposalTags = (tagId, newData) => {
    setProposalTags((prevProposalTags) => {
      let newProposalTags = [...prevProposalTags];

      const isExits = newProposalTags.find((p) => p._id === tagId);
      if (!isExits) {
        return newProposalTags.concat(newData);
      }

      return newProposalTags;
    });
  };

  const removeFromProposalTags = (tagId) => {
    setProposalTags((prev) => {
      return prev.filter((f) => f._id !== tagId);
    });
  };

  const [addTag] = useMutation(ADD_TAG, {
    onCompleted: ({ addTag }) => {
      setTagsLoader(false);
      setCreateTag(false);
      setSearchText('');

      // add the tag to tags data
      setTagsData((prev) => [...prev, addTag]);

      // add the tag to current proposl
      const newProposalList = proposalList.map((proposal) => {
        if (proposal._id === pid) {
          const newProposal = { ...proposal };
          // add the new tag to current proposal
          if (newProposal?.tags) {
            newProposal.tags = [...newProposal.tags, addTag._id];
          } else {
            newProposal.tags = [addTag._id];
          }
          return newProposal;
        }
        return proposal;
      });
      proposalsVar(newProposalList);
    },
    onError: (err) => {
      console.log('error', err);
    },
  });

  const [updateTag] = useMutation(UPDATE_TAG, {
    onCompleted: () => {},
    onError: (err) => {
      console.log('error', err);
    },
  });

  const [deleteTag] = useMutation(DELETE_TAG, {
    onCompleted: () => {},
    onError: (err) => {
      console.log('error', err);
    },
  });

  const handleKeyCommandTags = (e) => {
    if (e.key === 'Escape') {
      window.removeEventListener('keydown', handleKeyCommand, true);
      setvisibleTags(false);
    }
  };

  const close = () => {
    setvisibleTags(false);
    window.removeEventListener('keydown', handleKeyCommandTags, true);
  };

  return (
    <Popover
      trigger="click"
      placement="right"
      destroyTooltipOnHide={true}
      overlayClassName="tag-popover"
      onVisibleChange={() => {
        if (visibleTags) {
          close();
        } else {
          setvisibleTags(true);
          window.addEventListener('keydown', handleKeyCommandTags, true);
        }
      }}
      visible={visibleTags}
      content={
        <div className="body">
          <div className="closeable-mask" onClick={close} />

          {createTag || createTag === '' ? (
            <CreateTags
              createTag={createTag}
              setCreateTag={setCreateTag}
              createTagHandle={createTagHandle}
              tagsLoader={tagsLoader}
            />
          ) : (
            <ManageTags
              visibleTags={visibleTags}
              setCreateTag={setCreateTag}
              proposalTags={proposalTags}
              allUncheckedTags={allTags}
              editTags={editTags}
              setEditTags={setEditTags}
              handleEditTag={handleEditTag}
              selectedTagColor={selectedTagColor}
              setSelectedTagColor={setSelectedTagColor}
              deleteTag={deleteTagHandle}
              handleTagChecked={handleTagChecked}
              openDeletePopover={openDeletePopover}
              setOpenDeletePopover={setOpenDeletePopover}
              searchText={searchText}
              setSearchText={setSearchText}
              searchedCheckedIds={searchedCheckedIds}
              setSearchedCheckedIds={setSearchedCheckedIds}
              searchedUncheckedIds={searchedUncheckedIds}
              setSearchedUncheckedIds={setSearchedUncheckedIds}
            />
          )}
        </div>
      }>
      <div className="proposal-tags" onClick={(e) => e.stopPropagation()}>
        {displayableTags?.length ? (
          <>
            {displayableTags.map((tag) => (
              <TagBadge
                className="list-tags"
                key={tag._id}
                type={tag.type}
                color={tag.color}
                name={
                  tagWrapperWidth < 160 && tag.name.length > 10
                    ? `${tag.name.substring(0, 10)}...`
                    : tag.name
                }
              />
            ))}
            {displayableTags.length !== tags.length && (
              <TagBadge
                className="list-tags count-tag"
                type="border"
                color="#006655"
                name={`+${tags.length - displayableTags.length}`}
              />
            )}
          </>
        ) : (
          <Button className="add-tags">+ Add Tag</Button>
        )}
      </div>
    </Popover>
  );
};

ProposalItemTag.propTypes = {
  tags: PropTypes.array,
  tagsData: PropTypes.array,
  pid: PropTypes.string,
  auid: PropTypes.string,
  visibleTags: PropTypes.bool,
  setvisibleTags: PropTypes.func,
  handleKeyCommand: PropTypes.func,
  tagWrapperWidth: PropTypes.number,
  setTagsData: PropTypes.func,
  proposalsVar: PropTypes.func,
  proposalList: PropTypes.array,
  updateProposalTags: PropTypes.func,
  tagIdValues: PropTypes.object,
};

export default ProposalItemTag;
