import React from 'react';
import Icon from '@ant-design/icons';

const BellIcon = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 15V14.5H10H7H6.5V15C6.5 15.4953 6.80115 15.8826 7.16199 16.1231C7.52632 16.366 8.00084 16.5 8.5 16.5C8.99916 16.5 9.47368 16.366 9.83801 16.1231C10.1988 15.8826 10.5 15.4953 10.5 15Z"
      fill="white"
      stroke="#05034D"
    />
    <rect x="7" width="3" height="3" rx="1.5" fill="#05034D" />
    <path
      d="M14.25 11.5625V11.7116L14.3316 11.8363L15.5686 13.7262C15.7863 14.0587 15.5477 14.5 15.1503 14.5H1.84971C1.45228 14.5 1.2137 14.0587 1.43136 13.7262L2.66835 11.8363L2.75 11.7116V11.5625V8.125V7.25C2.75 4.07436 5.32436 1.5 8.5 1.5C11.6756 1.5 14.25 4.07436 14.25 7.25V8.125V11.5625Z"
      fill="white"
      stroke="#05034D"
    />
    <rect x="5" y="11" width="7" height="1" rx="0.5" fill="#05034D" />
  </svg>
);

const NotificationIcon = (props) => <Icon component={BellIcon} {...props} />;

export default NotificationIcon;
