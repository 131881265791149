import React from 'react';
import Icon from '@ant-design/icons';

const HelpSvg = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2" y="5" width="33" height="27" rx="8" fill="#7876C1" />
    <path
      d="M20.0446 30H25.7534C26.3931 30 26.8684 30.5924 26.7296 31.2169L25.461 36.9257C25.2774 37.7517 24.2149 37.9906 23.6954 37.3227L19.2553 31.6139C18.7444 30.9571 19.2125 30 20.0446 30Z"
      fill="#7876C1"
    />
    <path
      d="M19.4765 9C21.1344 9 22.4654 9.46531 23.4697 10.3959C24.4899 11.3102 25 12.5755 25 14.1918C25 15.7918 24.4979 17.0163 23.4936 17.8653C22.4893 18.698 21.1583 19.1143 19.5004 19.1143L19.4048 22H16.4637L16.3442 16.8367H17.4202C18.807 16.8367 19.875 16.649 20.6243 16.2735C21.3735 15.898 21.7481 15.2122 21.7481 14.2163C21.7481 13.498 21.5408 12.9265 21.1264 12.502C20.7279 12.0776 20.1779 11.8653 19.4765 11.8653C18.7432 11.8653 18.1694 12.0694 17.7549 12.4776C17.3564 12.8857 17.1571 13.449 17.1571 14.1673H14.0009C13.9849 13.1714 14.1921 12.2816 14.6225 11.498C15.0529 10.7143 15.6826 10.102 16.5115 9.66122C17.3564 9.22041 18.3447 9 19.4765 9Z"
      fill="#C7BCE4"
    />
    <circle cx="18" cy="26" r="2" fill="#C7BCE4" />
  </svg>
);

const HelpIcon = (props) => <Icon component={HelpSvg} {...props} />;

export default HelpIcon;
