import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select, message } from 'antd';
import { useMutation } from '@apollo/client';

import Popconfirm from 'components/PopConfirm';

import { SHARE_PROPOSAL_TEMPLATE_TO_TEAM } from 'graphql/mutations/usersTeamMutation';

import './ShareWithModal.scss';

const body = (form, handleSubmit, teamMembers) => {
  return (
    <Form
      name="normal_login"
      className="share-with-modal-form"
      layout="vertical"
      form={form}
      onFinish={handleSubmit}>
      <Form.Item
        label="Select Members to share with"
        name="members"
        rules={[{ required: true, message: 'Please select member!' }]}>
        <Select placeholder="Select Members" mode="multiple" allowClear>
          {teamMembers.map((teamMember) => (
            <Select.Option key={teamMember._id} value={teamMember._id}>
              {teamMember?.profile?.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

const ShareWithModal = ({ visible, onCancel, template, proposal, teamMembers, isLocked }) => {
  const [form] = Form.useForm();

  const [shareProposalTemplate] = useMutation(SHARE_PROPOSAL_TEMPLATE_TO_TEAM, {
    onCompleted() {
      message.success(`${template ? 'Template' : 'Proposal'} shared successfully!`);
      onCancel();
    },
  });

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      let variables = {
        members: values.members,
      };

      if (template) {
        variables['template'] = {
          _id: template._id,
          name: template.templateName,
        };
      } else {
        variables['proposal'] = {
          _id: proposal._id,
          name: proposal.name,
        };
      }

      if (!isLocked) {
        shareProposalTemplate({
          variables,
        });
      } else {
        message.error(`Share failed!`);
      }
    });
  };

  return (
    <Popconfirm
      title={`Share ${template ? 'Template' : 'Proposal'} with Team!`}
      body={body(form, handleSubmit, teamMembers)}
      visible={visible}
      cancelText="CANCEL"
      confirmText="SHARE"
      onCancel={onCancel}
      onConfirm={form.submit}
    />
  );
};

ShareWithModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  template: PropTypes.instanceOf(Object),
  proposal: PropTypes.instanceOf(Object),
  teamMembers: PropTypes.instanceOf(Array),
  isLocked: PropTypes.bool,
};

export default ShareWithModal;
