import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Empty, message, Avatar, Tooltip, notification, Table, Divider, Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { useIntercom } from 'react-use-intercom';
// import { useMutation, useQuery, useLazyQuery, useReactiveVar } from '@apollo/client';
import {
  useMutation,
  useQuery,
  useSubscription,
  useLazyQuery,
  useReactiveVar,
} from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
import copy from 'copy-to-clipboard';

import ProposalItem from './components/ProposalItem';
import DashboardHeader from './components/DashboardHeader';
import ScrollToTopButton from 'components/ScrollToTopButton';
import ProposalItemLoader from './components/ProposalItemLoader';
import useWindowDimensions from 'hooks/useWindowDimensions';
import DownloadingIcon from 'components/Icons/DownloadingIcon';
import { ZeroState } from 'components/Icons';
import IconButton from 'components/IconButton';

import commonUtils from 'utils/utils';
import DashboardModals from './DashboardModals';

import {
  GET_ALL_PROPOSALS,
  PROPOSAL_CSV,
  DOWNLOAD_PROPOSAL,
} from 'graphql/queries/proposalQueries';
import {
  getProposalTitle,
  getClientName,
  getSeenTimeDetails,
  findAllProposalStatus,
  proposalsByMonthAndYear,
  getCompletion,
  findDraftObject,
} from 'helpers/proposal';
import {
  PROPOSAL_STATUS,
  PROPOSAL_FILTER_ITEMS,
  PROPOSAL_REPORT_FILTER_ITEMS,
  PROPOSALS_LIMIT,
  PROPOSAL_SUBSCRIPTION_TOPIC,
  // POLLING_INTERVAL,
} from 'constants/index';
import {
  getUserId,
  userVar,
  proposalsVar,
  teamMembersVar,
  proposalsStatsVar,
  proposalsCountVar,
} from 'graphql/cache';
import { USER_DETAILS } from 'graphql/queries/userQueries';
import { UPDATE_PROPOSAL_MUTATION } from 'graphql/mutations/proposalMutation';
import { FETCH_TEAM_MEMBERS } from 'graphql/queries/usersTeamQueries';
import { FETCH_TAGS } from 'graphql/queries/tagsQueries';
// import { PING_POLLING } from 'graphql/queries/pollingQueries';
import { UPDATE_DASHBOARD_PROPOSAL_SUBSCRIPTION } from 'graphql/queries/proposalSubscription';
import { getUserShortName } from 'helpers/users';
import helpers from 'helpers/proposal';

import './Dashboard.scss';

const ProposalList = ({ props, loading, onClickNewProposal, filterApplied, isLocked }) => {
  const [prop, trash] = props;
  const proposalMessage =
    trash === true
      ? 'There are no proposals in the trash'
      : 'There are no proposals in this selected filter';

  if (loading && prop?.length === 0) {
    return null;
  }

  if (prop?.length > 0) {
    return prop;
  }

  return (
    <React.Fragment>
      {filterApplied ? (
        <Empty className="empty-view" description={proposalMessage} />
      ) : (
        <div className="no-proposals">
          <ZeroState className="feature-svg" />

          <p className="text">
            Create beautiful proposals with ease. Impress your clients and win more projects.
          </p>
          <a
            className="link"
            href="https://www.youtube.com/watch?v=0X7RfkLz7Zw"
            target="_blank"
            rel="noreferrer">
            🎓 Watch a short tutorial
          </a>

          <IconButton
            disabled={isLocked}
            text="NEW PROPOSAL"
            className={`new-proposal-btn ${isLocked ? 'disabled' : ''}`}
            onButtonClick={onClickNewProposal}
          />
        </div>
      )}
    </React.Fragment>
  );
};

const findFilteredItems = ({ filterItems }) => {
  if (filterItems[0].checked === true) {
    return [];
  }

  const checked = filterItems.some((f) => f.checked === true);
  // if none is checked then it should work like [All proposals] is checked
  if (!checked) {
    return [];
  }

  return filterItems;
};

const findFilteredTags = ({ tagsFilter, tagsType }) => {
  const tagsFill = [];
  if (tagsFilter?.length) {
    for (const tag of tagsFilter) {
      if (tag.checked && tag._id !== 0) {
        tagsFill.push(tag._id);
      }
    }
  }

  return {
    _ids: tagsFill,
    type: tagsType,
  };
};

const headers = [
  { label: 'Project Name', key: 'name' },
  { label: 'Prospect Name', key: 'clientName' },
  { label: 'Watched', key: 'watched' },
  { label: 'Opened', key: 'opened' },
  { label: 'Status', key: 'status' },
  { label: 'Quote', key: 'quote' },
  { label: 'Prospect IP', key: 'clientIP' },
];

const Dashboard = () => {
  const history = useHistory();
  const refToTop = useRef();
  const { trackEvent } = useIntercom();

  const user = useReactiveVar(userVar);
  const proposals = useReactiveVar(proposalsVar || []);
  const teamMembers = useReactiveVar(teamMembersVar);
  const proposalsStats = useReactiveVar(proposalsStatsVar);
  const proposalsCount = useReactiveVar(proposalsCountVar);

  const [selectedProposalId, setProposalId] = useState('');
  const [confirmModal, showConfirmModal] = useState(false);
  const [permissionErrorModal, showPermissionErrorModal] = useState(false);
  const [trash, showTrash] = useState(false);
  const [duplicateModal, showDuplicateModal] = useState(false);
  const [restoreModal, showRestoreModal] = useState(false);
  const [disabledEditingModal, setDisabledEditingModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filterItems, setFilterItems] = useState(PROPOSAL_FILTER_ITEMS);
  const [tagsData, setTagsData] = useState([]);
  const [tagsFilter, setTagsFilter] = useState([]);
  const [tagIdValues, setTagIdValues] = useState({});
  // const [lastUpdate, setLastUpdate] = useState('');

  const [reportItems] = useState([
    {
      id: 'current',
      name: `${moment().subtract(1, 'months').format('MMM YY')} - ${moment().format(
        'MMM D, YYYY'
      )}`,
      key: 'current',
    },
    ...PROPOSAL_REPORT_FILTER_ITEMS,
  ]);
  const [selectedDate, setSelectedDate] = useState([]);
  const [showScrollTop, showHideScrollTop] = useState(false);
  const [offset, setOffset] = useState(0);
  const [showItemLoader, setShowItemLoader] = useState(false);
  const [showAddProposalModal, setShowAddProposalModal] = useState(false);
  const [userInfoModal, setUserInfoModal] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [generateInvoiceModal, setGenerateInvoiceModal] = useState(false);
  const [popConfirmDetails, setPopConfirmDetails] = useState({ show: false });
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [proposalCSVData, setProposalCSVData] = useState([]);
  const [selectedCSVDuration, setSelectedCSVDuration] = useState('');
  const [showShareProposalModal, setShowShareProposalModal] = useState('');
  const [moveModal, showMoveModal] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [tagsType, setTagsType] = useState('or');
  const [tagWrapperWidth, setTagWrapperWidth] = useState(0);
  // ignore the updates from BE
  const [ignoreUpdates, setIgnoreUpdates] = useState({});

  const { allowedEditing } = useWindowDimensions();
  const downloadRef = useRef(null);

  const [fetchAllProposals, { loading: isFetching, data, fetchMore, refetch }] = useLazyQuery(
    GET_ALL_PROPOSALS,
    {
      // notifyOnNetworkStatusChange: true,
      variables: {
        limit: PROPOSALS_LIMIT * (window.innerHeight >= 1250 ? 2 : 1),
        offset: 0,
        search: searchValue,
        teamList: teamList,
        filterTags: findFilteredTags({ tagsFilter, tagsType }),
        selectedDate,
        filterItems: findFilteredItems({ filterItems }),
      },
      fetchPolicy: 'no-cache',
      skip: !!proposals?.length,
      onCompleted: (data) => {
        if (data?.fetchProposals) {
          proposalsStatsVar(findAllProposalStatus(data.fetchProposals.proposalsStats[0]));
          proposalsVar(processPropsals(proposals, data.fetchProposals.proposals));
          proposalsCountVar(data.fetchProposals.proposalsCount);

          if (searchValue || selectedDate?.length || findFilteredItems({ filterItems }).length) {
            setFilterApplied(true);
          } else {
            setFilterApplied(false);
          }

          scrollToTop();
        }
      },
    }
  );

  const processPropsals = (oldProposals, proposals) => {
    const currentTime = Date.now();

    return proposals.map((proposal) => {
      // check whether to ignore the updates
      if (ignoreUpdates?.[proposal._id] > currentTime) {
        // ignore the new value from BE, preserve the current value from FE
        const oldProposal = oldProposals.find((p) => p._id === proposal._id);
        if (oldProposal?.tags) {
          proposal.tags = oldProposal.tags;
        }
      }

      return proposal;
    });
  };

  const [refetchUser] = useLazyQuery(USER_DETAILS, {
    variables: { id: getUserId() },
    skip: user || !getUserId(),
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (res) => {
      const { fetchUser } = res;
      userVar({ ...user, ...fetchUser });
    },
  });

  useEffect(() => {
    fetchAllProposals();
  }, [fetchAllProposals]);

  useEffect(() => {
    setTagIdValues(
      tagsData.reduce((acc, tag) => {
        acc[tag._id] = tag;
        return acc;
      }, {})
    );
  }, [tagsData]);

  useQuery(FETCH_TAGS, {
    variables: {
      auid: user?.teamId || user?._id,
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ fetchTags }) => {
      setTagsData(fetchTags);
    },
  });

  const refetchProposal = useCallback(
    async (searchValue, teamList, selectedDate, filterItems, tagsFilter, shouldUpdate = true) => {
      if (user?.paymentStatus?.canLock) {
        return;
      }

      const refetchProposalData = await refetch({
        limit: PROPOSALS_LIMIT * (window.innerHeight >= 1250 ? 2 : 1),
        offset: 0,
        search: searchValue,
        teamList: teamList,
        filterTags: findFilteredTags({ tagsFilter, tagsType }),
        selectedDate,
        filterItems: findFilteredItems({ filterItems }),
      });

      if (shouldUpdate && refetchProposalData?.data?.fetchProposals) {
        if (refetchProposalData?.data.fetchProposals?.proposalsStats?.length) {
          proposalsStatsVar(
            findAllProposalStatus(refetchProposalData?.data.fetchProposals.proposalsStats[0])
          );
        }
        proposalsVar(processPropsals(proposals, refetchProposalData.data.fetchProposals.proposals));
        proposalsCountVar(refetchProposalData.data.fetchProposals.proposalsCount);

        scrollToTop(); // scroll when new proposal loads
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refetch, user, tagsType, tagsFilter, proposals]
  );

  const [updateProposal] = useMutation(UPDATE_PROPOSAL_MUTATION, {
    onCompleted: ({ updateProposal }) => {
      let updatedProposals = [];
      const prevProposals = proposals.filter((prop) => prop._id !== updateProposal._id);
      const prevProposal = proposals.find((prop) => prop._id === updateProposal._id);
      updateProposal = prevProposal ? { ...prevProposal, ...updateProposal } : updateProposal;
      updatedProposals = [updateProposal, ...prevProposals];
      updatedProposals = updatedProposals.sort((p1, p2) => new Date(p2.date) - new Date(p1.date));

      if (
        popConfirmDetails?.show &&
        (popConfirmDetails?.type === 'decline' ||
          popConfirmDetails?.type === 'approve' ||
          popConfirmDetails?.type === 'state')
      ) {
        refetchProposal(searchValue, teamList, selectedDate, filterItems);

        setPopConfirmDetails((prevState) => ({
          ...prevState,
          show: false,
          type: '',
        }));

        message.success(
          `Proposal ${
            popConfirmDetails?.type === 'decline'
              ? 'declined'
              : popConfirmDetails?.type === 'state'
              ? 'state'
              : 'approved'
          } successfully update`
        );
      }
      proposalsVar(processPropsals(proposals, updatedProposals));
    },
  });

  const handleTeamMembersList = (fetchTeamMembers) => {
    let tempTeamList = [
      {
        id: user._id,
        name: 'By Me',
        checked: false,
      },
    ];
    fetchTeamMembers.forEach((userTeam) => {
      if (userTeam._id !== user._id) {
        tempTeamList.push({
          id: userTeam._id,
          name: userTeam.profile.name,
          checked: false,
        });
      }
    });
    if (user.roles && user.roles.includes('editor')) {
      tempTeamList.push({
        id: 'sharedwithme',
        name: 'Shared with Me',
        checked: false,
      });
    }
    setTeamList(tempTeamList);
  };

  const [loadTeamMembers, { loading: isFetchingTeamMembers }] = useLazyQuery(FETCH_TEAM_MEMBERS, {
    variables: user?.roles?.includes('manager') ? { teamId: user?.teamId } : null,
    skip: !getUserId() || user || !teamMembers.length,
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ fetchTeamMembers }) => {
      handleTeamMembersList(fetchTeamMembers);
      teamMembersVar(fetchTeamMembers);
    },
  });

  useSubscription(UPDATE_DASHBOARD_PROPOSAL_SUBSCRIPTION, {
    variables: { topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${user?.teamId || user?._id}` },
    onSubscriptionData: ({ subscriptionData }) => {
      console.log('is subscription data coming', subscriptionData);
      if (!subscriptionData.data) return proposals;

      let { typename, proposal } = subscriptionData.data.updateProposalSubscription;
      let updatedProposals = [];

      if (
        user._id !== proposal.uid &&
        user.teamId !== proposal.auid &&
        user._id !== proposal.auid &&
        typename !== 'DELETE_PROPOSAL'
      )
        return;

      const prevProposals = proposals.filter((prop) => prop._id !== proposal._id);

      if (prevProposals && proposal) {
        if (typename === 'UPDATE_PROPOSAL') {
          const prevProposal = proposals.find((prop) => prop._id === proposal._id);
          proposal = prevProposal ? { ...prevProposal, ...proposal } : proposal;
          updatedProposals = [proposal, ...prevProposals];
        } else if (typename === 'DELETE_PROPOSAL')
          updatedProposals = prevProposals.filter((prop) => prop._id !== proposal._id);
        else if (typename === 'RESTORE_PROPOSAL') {
          updatedProposals = prevProposals.filter((prop) => prop._id !== proposal._id);
          showRestoreModal(false);
        } else updatedProposals = [proposal, ...prevProposals];

        updatedProposals = updatedProposals.sort((p1, p2) => new Date(p2.date) - new Date(p1.date));

        proposalsVar(processPropsals(proposals, updatedProposals));
      }
    },
  });

  /*
   * Remove polling
   *
  const {
    data: pollingResponse,
    // startPolling,
    // stopPolling,
  } = useQuery(PING_POLLING, {
    variables: {
      from: 'Dashboard',
      userId: getUserId() || '',
      teamId: user?.teamId || '',
    },
    pollInterval: POLLING_INTERVAL.dashboard * 1000,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (pollingResponse) {
      let typename,
        proposal = undefined;
      typename = pollingResponse?.pingPolling?.typename;
      proposal = pollingResponse?.pingPolling?.proposal;
      if (
        typename &&
        typename !== 'pong' &&
        lastUpdate !== JSON.stringify(pollingResponse?.pingPolling)
      ) {
        setLastUpdate(JSON.stringify(pollingResponse?.pingPolling));
        let updatedProposals = [];

        if (
          user?._id === proposal?.uid ||
          user?.teamId === proposal?.auid ||
          user?._id === proposal?.auid ||
          (user?.roles?.includes('manager') && user?.teamId === proposal?.uid)
        ) {
          const prevProposals = proposals.filter((prop) => prop._id !== proposal._id);

          if (prevProposals && proposal) {
            if (typename === 'UPDATE_PROPOSAL') {
              const prevProposal = proposals.find((prop) => prop._id === proposal._id);
              proposal = prevProposal ? { ...prevProposal, ...proposal } : proposal;
              updatedProposals = [proposal, ...prevProposals];
            } else if (typename === 'DELETE_PROPOSAL')
              updatedProposals = prevProposals.filter((prop) => prop._id !== proposal._id);
            else if (typename === 'RESTORE_PROPOSAL') {
              updatedProposals = prevProposals.filter((prop) => prop._id !== proposal._id);
            } else updatedProposals = [proposal, ...prevProposals];

            updatedProposals = updatedProposals.sort(
              (p1, p2) => new Date(p2.date) - new Date(p1.date)
            );

            proposalsVar(updatedProposals);
          }
        }
      }
    }
  }, [pollingResponse, proposals, user, lastUpdate, setLastUpdate]);

  */

  /*
   * No manual start and stop polling 
   *
  useEffect(() => {
    startPolling(POLLING_INTERVAL.dashboard * 1000);
    return () => {
      stopPolling();
    };
  }, [stopPolling, startPolling]);
  */

  useEffect(() => {
    document.title = 'Prospero - Dashboard';
  });

  const fetchMoreProposal = async (type = '') => {
    let page = offset;

    // making api cal after delete
    if (type === 'delete') {
      // skip fetching new data if there are enough proposal on page
      if (proposals.length >= PROPOSALS_LIMIT) {
        return;
      }
    }

    // reset offsets when loaded proposals resets
    if (
      proposals.length < proposalsCount &&
      Math.ceil(proposalsCount / PROPOSALS_LIMIT) === page &&
      !showItemLoader
    ) {
      proposalsCountVar(proposals.length);
      setOffset(0);
    }

    if (
      (proposalsCount === null || Math.ceil(proposalsCount / PROPOSALS_LIMIT) > page) &&
      fetchMore &&
      proposals.length <= proposalsCount &&
      !showItemLoader
    ) {
      setShowItemLoader(true);

      let refetchProposalData = await fetchMore({
        variables: {
          limit: PROPOSALS_LIMIT * (window.innerHeight >= 1250 && !page ? 2 : 1),
          offset: page,
          search: searchValue,
          selectedDate,
          teamList: teamList,
          filterItems: findFilteredItems({ filterItems }),
        },
      });

      proposalsCountVar(refetchProposalData.data.fetchProposals.proposalsCount);
      setOffset(offset + 1);
      setShowItemLoader(false);
    }
  };

  useEffect(() => {
    // function getScrollbarWidth() {
    //   const scrollDiv = document.createElement('div');
    //   scrollDiv.style.overflow = 'scroll';
    //   document.body.appendChild(scrollDiv);
    //   const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    //   document.body.removeChild(scrollDiv);
    //   return scrollbarWidth;
    // }

    const dashboardHeader = document.querySelector('.dashboard-header');
    const mainContent = document.querySelector('.item-list');
    async function onScroll() {
      if (mainContent.scrollTop > 0) {
        showHideScrollTop(true);
      } else {
        showHideScrollTop(false);
      }
      if (mainContent.scrollTop > 80) {
        dashboardHeader.classList.add('hide-items');
      } else {
        dashboardHeader.classList.remove('hide-items');
      }

      if (
        mainContent.scrollTop + mainContent.offsetHeight + 100 >
        mainContent?.scrollHeight
        // NOTE : might break something, not aware of
        // !user?.paymentStatus?.canLock &&
      ) {
        fetchMoreProposal();
      }
    }

    function onResize() {
      // dashboardHeader.style.width = `calc(100vw - ${146 + getScrollbarWidth()}px)`;
      // dashboardHeader.parentElement.style.paddingTop = `${
      //   dashboardHeader.clientTop + dashboardHeader.clientHeight
      // }px`;
    }

    onResize();
    mainContent.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onResize);

    return () => {
      mainContent.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', onResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterItems,
    offset,
    searchValue,
    selectedDate,
    proposalsCount,
    teamList,
    user?.paymentStatus?.canLock,
    proposals,
    showItemLoader,
  ]);

  useEffect(() => {
    if (user && user.profile && (!user.profile.terms || !user.ptype)) {
      setUserInfoModal(true);
    }

    if (user && !teamMembers.length) {
      loadTeamMembers();
    } else if (teamMembers.length) {
      handleTeamMembersList(teamMembers);
    }

    if (user) {
      window.ga('send', 'event', 'dashboard', 'init', window.location.href);
      trackEvent('dashboard');
    }

    return () => {
      if (proposals?.length) proposalsVar([]);
    };
  }, [user, loadTeamMembers]); //eslint-disable-line

  // useEffect(() => {
  //   refetchProposal();
  // }, [filterItems, searchValue, teamList, selectedDate, refetchProposal]);

  const downloadCSV = useCallback(() => {
    setTimeout(() => {
      notification.close('download-report-notification');
      downloadRef.current?.link?.click();
      setProposalCSVData([]);
    });
  }, [setProposalCSVData]);

  useEffect(() => proposalCSVData.length && downloadCSV(), [proposalCSVData, downloadCSV]);

  const [getProposalCSV] = useLazyQuery(PROPOSAL_CSV, {
    fetchPolicy: 'no-cache',
    skip: !selectedCSVDuration,
    onCompleted: ({ fetchProposalCSV }) => {
      if (!fetchProposalCSV.length) {
        notification.close('download-report-notification');
        message.error(
          `No proposals were found within the specified date range for download. Please choose alternative dates.`
        );
      } else {
        setProposalCSVData(fetchProposalCSV);
      }
      setSelectedCSVDuration('');
    },
    onError: (error) => {
      message.error(`Report download failed: ${error.message}`);
      notification.close('download-report-notification');
      setSelectedCSVDuration('');
    },
  });

  useEffect(() => {
    // on tagsData chnage update the tags filter
    setTagsFilter((prev) => {
      const newData = tagsData.map((tag) => {
        // check the checked status
        const existingTag = prev.find((t) => t._id === tag._id);

        return {
          ...tag,
          checked: existingTag?.checked ?? false,
        };
      });

      if (newData?.[0]?.name !== 'All Tags') {
        newData.unshift({ name: 'All Tags', _id: 0, checked: true });
      }

      return newData;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagsData]);

  useEffect(() => {
    if (selectedCSVDuration) {
      getProposalCSV({ variables: { duration: selectedCSVDuration } });
    }
  }, [selectedCSVDuration]); //eslint-disable-line

  const [downloadPdf] = useLazyQuery(DOWNLOAD_PROPOSAL, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      window.open(data.downloadProposal, '_self');
      notification.close('download-report-notification');
    },
    onError: (error) => {
      commonUtils.showErrorMessage({
        title: 'Problem Downloading PDF',
        message: `Please try in a few minutes or contact support. Error ${error.message}`,
      });
    },
  });

  const scrollToTop = () => {
    refToTop.current.scroll({ behavior: 'smooth', top: 0 });
  };

  const editProposal = ({ proposalId }) => {
    const proposal = proposals.find((item) => item.pid === proposalId);

    if (!proposal) {
      message.error('Something went wrong');
      return;
    }
    const { version = 0 } = proposal;

    if (version < 5) {
      message.error(
        'This version of Prospero does not support this old proposal, it can no longer be edited'
      );
      return;
    }

    if (!allowedEditing) {
      setDisabledEditingModal(true);
      return;
    }

    const { delivcomplete, milestonescomplete } = getCompletion({ proposal });
    const allcomplete = delivcomplete && milestonescomplete;

    if (proposal.state === 'published' || proposal.state === 'approved') {
      history.push(`/d/${proposalId}`);
    } else {
      if (allcomplete) {
        if (version > 1 || proposal.draft) {
          // const propsigneddate = proposal.signature && proposal.signature.date;
          history.push(`/d/${proposalId}`);
        }
      } else {
        history.push(`/w/${proposalId}`);
      }
    }
  };

  const updateProposalTitle = ({ proposalId, value }) => {
    const index = proposals.findIndex((item) => item.pid === proposalId);
    if (index < 0) {
      return;
    }
    const proposal = proposals[index];

    const { _id } = proposal;

    const { draft } = findDraftObject({
      what: {
        'draft.header.title': value,
        'draft.header.rawtitle': null,
      },
      proposal,
    });

    const project = {
      ...proposal.project,
      name: value,
    };

    // update title instantly
    proposals[index].project = project;
    proposalsVar(proposals);

    updateProposal({
      variables: {
        topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${
          proposal?.channel || proposal?.auid || proposal?.uid
        }`,
        updateType: 'edit_title',
        proposal: {
          id: _id,
          _id,
          pid: proposalId,
          uid: proposal.uid,
          draft,
          edited: new Date(),
          project,
          editEvent: proposal.editEvent,
        },
      },
    });
  };

  const updateProposalTags = (proposal, tags) => {
    updateProposal({
      variables: {
        topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${
          proposal?.channel || proposal?.auid || proposal?.uid
        }`,
        updateType: 'edit_tag',
        proposal: {
          id: proposal._id,
          _id: proposal._id,
          pid: proposal.pid,
          uid: proposal.uid,
          tags: tags,
          edited: new Date(),
        },
      },
    });
  };

  useEffect(() => {
    // update title on modal
    if (popConfirmDetails?.show && popConfirmDetails?.title === 'Activity') {
      const { proposalId } = popConfirmDetails;
      openlogsModal(
        proposals.find((item) => item.pid === proposalId),
        proposalId
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposals]);

  const updateProposalClientName = ({ proposalId, value }) => {
    const proposal = proposals.find((item) => item.pid === proposalId);

    const { _id } = proposal;

    const { draft } = findDraftObject({
      what: {
        'draft.signature.rawname': null,
        'draft.signature.name': value,
      },
      proposal,
    });

    if (draft?.header?.rawsubtitle?.blocks?.length > 0) {
      const oldValue = proposal?.draft?.variables?.client?.fullName.value;
      // keep only the first block
      for (let i = 0; i < draft.header.rawsubtitle.blocks.length; i++) {
        if (draft.header.rawsubtitle.blocks[i].text.indexOf(oldValue) > -1) {
          draft.header.rawsubtitle.blocks[i].text = draft.header.rawsubtitle.blocks[i].text.replace(
            oldValue,
            value
          );
          if (draft.header.rawsubtitle.blocks[i].inlineStyleRanges.length > 0) {
            // if entire text is styled, then update the length of new text
            draft.header.rawsubtitle.blocks[i].inlineStyleRanges.map((item) => {
              if (item['offset'] === 0 && item['length'] === oldValue.length) {
                item['length'] = value.length;
              }
              return item;
            });
          }
          break;
        }
      }
    }

    const client = {
      ...proposal.client,
      contact: value,
    };

    updateProposal({
      variables: {
        topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${
          proposal?.channel || proposal?.auid || proposal?.uid
        }`,
        updateType: 'edit',
        proposal: {
          id: _id,
          _id,
          pid: proposalId,
          uid: proposal.uid,
          draft,
          client: client,
          edited: new Date(),
          editEvent: proposal.editEvent,
        },
      },
    });
  };

  const openlogsModal = (proposal, proposalId) => {
    const columns = [
      {
        title: '#',
        dataIndex: 'index',
        key: 'index',
        render: (_, __, index) => index + 1,
      },
      {
        title: 'Opened',
        dataIndex: 'date',
        key: 'date',
        render: (date) => {
          if (date.length < 11) {
            // for 28-11-2022
            date = date.split('/').reverse().join('-');
            return <>{moment(date).format('MMM D, YYYY')}</>;
          }
          /* NEED this if showing invalid date for some
          else if (date.length < 17) {
            // for 28-11-2022 06:25
            date = date.split(' ');
            date[0] = date[0].split('/').reverse().join('-')
            date = date.join(' ')
          }
          */
          return <>{moment(date).format('MMM D, YYYY HH:mm')}</>;
        },
      },
      {
        title: 'Watched',
        dataIndex: 'watched',
        key: 'watched',
        render: (watched) => {
          let minutes = watched ? `${Math.floor((watched * 1000) / 60000)}` : null;
          let seconds = watched ? `${(((watched * 1000) % 60000) / 1000).toFixed(0)}` : null;

          if (minutes?.length < 2) minutes = `0${minutes}`;
          if (seconds?.length < 2 && seconds > 10) seconds = `${seconds}0`;
          else if (seconds && seconds < 10) seconds = `0${seconds}`;

          return (
            <>
              {minutes || '00'}:{seconds || '00'} <span className="open_logs_span">min</span>
            </>
          );
        },
      },
      {
        title: 'Device',
        dataIndex: 'deviceInfo',
        key: 'deviceInfo',
        render: (deviceInfo) => (
          <Tooltip
            overlayClassName="device-info-tooltip"
            title={
              deviceInfo ? (
                <div className="device-info-container">
                  <Row>
                    <Col>{deviceInfo?.deviceName}</Col>
                    <Divider type="vertical" />
                    <Col>{deviceInfo?.deviceType}</Col>
                  </Row>
                  <Row>{deviceInfo.deviceAgent}</Row>
                </div>
              ) : null
            }>
            {deviceInfo?.deviceType}
          </Tooltip>
        ),
      },
      {
        title: 'Browser',
        dataIndex: 'deviceBrowser',
        key: 'deviceInfo',
        render: (_, { deviceInfo }) => <>{deviceInfo?.deviceBrowser || ''}</>,
      },
      {
        title: 'IP',
        dataIndex: 'clientIP',
        key: 'clientIP',
        render: (clientIP) => <>{clientIP.split('-')[0] || ''}</>,
      },
    ];

    setPopConfirmDetails((prevState) => ({
      ...prevState,
      show: true,
      proposalId,
      title: 'Activity',
      className: 'open-logs-modal',
      body: (
        <>
          <div className="content">{getProposalTitle({ proposal, user })}</div>
          <Table
            rowKey={'index'}
            columns={columns}
            dataSource={proposal.openLogs}
            pagination={{
              pageSize: 5,
            }}
          />
        </>
      ),
      cancelText: 'CLOSE',
      type: 'openLogs',
    }));
  };

  const onClickItem = ({ actionType, proposalId, value }) => {
    if (
      user?.paymentStatus?.canLock &&
      actionType !== 'download_pdf' &&
      actionType !== 'copy_link'
    ) {
      return;
    }

    if (actionType === 'delete') {
      const proposal = proposals.find((item) => item.pid === proposalId);
      if (![proposal.uid, proposal.auid].includes(user._id)) {
        return showPermissionErrorModal(true);
      }
      setProposalId(proposalId);
      showConfirmModal(true);
    } else if (actionType === 'restore') {
      setProposalId(proposalId);
      showRestoreModal(true);
    } else if (actionType === 'edit') {
      editProposal({ proposalId });
    } else if (actionType === 'decline') {
      setPopConfirmDetails((prevState) => ({
        ...prevState,
        show: true,
        title: 'Mark Declined?',
        body: 'You can change the status back to what it was before',
        cancelText: 'CANCEL',
        confirmText: 'YES, DECLINE',
        onConfirm: () => approveOrDeclineProposal('decline', proposalId),
        type: 'decline',
      }));
    } else if (actionType === 'approve') {
      setPopConfirmDetails((prevState) => ({
        ...prevState,
        show: true,
        title: 'Mark Approved?',
        body: "When a proposal is marked as approved, it will be sealed and won't be editable.You may download a PDF copy or duplicate it as a new proposal later or change its status back to what it was before.",
        cancelText: 'CANCEL',
        confirmText: 'YES, APPROVE',
        onConfirm: () => approveOrDeclineProposal('approve', proposalId),
        type: 'approve',
      }));
    } else if (actionType === 'update_title') {
      updateProposalTitle({ proposalId, value });
    } else if (actionType === 'update_client') {
      updateProposalClientName({ proposalId, value });
    } else if (actionType === 'duplicate') {
      setProposalId(proposalId);
      showDuplicateModal(true);
    } else if (actionType === 'save_as_template') {
      const templateStates = ['seen', 'approved', 'published', 'pdf', 'sent'];
      const proposal = proposals.find((item) => item.pid === proposalId);

      if (templateStates.includes(proposal.state)) {
        showMoveModal(true);
        setProposalId(proposal._id);
      } else {
        message.error('Template is still in edit mode.');
      }
    } else if (actionType === 'generate_fresh_book_invoice') {
      if (!user?.freshbooks) {
        setPopConfirmDetails((prevState) => ({
          ...prevState,
          show: true,
          title: 'FreshBooks Invoice Integration',
          body: 'You can integrate Prospero with an invoice service to generate invoices from your proposals with one click.',
          cancelText: 'CANCEL',
          confirmText: 'Setup',
          onConfirm: () => history.push('/integration'),
          type: 'integration',
        }));
      } else {
        setProposalId(proposalId);
        setGenerateInvoiceModal('freshbooks');
      }
    } else if (actionType === 'generate_quick_book_invoice') {
      if (!user?.quickbooks) {
        setPopConfirmDetails((prevState) => ({
          ...prevState,
          show: true,
          title: 'QuickBooks Invoice Integration',
          body: 'You can integrate Prospero with an invoice service to generate invoices from your proposals with one click.',
          cancelText: 'CANCEL',
          confirmText: 'Setup',
          onConfirm: () => history.push('/integration'),
          type: 'integration',
        }));
      } else {
        setProposalId(proposalId);
        setGenerateInvoiceModal('quickbooks');
      }
    } else if (actionType === 'generate_xero_invoice') {
      if (!Object.keys(user?.xero || {}).length) {
        setPopConfirmDetails((prevState) => ({
          ...prevState,
          show: true,
          title: 'Xero Invoice Integration',
          body: 'You can integrate Prospero with an invoice service to generate invoices from your proposals with one click.',
          cancelText: 'CANCEL',
          confirmText: 'Setup',
          onConfirm: () => history.push('/integration'),
          type: 'integration',
        }));
      } else {
        setProposalId(proposalId);
        setGenerateInvoiceModal('xero');
      }
    } else if (actionType === 'generate_morning_invoice') {
      if (!Object.keys(user?.morninginvoice || {}).length) {
        setPopConfirmDetails((prevState) => ({
          ...prevState,
          show: true,
          title: 'morning Invoice Integration',
          body: 'You can integrate Prospero with an invoice service to generate invoices from your proposals with one click.',
          cancelText: 'CANCEL',
          confirmText: 'Setup',
          onConfirm: () => history.push('/integration'),
          type: 'integration',
        }));
      } else {
        setProposalId(proposalId);
        setGenerateInvoiceModal('morninginvoice');
      }
    } else if (actionType === 'change_to_previous_state') {
      setProposalId(proposalId);
      const proposal = proposals.find((item) => item.pid === proposalId);
      const proposalPrevState = proposal.prevState || 'edit';

      setPopConfirmDetails((prevState) => ({
        ...prevState,
        show: true,
        title: `Change proposal state to ${
          proposalPrevState === 'edit'
            ? 'draft'
            : proposalPrevState === 'seen'
            ? 'opened'
            : proposalPrevState
        }?`,
        body: 'This action cannot be undone',
        cancelText: 'CANCEL',
        confirmText: 'YES, CHANGE',
        onConfirm: () => {
          let doc = {};

          if (proposal.state === 'seen') {
            doc = {
              id: proposal._id,
              _id: proposal._id,
              pid: proposalId,
              uid: proposal.uid,
              state: proposalPrevState,
              prevState: '',
              published: '',
              signature: {
                name: '',
                email: '',
                date: null,
                clientIP: '',
              },
              approvedmanually: '',
              pdflink: '',
              disableSig: false,
              edited: new Date(),
              editEvent: proposal.editEvent,
              ...(proposal.prevState === '' ? { signature: null } : {}),
            };
          } else {
            doc = {
              id: proposal._id,
              _id: proposal._id,
              pid: proposalId,
              uid: proposal.uid,
              state: proposalPrevState,
              prevState: '',
              lastSeen: '',
              seenCount: 0,
              seenTime: 0,
              published: '',
              signature: {
                name: '',
                date: null,
                email: '',
                clientIP: '',
              },
              approvedmanually: '',
              pdflink: '',
              disableSig: false,
              edited: new Date(),
              editEvent: proposal.editEvent,
              ...(proposal.prevState === '' || proposal.state === 'approved'
                ? { signature: null }
                : {}),
            };
          }

          updateProposal({
            variables: {
              topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${doc?.channel || doc?.auid || doc?.uid}`,
              updateType: 'edit',
              proposal: doc,
            },
          });
        },
        type: 'state',
      }));
    } else if (actionType === 'download_pdf') {
      downloadPdf({
        variables: {
          origin: window.location.origin,
          proposalId,
        },
      });
      downloadNotification(`Downloading PDF...`);
    } else if (actionType === 'open_logs') {
      const proposal = proposals.find((item) => item.pid === proposalId);
      openlogsModal(proposal, proposalId);
    } else if (actionType === 'share_proposal') {
      const proposal = proposals.find((item) => item.pid === proposalId);

      setShowShareProposalModal({
        _id: proposal._id,
        name: getProposalTitle({ proposal, user }),
      });
    } else if (actionType === 'copy_link') {
      const proposal = proposals.find((item) => item.pid === proposalId);

      // dont copy link for null, project, you, deliverables, milestones, edit
      if (
        !proposal ||
        [null, 'project', 'you', 'deliverables', 'milestones', 'edit'].includes(proposal.state)
      ) {
        return;
      }

      const hostForLink = `.goprospero.com/${helpers.porposalPdfLinkPath(proposal._id)}`;
      let pdfLink = user ? `https://${user.domain}${hostForLink}` : '';

      if (user && user._id !== proposal.uid) {
        const teamMember =
          proposal.uid === user._id || // if proposal is created by user
          proposal.uid === user.teamId || // if proposal is createrd by team owner
          proposal.auid === user._id; // if proposal is createrd by team member

        // const teamMember = teamList && teamList.find((user) => user._id === proposal.uid);
        if (teamMember) {
          pdfLink = `https://${user?.domain}${hostForLink}`;
        } else {
          pdfLink = '';
        }
      }

      copy(pdfLink);
      message.success('Copied to Clipboard');
    }
  };

  if (data && data.fetchProposals) {
    if (proposalsCount !== data.fetchProposals.proposalsCount)
      proposalsCountVar(data.fetchProposals.proposalsCount);
  }

  const proposalsByMonth = _.groupBy(proposals, (proposal) =>
    proposalsByMonthAndYear({ proposalDate: new Date(proposal.date) })
  );

  const limitToZero = (str) => {
    if (!str || !str.includes('-')) {
      return str;
    }
    // -$-600.00
    str = `${str.split('-').slice(0, -1).join('')}0`;
  };

  const props = _.flatten(
    _.toArray(
      _.map(proposalsByMonth, (proposalList, monthName) => {
        return _.compact(
          _.map(proposalList, (proposal, proposalIndex) => {
            const { pid: proposalId, state = 'edit' } = proposal;

            if (proposalId && proposalId.length < 36) {
              return null;
            }

            const proposalStatus = PROPOSAL_STATUS[state];
            const proposalTitle = getProposalTitle({ proposal, user });
            const clientName = getClientName({ proposal });
            let { minPrice, maxPrice } = proposal?.quotes || { minPrice: '$0', maxPrice: '$0' };

            // minimum value can be 0
            minPrice = limitToZero(minPrice);
            maxPrice = limitToZero(maxPrice);

            const seenTime = getSeenTimeDetails({ proposal });
            const { seenCount, openLogs } = proposal;

            let opened = seenCount || openLogs?.length;
            opened =
              opened &&
              proposalStatus !== 'DRAFT' &&
              new Date(proposal.date) > new Date(2019, 5, 30)
                ? opened
                : null;

            const proposalUser = teamMembers?.length
              ? proposal?.uid !== user?._id
                ? teamMembers?.find((teamMember) => teamMember._id === proposal.uid)
                : user
              : '';

            let avatar = '';

            if (proposalUser) {
              avatar = (
                <Tooltip title={proposalUser?.profile?.name}>
                  <Avatar src={proposalUser?.profile?.image} size={40}>
                    {getUserShortName({ name: proposalUser?.profile?.name })}
                  </Avatar>
                </Tooltip>
              );
            }

            return (
              <div key={proposalIndex + proposalId}>
                {proposalIndex === 0 && <div className="month-name">{monthName}</div>}
                <ProposalItem
                  proposalIndex={proposalIndex}
                  tagWrapperWidth={tagWrapperWidth}
                  setTagWrapperWidth={setTagWrapperWidth}
                  teamInfo={
                    proposalUser
                      ? {
                          userId: user?._id,
                          userRole: user?.roles && user?.roles[0],
                          uid: proposal.uid,
                          auid: proposal.auid,
                        }
                      : null
                  }
                  pnum={proposal.pnum}
                  trash={trash}
                  tags={proposal.tags}
                  name={proposalTitle}
                  client={clientName}
                  avatar={avatar}
                  watched={
                    seenTime &&
                    proposalStatus !== 'DRAFT' &&
                    new Date(proposal.date) > new Date(2019, 5, 30)
                      ? `${seenTime.minutes}:${seenTime.seconds} min`
                      : ''
                  }
                  opened={opened}
                  lastSeen={proposal.lastSeen}
                  clientIP={proposal?.signature?.clientIP}
                  quote={
                    minPrice === maxPrice
                      ? `${minPrice || '$0'}`
                      : `${minPrice || '$0'} - ${maxPrice || '$0'}`
                  }
                  status={proposalStatus || 'DRAFT'}
                  onClickItem={onClickItem}
                  proposalId={proposalId}
                  isLocked={user?.paymentStatus?.canLock}
                  variables={proposal?.draft?.variables}
                  isDeletable={
                    user?._id === proposal?.auid || //user is admin
                    proposal.isOwner || //user is owner
                    (user?.roles?.includes('manager') && !proposal.isManager) // user is manager and proposal is of editor
                  }
                  auid={user.teamId || user._id}
                  pid={proposal._id}
                  tagsData={tagsData}
                  tagIdValues={tagIdValues}
                  setTagsData={setTagsData}
                  proposalsVar={proposalsVar}
                  proposalList={proposals}
                  updateProposalTags={updateProposalTags}
                  setIgnoreUpdates={setIgnoreUpdates}
                  tagsFilter={tagsFilter}
                />
              </div>
            );
          })
        );
      })
    )
  );

  const onChangeSearch = _.debounce((e) => {
    setOffset(0);
    setSearchValue(e.target.value);
    refetchProposal(e.target.value, teamList, selectedDate, filterItems, tagsFilter);
  }, 1000);

  const onChangeFilter = (selectedItem) => {
    var updatedFilterList = filterItems.map((item) =>
      item.id === selectedItem.id
        ? { ...item, checked: !selectedItem.checked }
        : !selectedItem.checked && selectedItem.id !== 0 && item.id === 0
        ? { ...item, checked: false }
        : selectedItem.id === 0 && !selectedItem.checked && item.id !== 0
        ? { ...item, checked: false }
        : item
    );

    const allUpdatedFilterCheck = updatedFilterList.every((x) => x.checked === false);
    if (allUpdatedFilterCheck === true && selectedItem.id !== 0) {
      updatedFilterList[0].checked = true;
    }
    setOffset(0);
    setFilterItems(updatedFilterList);
    refetchProposal(searchValue, teamList, selectedDate, updatedFilterList, tagsFilter, false);
  };

  const onChangeTagsFilter = (selectedTag) => {
    var updatedTagsList = tagsFilter.map((item) =>
      item._id === selectedTag._id
        ? { ...item, checked: !selectedTag.checked }
        : !selectedTag.checked && selectedTag._id !== 0 && item._id === 0
        ? { ...item, checked: false }
        : selectedTag._id === 0 && !selectedTag.checked && item._id !== 0
        ? { ...item, checked: false }
        : item
    );

    const allUpdatedFilterCheck = updatedTagsList.every((x) => x.checked === false);
    if (allUpdatedFilterCheck === true && selectedTag._id !== 0) {
      updatedTagsList[0].checked = true;
    }
    setOffset(0);
    setTagsFilter(updatedTagsList);
    refetchProposal(searchValue, teamList, selectedDate, filterItems, updatedTagsList);
  };

  const onShowTrash = () => {
    let updatedFilterList = undefined;
    if (!trash) {
      updatedFilterList = filterItems.map((item) =>
        item.key === 'deleted' ? { ...item, checked: true } : { ...item, checked: false }
      );
    } else {
      updatedFilterList = filterItems.map((item) =>
        item.key === 'All' ? { ...item, checked: true } : { ...item, checked: false }
      );
    }
    showTrash(!trash);
    setOffset(0);
    setFilterItems(updatedFilterList);
    refetchProposal(searchValue, teamList, selectedDate, updatedFilterList, tagsFilter);
  };

  const downloadNotification = (downloadMessage) => {
    notification.open({
      key: 'download-report-notification',
      className: 'download-report-notification',
      duration: 0,
      message: downloadMessage,
      placement: 'bottomRight',
      icon: <DownloadingIcon />,
      closeIcon: <></>,
    });
  };

  const onDownloadReportFilter = (selectedItem) => {
    setSelectedCSVDuration(selectedItem.key);
    downloadNotification(`Your report is on it’s way...`);
  };

  const onChangeDate = (date) => {
    if (date && date.length) {
      date[0] = date[0]?.startOf('day');
      date[1] = date[1]?.endOf('day');
    }
    setOffset(0);
    setSelectedDate(date);
    refetchProposal(searchValue, teamList, date, filterItems, tagsFilter);
  };

  const onChangeTeamsFilter = (selectedItem) => {
    const updatedFilterList = teamList.map((item) =>
      item.id === selectedItem.id ? { ...item, checked: !selectedItem.checked } : item
    );
    setOffset(0);
    setTeamList(updatedFilterList);
    refetchProposal(searchValue, updatedFilterList, selectedDate, filterItems, tagsFilter);
  };

  const approveOrDeclineProposal = (type, proposalId) => {
    const proposal = proposals.find((item) => item.pid === proposalId);
    // DECLINED
    // TODO: GA
    if (popConfirmDetails.type === 'decline' || type === 'decline') {
      trackEvent('decline', {
        propid: proposalId,
      });

      updateProposal({
        variables: {
          topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${
            proposal?.channel || proposal?.auid || proposal?.uid
          }`,
          updateType: 'declined',
          proposal: {
            id: proposal._id,
            _id: proposal._id,
            pid: proposal.pid,
            uid: proposal.uid,
            state: 'declined',
            prevState: proposal.state,
            published: '',
          },
        },
      });
    }
    // APPROVE
    if (popConfirmDetails.type === 'approve' || type === 'approve') {
      // TODO: GA
      trackEvent('approve', {
        propid: proposalId,
      });

      if (proposal.state === 'approved') {
        message.success("Can't decline an approved proposal");
      } else {
        updateProposal({
          variables: {
            topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${
              proposal?.channel || proposal?.auid || proposal?.uid
            }`,
            updateType: 'approved',
            proposal: {
              id: proposal._id,
              _id: proposal._id,
              pid: proposal.pid,
              uid: proposal.uid,
              state: 'approved',
              prevState: proposal.state,
              approvedmanually: new Date(new Date().toUTCString()).toISOString(),
              disableSig: true,
            },
          },
        });
      }
    }
  };

  const onClickNewProposal = () => {
    if (!user?.paymentStatus?.canLock) {
      setShowAddProposalModal(true);
    }
  };

  return (
    <div className="dashboard">
      <DashboardHeader
        userPlanType={user?.planType}
        approvedCount={proposalsStats?.approvedCount}
        acceptanceRate={proposalsStats?.acceptanceRate}
        onChangeSearch={onChangeSearch}
        onChangeFilter={onChangeFilter}
        onChangeTagsFilter={onChangeTagsFilter}
        trash={trash}
        showTrash={showTrash}
        onShowTrash={onShowTrash}
        onChangeDate={onChangeDate}
        onChangeTeamsFilter={onChangeTeamsFilter}
        onDownloadReportFilter={onDownloadReportFilter}
        filterItems={filterItems}
        reportItems={reportItems}
        selectedDate={selectedDate}
        teamList={teamList}
        isTeamMember={user?.teamId ? true : false}
        reviewWidget={user?.reviewWidget}
        wixReview={!!user?.wix?.length}
        isFetchingTeamMembers={isFetchingTeamMembers}
        onClicknNewProposal={onClickNewProposal}
        showButton={!(!props?.length && !filterApplied)}
        tagsData={tagsFilter}
        tagsType={tagsType}
        setTagsType={setTagsType}
      />

      <div
        className="item-list"
        ref={refToTop}
        style={
          user?.reviewWidget !== 1
            ? {}
            : {
                top: '33em',
                height: 'calc(100% - 33em)',
              }
        }>
        {!isFetching ? (
          <React.Fragment>
            <ProposalList
              props={[props, trash]}
              loading={showItemLoader}
              onClickNewProposal={onClickNewProposal}
              filterApplied={filterApplied}
              isLocked={user?.paymentStatus?.canLock}
            />
            {showItemLoader && (
              <div className="loader-container">
                {Array.from({ length: PROPOSALS_LIMIT - 1 }, (_, x) => (
                  <ProposalItemLoader key={x} />
                ))}
              </div>
            )}
          </React.Fragment>
        ) : (
          <div className="loader-container">
            {Array.from({ length: PROPOSALS_LIMIT }, (_, x) => (
              <ProposalItemLoader key={x} />
            ))}
          </div>
        )}
      </div>

      <ScrollToTopButton showScrollTop={showScrollTop} scrollToTop={scrollToTop} />

      {user && (
        <DashboardModals
          user={user}
          refetchUser={refetchUser}
          trash={trash}
          proposals={proposals || []}
          teamMembers={teamMembers}
          selectedProposalId={selectedProposalId}
          updateProposal={updateProposal}
          popConfirmDetails={popConfirmDetails}
          setPopConfirmDetails={setPopConfirmDetails}
          showAddProposalModal={showAddProposalModal}
          setShowAddProposalModal={setShowAddProposalModal}
          confirmModal={confirmModal}
          showConfirmModal={showConfirmModal}
          showAddUserModal={showAddUserModal}
          setShowAddUserModal={setShowAddUserModal}
          duplicateModal={duplicateModal}
          showDuplicateModal={showDuplicateModal}
          setUserInfoModal={setUserInfoModal}
          permissionErrorModal={permissionErrorModal}
          showPermissionErrorModal={showPermissionErrorModal}
          restoreModal={restoreModal}
          showRestoreModal={showRestoreModal}
          generateInvoiceModal={generateInvoiceModal}
          setGenerateInvoiceModal={setGenerateInvoiceModal}
          userInfoModal={userInfoModal}
          showShareProposalModal={showShareProposalModal}
          setShowShareProposalModal={setShowShareProposalModal}
          refetchProposal={refetchProposal}
          moveModal={moveModal}
          showMoveModal={showMoveModal}
          fetchMoreProposal={fetchMoreProposal}
          disabledEditingModal={disabledEditingModal}
          setDisabledEditingModal={setDisabledEditingModal}
        />
      )}

      <CSVLink
        ref={downloadRef}
        data={proposalCSVData}
        headers={headers}
        filename={'prospero-proposals.csv'}
      />
    </div>
  );
};

export default Dashboard;
