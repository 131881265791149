import React, { useState, useRef, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Avatar,
  message,
  Progress,
  AutoComplete,
  Tooltip,
} from 'antd';
import { CachePersistor } from 'apollo3-cache-persist';
import { useHistory } from 'react-router-dom';

import PageHeader from 'components/PageHeader';
import Loader from 'components/Loader';
import Uploader from 'components/Uploader';
import helpers from 'helpers';
import Path from 'routes/path';
import Popconfirm from 'components/PopConfirm';
import CountriesCode from 'constants/countries_code';
import InfoIcon from 'components/Icons/InfoIcon';
import SettingsResetIcon from 'components/Icons/SettingsResetIcon';

import { getUserShortName } from 'helpers/users';
import utils from 'utils/utils';
import { resizeImageForS3 } from 'utils/uploadToS3';
import { USER_DETAILS } from 'graphql/queries/userQueries';
import {
  USER_DETAILS_UPDATE,
  USER_PASSWORD_UPDATE,
  USER_DELETE,
} from 'graphql/mutations/userMutations';
import { useLazyQuery, useMutation, useApolloClient, useReactiveVar } from '@apollo/client';
import { cache, getUserId, userVar, isLoggedInVar, userProfileVar } from 'graphql/cache';

import './Profile.scss';

const FormItem = ({
  placeholder,
  label,
  value,
  handleInputChange,
  validateStatus,
  help,
  colon,
  type,
  name,
  disabled,
  maxLength,
}) => (
  <Col md={12} lg={12} xl={12} className="input-container">
    <Form.Item
      label={label}
      colon={colon || true}
      validateStatus={validateStatus ? 'error' : ''}
      help={help || ''}>
      <Input
        placeholder={placeholder || ''}
        value={value}
        name={name}
        onChange={handleInputChange}
        type={type || 'text'}
        disabled={disabled}
        maxLength={maxLength}
      />
    </Form.Item>
  </Col>
);

const Profile = () => {
  const [upload, uploadStatus] = Uploader();
  const [emailValidateError, setEmailValidateError] = useState('');
  const [numberValidateError, setNumberValidateError] = useState('');
  const [whatsappNumberValidateError, setWhatsappNumberValidateError] = useState('');
  const [nameValidateError, setNameValidateError] = useState('');
  const [popConfirmDetails, setPopConfirmDetails] = useState({
    show: false,
  });

  const inputFileRef = useRef(null);
  const history = useHistory();
  const client = useApolloClient();
  const user = useReactiveVar(userVar);

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState([]);
  const [saving, setSaving] = useState(false);
  const [profile, setProfile] = useState(
    user
      ? {
          ...user?.profile,
          email: user.emails[0].address,
          role: user?.roles && user?.roles[0],
        }
      : {
          name: '',
          email: '',
          companyname: '',
          address: '',
          country: '',
          countryCode: '',
          vatnumber: '',
          image: '',
          contactnumber: '',
          role: '',
          whatsappNumber: '',
        }
  );
  const [showPasswordEdit, setShowPasswordEdit] = useState(false);
  const [passwordForm, setPasswordForm] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [passwordValidationError, setPasswordValidationError] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [passwordStrengthError, setPasswordStrengthError] = useState('');

  const isSettingsDisabled = profile.role === 'editor' || profile.role === 'manager' ? true : false;

  const [loadUser] = useLazyQuery(USER_DETAILS, {
    variables: { id: getUserId() },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const {
        fetchUser: {
          profile: {
            companyname,
            name,
            address,
            country,
            countryCode,
            image,
            vatnumber,
            contactnumber,
            whatsappNumber,
          },
          emails,
          roles,
        },
      } = res;

      setOptions([{ label: country, value: country }]);

      setProfile({
        ...profile,
        name,
        companyname,
        email: emails[0].address,
        address,
        country,
        countryCode,
        image,
        contactnumber,
        vatnumber,
        role: roles && roles[0],
        whatsappNumber,
      });
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    },
  });

  const [saveProfile] = useMutation(USER_DETAILS_UPDATE, {
    errorPolicy: 'none',
    async onCompleted() {
      cache.evict('fetchUser');
      userProfileVar(profile);
      setEmailValidateError('');
      setNumberValidateError('');
      setWhatsappNumberValidateError('');
      message.success('Profile updated successfully');
      setSaving(false);
    },
    onError: ({ graphQLErrors }) => {
      setSaving(false);
      message.error(graphQLErrors?.[0]?.message);
    },
  });

  const [deleteUser, { loading: deleteUserLoading }] = useMutation(USER_DELETE, {
    errorPolicy: 'none',
    async onCompleted() {
      const persistor = new CachePersistor({
        cache: client.cache,
        storage: window.localStorage,
      });

      await client.clearStore();
      await persistor.purge();

      isLoggedInVar(false);
      userProfileVar('');
      userVar('');

      localStorage.clear();
      sessionStorage.removeItem('lastTimeStamp');
      history.push(Path.LOGIN);
    },
    onError: ({ graphQLErrors }) => {
      message.error(graphQLErrors[0].message);
    },
  });

  const [updatePassword] = useMutation(USER_PASSWORD_UPDATE, {
    onCompleted() {
      setShowPasswordEdit(false);
      message.success('Password changed successfully');
    },
    onError: ({ graphQLErrors }) => {
      message.error(graphQLErrors[0].message);
    },
  });

  useEffect(() => {
    setLoading(true);
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = 'Prospero - Profile';
  });

  if (loading) {
    return <Loader />;
  }

  const uploadUserPhoto = () => {
    inputFileRef.current.click();
  };

  const onImageSelect = async ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    if (validity.valid) {
      const size = { width: 640, height: 480 };

      try {
        const reSizedImage = await resizeImageForS3({
          file: file,
          path: 'profile',
          size,
        });

        const { url } = await upload(reSizedImage, `profile/${user._id}`);
        setProfile({
          ...profile,
          image: url,
        });
      } catch (error) {
        message.error(error.message);
      }
    }
  };

  const handleInputChange = (e) => {
    if (emailValidateError && e.target.name === 'email') {
      setEmailValidateError('');
    }
    if (nameValidateError && e.target.name === 'name') {
      setNameValidateError('');
    }

    const newProfile = { ...profile };
    newProfile[e.target.name] = e.target.value;
    setProfile(newProfile);
  };

  const onSearch = (searchText) => {
    let newOptions = [];

    !searchText
      ? []
      : Object.keys(CountriesCode).forEach((countryCode) => {
          if (CountriesCode[countryCode].name.search(new RegExp(searchText, 'i')) !== -1)
            newOptions.push({
              label: CountriesCode[countryCode].name,
              value: CountriesCode[countryCode].name,
            });
        });

    setOptions(newOptions);
  };

  const handleSave = () => {
    let error = false;
    let newProfile = { ...profile };

    if (!newProfile.name.trim()) {
      setNameValidateError('Name cannot be empty');
      error = true;
    } else if (newProfile.name.length > 100) {
      setNameValidateError('Name cannot be greater than 100 characters');
      error = true;
    }

    if (!newProfile.email) {
      setEmailValidateError('Email cannot be empty');
      error = true;
    } else if (!helpers.validateEmail(newProfile.email)) {
      setEmailValidateError('Invalid email');
      error = true;
    }
    /* eslint-disable */
    if (
      !!newProfile.whatsappNumber &&
      !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g.test(newProfile.whatsappNumber)
    ) {
      error = true;
      setWhatsappNumberValidateError('Invalid whatsapp number');
    }

    if (
      !!newProfile.contactnumber &&
      !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g.test(newProfile.contactnumber)
    ) {
      error = true;
      setNumberValidateError('Invalid contact number');
    }

    if (
      !!newProfile.email &&
      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        newProfile.email
      )
    ) {
      error = true;
      setEmailValidateError('Invalid email');
    }
    /* eslint-enable */

    newProfile['name'] = newProfile.name.trim();

    let countryCode = newProfile.countryCode || newProfile.country;

    Object.keys(CountriesCode).forEach((code) => {
      if (CountriesCode[code]?.name === newProfile.country) countryCode = code;
    });

    newProfile['countryCode'] = countryCode;

    if (isSettingsDisabled) {
      newProfile = {
        ...user.profile,
        email: newProfile?.email,
        name: newProfile?.name,
        contactnumber: newProfile?.contactnumber,
      };
    }

    if (!error) {
      setSaving(true);
      saveProfile({
        variables: {
          id: getUserId(),
          profile: newProfile,
        },
      });
    }
  };

  const handlePasswordChange = (e) => {
    const newPasswordForm = { ...passwordForm };
    newPasswordForm[e.target.name] = e.target.value;
    if (passwordValidationError[e.target.name]) {
      passwordValidationError[e.target.name] = '';
      setPasswordValidationError({ ...passwordValidationError });
    }
    setPasswordForm(newPasswordForm);

    if (e.target.name !== 'oldPassword') {
      const errorResponse = utils.simplePasswordPolicy(e.target.value);
      if (errorResponse !== null) {
        setPasswordStrengthError(errorResponse);
        passwordValidationError[e.target.name] = errorResponse;
      }
    }
  };

  const submitPassword = () => {
    let error = false;

    setPasswordValidationError({
      confirmPassword: '',
      newPassword: '',
      oldPassword: '',
    });

    const newPasswordValidationError = {
      confirmPassword: '',
      newPassword: '',
      oldPassword: '',
    };

    if (!passwordForm.oldPassword) {
      newPasswordValidationError.oldPassword = 'This field is required';
      error = true;
    }

    if (!passwordForm.newPassword) {
      newPasswordValidationError.newPassword = 'This field is required';
      error = true;
    } else if (passwordForm.newPassword.length < 8) {
      newPasswordValidationError.newPassword =
        'The password should have minimum of 8 character length';
      error = true;
    }

    if (!passwordForm.confirmPassword) {
      newPasswordValidationError.confirmPassword = 'This field is required';
      error = true;
    } else if (passwordForm.confirmPassword !== passwordForm.newPassword) {
      newPasswordValidationError.confirmPassword = 'The passwords should be same';
      error = true;
    }

    setPasswordValidationError(newPasswordValidationError);

    if (!error) {
      updatePassword({
        variables: passwordForm,
      });
    }
  };

  const resetPasswordForm = () => {
    setPasswordValidationError({
      confirmPassword: '',
      newPassword: '',
      oldPassword: '',
    });
    setPasswordForm({
      confirmPassword: '',
      newPassword: '',
      oldPassword: '',
    });
  };

  const resetProfileForm = () => {
    let profileResetFields = {
      name: '',
      email: '',
      contactnumber: '',
    };

    if (!isSettingsDisabled) {
      profileResetFields = {
        companyname: '',
        address: '',
        country: '',
        countryCode: '',
        vatnumber: '',
        whatsappNumber: '',
        ...profileResetFields,
      };
    } else {
      profileResetFields = { ...profile, ...profileResetFields };
    }

    setProfile(profileResetFields);
  };

  const deleteUserProfile = () => {
    setPopConfirmDetails((prevState) => ({
      ...prevState,
      show: true,
      title: 'Delete Profile?',
      body: 'All your account data, including your proposals, support interactions and usage data will be lost forever. There is no way to undo this step.',
      cancelText: 'CANCEL',
      confirmText: 'YES, DELETE',
      onConfirm: deleteUser,
    }));
  };

  return (
    <div className="container">
      <div className="profile">
        <Row className="profile-header">
          <PageHeader pageTitle="Profile" showButton={false} />
        </Row>
        <Form className="profile-form" layout="vertical">
          <div>
            <Row className="content">
              <Col xs={24} className="input-container">
                <Form.Item label="User Photo" colon={false} help="">
                  {uploadStatus.status === 'uploading' ? (
                    <Progress type="circle" percent={uploadStatus.percent} />
                  ) : (
                    <div>
                      {profile.image ? (
                        <Avatar src={profile.image} size={64} />
                      ) : (
                        <Avatar size={64}>{getUserShortName({ name: profile.name })}</Avatar>
                      )}
                      <Button className="change-btn" size="small" onClick={uploadUserPhoto}>
                        Change
                      </Button>
                    </div>
                  )}
                </Form.Item>
              </Col>
              <FormItem
                value={profile.name}
                placeholder="Write your full name"
                name="name"
                label="Full Name"
                validateStatus={nameValidateError}
                help={nameValidateError}
                handleInputChange={handleInputChange}
              />
              <FormItem
                value={profile.email}
                placeholder="Write your email address"
                name="email"
                label="Email"
                validateStatus={emailValidateError}
                help={emailValidateError}
                handleInputChange={handleInputChange}
                colon={false}
              />
              <FormItem
                value={profile.contactnumber}
                placeholder="Write your contact number e.g. +12163547758"
                name="contactnumber"
                label={
                  <>
                    <span>Contact number</span>
                    <Tooltip
                      title={
                        <>
                          Make sure to include the country code in the mobile number for phone
                          calls.
                        </>
                      }
                      color="white">
                      <InfoIcon />
                    </Tooltip>
                  </>
                }
                handleInputChange={handleInputChange}
                validateStatus={numberValidateError}
                help={numberValidateError}
                colon={false}
              />
              <FormItem
                value={profile.companyname}
                placeholder="Write your company name"
                name="companyname"
                label="Company name"
                handleInputChange={handleInputChange}
                colon={false}
                help=""
                disabled={isSettingsDisabled}
              />
              <FormItem
                value={profile.address}
                placeholder="Write your address"
                name="address"
                label="Address"
                handleInputChange={handleInputChange}
                colon={false}
                help=""
                disabled={isSettingsDisabled}
              />
              <Col md={12} lg={12} xl={12} className="input-container">
                <Form.Item label="Country" name="country" colon={false}>
                  <AutoComplete
                    defaultValue={profile.country}
                    options={options}
                    onSearch={onSearch}
                    onChange={(countryValue) =>
                      handleInputChange({ target: { name: 'country', value: countryValue } })
                    }
                    disabled={isSettingsDisabled}
                  />
                </Form.Item>
              </Col>
              <FormItem
                value={profile.vatnumber}
                placeholder="Write your VAT / Business number"
                name="vatnumber"
                label="VAT / Business number"
                handleInputChange={handleInputChange}
                colon={false}
                help=""
                disabled={isSettingsDisabled}
              />
              <FormItem
                value={profile.whatsappNumber}
                placeholder="Write your WhatsApp Number e.g. +12163547758"
                name="whatsappNumber"
                label={
                  <>
                    <span>WhatsApp number</span>
                    <Tooltip
                      title={
                        <>
                          Make sure to include the country code in the mobile number for WhatsApp
                          messages.
                        </>
                      }
                      color="white">
                      <InfoIcon />
                    </Tooltip>
                  </>
                }
                validateStatus={whatsappNumberValidateError}
                help={whatsappNumberValidateError}
                handleInputChange={handleInputChange}
                colon={false}
                disabled={isSettingsDisabled}
              />
            </Row>
            {showPasswordEdit ? (
              <React.Fragment>
                <Row className="divider">&nbsp;</Row>
                <Row className="password-form content">
                  <FormItem
                    value={passwordForm.oldPassword}
                    name="oldPassword"
                    label="Enter your old password"
                    handleInputChange={handlePasswordChange}
                    validateStatus={passwordValidationError.oldPassword ? 'error' : ''}
                    help={passwordValidationError.oldPassword}
                    colon={false}
                    type="password"
                  />

                  <FormItem
                    value={passwordForm.newPassword}
                    name="newPassword"
                    label="Enter new password"
                    handleInputChange={handlePasswordChange}
                    validateStatus={passwordValidationError.newPassword ? 'error' : ''}
                    help={passwordValidationError.newPassword}
                    colon={false}
                    type="password"
                    onInput={passwordStrengthError}
                  />

                  <FormItem
                    value={passwordForm.confirmPassword}
                    name="confirmPassword"
                    label="Confirm password"
                    handleInputChange={handlePasswordChange}
                    colon={false}
                    validateStatus={passwordValidationError.confirmPassword ? 'error' : ''}
                    help={passwordValidationError.confirmPassword}
                    type="password"
                    onInput={passwordStrengthError}
                  />
                </Row>
                <Row className="password-actions">
                  <Button
                    className="link-btn"
                    type="link"
                    onClick={() => {
                      setShowPasswordEdit(true);
                      submitPassword();
                    }}>
                    Submit password
                  </Button>
                  <Button
                    className="link-btn cancel"
                    type="link"
                    onClick={() => {
                      setShowPasswordEdit(false);
                      resetPasswordForm();
                    }}>
                    Cancel
                  </Button>
                </Row>
              </React.Fragment>
            ) : (
              <Row>
                <Button
                  className="change-password link-btn"
                  type="link"
                  onClick={() => {
                    setShowPasswordEdit(true);
                  }}>
                  Change password
                </Button>
              </Row>
            )}
          </div>
          <div className="profile-actions">
            <Col>
              <Button
                className="primary-btn save-btn"
                type="primary"
                loading={saving}
                onClick={handleSave}>
                Save
              </Button>
              <Button className="delete-btn" onClick={deleteUserProfile}>
                Delete profile
              </Button>
              <Button
                className="link-btn reset-profile-btn reset-btn"
                type="link"
                onClick={() => {
                  resetProfileForm();
                }}>
                <span className="reset-icon">{<SettingsResetIcon />}</span>
                <span className="prosper-button-text"> {'Reset'}</span>{' '}
              </Button>
            </Col>
          </div>
        </Form>
      </div>
      <input
        type="file"
        className="file_bag"
        accept="image/*"
        ref={inputFileRef}
        onChange={onImageSelect}
        style={{ display: 'none', position: 'absolute', left: -10000 }}
      />
      {popConfirmDetails.show && (
        <Popconfirm
          title={popConfirmDetails.title}
          body={popConfirmDetails.body}
          visible={popConfirmDetails.show}
          cancelText={popConfirmDetails.cancelText}
          confirmText={popConfirmDetails.confirmText}
          modalClassName={popConfirmDetails.className}
          onCancel={() =>
            setPopConfirmDetails((prevState) => ({
              ...prevState,
              show: false,
            }))
          }
          onConfirm={popConfirmDetails.onConfirm}
          okButtonProps={{ loading: deleteUserLoading }}
        />
      )}
    </div>
  );
};

Profile.propTypes = {};

export default Profile;
