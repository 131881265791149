import React, { useEffect } from 'react';

import NotificationIcon from '../Icons/NotificationIcon';

const NotificationItem = () => {
  useEffect(() => {
    window.HW_config = {
      selector: '#notification-item', // CSS selector where to inject the badge
      account: 'JVD2Dx',
    };

    const element = document.getElementById('headwayappScript');
    if (!element) {
      const script = document.createElement('script');
      script.src = 'https://cdn.headwayapp.co/widget.js';
      script.async = true;
      script.id = 'headwayappScript';
      document.body.appendChild(script);
    }

    return () => {
      if (element) {
        element.parentElement.removeChild(element);
      }
      const badge = document.getElementById('HW_badge_cont');
      if (badge) {
        badge.parentElement.removeChild(badge);
      }
    };
  }, []);

  return (
    <li className="headway-widget">
      <NotificationIcon id="notification-item" className="headway-notification" />
    </li>
  );
};

export default NotificationItem;
