import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Layout, Form, Input, Row, Col, Button } from 'antd';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useIntercom } from 'react-use-intercom';
import { useGoogleLogin } from '@react-oauth/google';

import Path from 'routes/path';
import Loader from 'components/Loader';
import utils from 'utils/utils';
import Testimonials from 'components/Testimonial/Testimonial';
import CustomGoogleButton from 'components/CustomGoogleButton';
import { USER_CREATE } from 'graphql/mutations/userMutations';
import { USER_GOOGLE_LOGIN } from 'graphql/queries/userQueries';
import { isLoggedInVar, userProfileVar, userVar } from 'graphql/cache';
import { updateIntercomUser } from 'helpers/users';

import './Signup.scss';

// const { defaultUsersPassword } = Meteor.settings.public;
const { Content } = Layout;

const Signup = ({ loggedIn, history }) => {
  const [form] = Form.useForm();
  const { trackEvent, update } = useIntercom();
  const redirectUrl = sessionStorage.getItem('redirect-url') || utils.getURLParam('redirect_url');

  const [googleLoading, setGoogleLoading] = useState(false);
  const [googleError, setGoogleError] = useState(false);

  const [onGoogleLogin] = useLazyQuery(USER_GOOGLE_LOGIN, {
    onCompleted: (res) => {
      const {
        loginGoogleUser: {
          token,
          user: {
            _id,
            profile: {
              companyname,
              name,
              address,
              image,
              vatnumber,
              contactnumber,
              terms,
              whatsappNumber,
              where,
            },
            emails,
            createdAt,
          },
        },
      } = res;

      if (res.loginGoogleUser.user) {
        userProfileVar({
          name,
          companyname,
          email: emails[0].address,
          address,
          image,
          contactnumber,
          vatnumber,
          terms,
          whatsappNumber,
          where,
        });
        userVar({ ...res.loginGoogleUser.user });

        utils.updateClarity({ _id, name, email: emails?.[0].address, createdAt });
      }

      if (token) {
        window.ga('send', 'event', 'user', 'sign-in');
        trackEvent('sign-in');
        updateIntercomUser(res.loginGoogleUser.user, update);
        window.localStorage.setItem('prospero-token', token);
        window.localStorage.setItem('prospero-user-id', _id);
        isLoggedInVar(true);
        history.push(redirectUrl || Path.DASHBOARD);
      }

      utils.createCookie('loginattempts', 0);
      setGoogleLoading(false);
    },
    onError: (err) => {
      let loginattempts = Number(utils.readCookie('loginattempts') || 0);
      loginattempts += 1;
      if (err.message === 'Google Authentication Error') {
        const d1 = new Date();
        const expires = new Date(d1);
        expires.setMinutes(d1.getMinutes() + 30);
        utils.createCookie('loginattempts', loginattempts, expires);
        utils.createCookie('loginattemptsExpiresIn', expires, expires);
      } else if (err.message === 'Account Deleted') {
        return setGoogleError('Account Deleted! Please contact support.');
      }
      setGoogleLoading(false);
    },
    fetchPolicy: 'network-only',
  });

  const [emailError, setEmailError] = useState(false);

  // TODO: Remove this code later
  useEffect(() => {
    document.body.classList.add('draft-body');
    return () => document.body.classList.remove('draft-body');
  }, []);

  useEffect(() => {
    const instanceId = utils.readCookie('instanceId');

    if (instanceId) {
      history.push('/login');
    }
  }, [history]);

  const [createUser] = useMutation(USER_CREATE, {
    async onCompleted(data) {
      const {
        createUser: { token, user },
      } = data;

      if (token) {
        window.ga('send', 'event', 'registeration', 'sign up to trial', 'freetrial');
        window.ga('send', 'pageview', '/event/sign-up');
        trackEvent('sign-up');
        updateIntercomUser(user, update);
        window.gtag('event', 'conversion', { send_to: 'AW-848665564/735ACKCqsJEDENy31pQD' });
        window.localStorage.setItem('prospero-token', token);
        window.localStorage.setItem('prospero-user-id', user._id);

        isLoggedInVar(true);
        history.push(redirectUrl || Path.DASHBOARD);
      }
    },
    onError({ networkError, graphQLErrors }) {
      if (graphQLErrors && graphQLErrors.length)
        return graphQLErrors.map(({ message }) => setEmailError(message));

      if (networkError) return setEmailError('Network connection failed');
    },
  });

  useEffect(() => {
    if (googleLoading) {
      return <Loader />;
    }
  });

  const doSignup = (values) => {
    let { email } = values;
    if (email) {
      setEmailError('');
      email = email.toLowerCase();
      const profile = {};
      const affiliate = utils.getQueryStringValue('affiliate') || utils.readCookie('affiliate');
      const role = utils.getQueryStringValue('role');

      [profile.name] = email.split('@');

      if (affiliate) profile.affiliate = affiliate;

      if (role === 'affiliate') profile.role = 'affiliate';

      const referrerUrl = utils.readCookie('referrer_url');
      if (referrerUrl) profile.referrerUrl = referrerUrl;

      const landingUrl = utils.readCookie('landing_url');
      if (landingUrl) profile.landingUrl = landingUrl;

      profile['language'] =
        window.sessionStorage.getItem('language.value')?.toLowerCase() || 'english';
      profile['country'] = window.sessionStorage.getItem('country.value');
      profile['countryCode'] = window.sessionStorage.getItem('countryCode.value');
      profile['ip'] = window.sessionStorage.getItem('ip') || '';
      profile['allowPreviewDownload'] = true;
      profile['showPdfFooter'] = true;
      profile['pdfFooterVariables'] = '{{proposal.title}}';
      profile['currency'] =
        window.sessionStorage.getItem('language.value')?.toLowerCase() === 'hebrew' ? '₪' : '$';

      createUser({
        variables: {
          email,
          password: process.env.REACT_APP_DEFAULT_PASSWORD,
          profile,
        },
      });
    }
  };

  useEffect(() => {
    const affiliateId = utils.getQueryStringValue('affiliate');
    if (affiliateId) utils.createCookie('affiliate', affiliateId);

    /*eslint-disable*/
    if (
      !window.sessionStorage.getItem('language.value') ||
      !window.sessionStorage.getItem('country.value') ||
      !window.sessionStorage.getItem('countryCode.value')
    ) {
      utils.cacheGeoData();
    }
    /* eslint-enable */

    if (loggedIn) {
      return history.push(Path.DASHBOARD);
    }
  }, [loggedIn, history]);

  useEffect(() => {
    if (emailError) form.validateFields(['email']);
  }, [emailError, form]);

  useEffect(() => {
    document.title = 'Prospero - Signup';
  });

  const handleGoogleAuth = async (googleData) => {
    setGoogleLoading(true);
    const token = googleData.access_token;

    const profile = {};
    const affiliate = utils.getQueryStringValue('affiliate') || utils.readCookie('affiliate');
    const role = utils.getQueryStringValue('role');

    if (affiliate) profile.affiliate = affiliate;

    if (role === 'affiliate') profile.role = 'affiliate';

    const referrerUrl = utils.readCookie('referrer_url');
    if (referrerUrl) profile.referrerUrl = referrerUrl;

    const landingUrl = utils.readCookie('landing_url');
    if (landingUrl) profile.landingUrl = landingUrl;

    onGoogleLogin({
      variables: {
        token,
        affiliate,
        landingUrl,
      },
    });
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      handleGoogleAuth(codeResponse);
    },
    onError: (errorResponse) => {
      handleGoogleAuth(errorResponse);
    },
  });

  return (
    <Layout className="signup">
      <Content>
        <Row className="signup-container">
          <Testimonials page="Signup" />
          <Col className="signup-left" md={24} lg={14} xl={14}>
            <div className="signup-wrapper">
              <Row className="header-signup" justify="end">
                <div className="login-link">
                  Already have an account? &nbsp;
                  <NavLink to={Path.LOGIN}>Login</NavLink>
                </div>
              </Row>
              <div className="content-wrapper">
                <h1 className="heading">Start Your Free Trial</h1>
                <div className="color-divider m-0" />
                <div className="error-message">{googleError || emailError}</div>
                <Form form={form} layout="vertical email-input" onFinish={doSignup}>
                  <Form.Item
                    label="Enter your email"
                    name="email"
                    labelCol={false}
                    rules={[
                      { required: true, message: 'Please input your email' },
                      {
                        type: 'email',
                        message: 'Please input a valid email',
                      },
                      {
                        required: !!emailError,
                        message: emailError,
                      },
                    ]}>
                    <Input tabIndex={1} />
                  </Form.Item>
                  <Button
                    tabIndex={2}
                    type="primary"
                    htmlType="submit"
                    size="large"
                    block
                    className="signup-btn">
                    <span className="spacer" />
                    <span className="text-content">CREATE YOUR FIRST PROPOSAL</span>
                  </Button>
                </Form>
                <p className="cancel-text">Cancel anytime. No credit card required.</p>
                <div className="login-divider">
                  <div className="inner-login-divider"> Or sign up using </div>
                </div>
                <CustomGoogleButton
                  className="google-login-button"
                  text="Google"
                  onClick={() => login()}
                />
              </div>

              <div className="footer">
                <a
                  href="https://www.iubenda.com/privacy-policy/50076459"
                  target="_blank"
                  rel="noreferrer">
                  Privacy
                </a>
                <a href="https://goprospero.com/gdpr" target="_blank" rel="noreferrer">
                  GDPR
                </a>
                <a href="https://goprospero.com/terms" target="_blank" rel="noreferrer">
                  Terms
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

Signup.propTypes = {
  loggedIn: false,
};

Signup.propTypes = {
  loggedIn: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default Signup;
