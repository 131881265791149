import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useReactiveVar } from '@apollo/client';

import { Layout, Button, List, Popover } from 'antd';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

import CallIcon from 'components/Icons/CallIcon';
import MessageIcon from 'components/Icons/MessageIcon';
import WhatsappIcon from 'components/Icons/WhatsappIcon';
import utils from 'utils/utils';
import { userVar } from 'graphql/cache';

import './PublishFooter.scss';

const PublishFooter = ({
  configText,
  proposal,
  setMakePayment,
  publishedProposalLink,
  isClient,
  setFooterHeight,
  templateUserWixPreview,
  spamUrls,
}) => {
  const user = useReactiveVar(userVar);

  const footerRef = useRef();
  const [buttons, setButtons] = useState([]);
  const [hasSignedProposal, setHasSignedProposal] = useState(false);
  const [hasMadePayment, setHasMadePayment] = useState(false);

  const [showMakePayment, setShowMakePayment] = useState(false);

  useEffect(() => {
    if (
      proposal?.draft?.signature?.additionalSignature &&
      proposal?.signature?.additionalSignature &&
      proposal?.signature?.name
    ) {
      setHasSignedProposal(true);
    } else if (
      !proposal?.draft?.signature?.additionalSignature &&
      proposal?.signature &&
      proposal?.signature.date
    ) {
      setHasSignedProposal(true);
    } else {
      setHasSignedProposal(false);
    }
    if (proposal.payment && Object.keys(proposal.payment)) {
      setHasMadePayment(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposal]);

  // buttons to display
  useEffect(() => {
    const _buttons = [];

    if (proposal.payment && Object.keys(proposal.payment)) {
      _buttons.push('payment');
    }
    if (getBottomContactVisibility !== 'none') {
      _buttons.push('contact');
    }

    if (proposal?.stripe?.stripe_user_id && proposal?.clientPayableAmount && !proposal.payment) {
      _buttons.push('stripe_user_id');
    } else if (proposal?.stripe?.type === 'custom payment link' && proposal?.clientPayableLink) {
      _buttons.push('stripe_custom');
    }

    if (!proposal?.disableSig && (!proposal?.signature || !proposal?.signature?.date)) {
      _buttons.push('signature');
    }

    const { enablePayment, clientPayableAmount, draft, payment, signature } = proposal;
    if (
      enablePayment === 'after_sign' &&
      draft?.signature?.additionalSignature &&
      signature?.additionalSignature === null
    ) {
      _buttons.push('signature');
      setShowMakePayment(false);
    } else if (
      enablePayment === 'after_sign' &&
      !draft?.signature?.additionalSignature &&
      signature !== null
    ) {
      setHasMadePayment(true);
    }
    if (enablePayment === 'before_sign' && clientPayableAmount !== null && payment === null) {
      setShowMakePayment(true);
    } else if (hasSignedProposal) {
      setShowMakePayment(true);
    } else if (hasMadePayment) {
      setShowMakePayment(false);
    } else {
      setShowMakePayment(false);
    }

    setButtons(_buttons);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSignedProposal, hasMadePayment]);

  // Preview buttons
  useEffect(() => {
    if (!isClient) {
      const _buttons = [];
      if (proposal.payment && Object.keys(proposal.payment)) {
        _buttons.push('payment');
      }
      if (getBottomContactVisibility !== 'none') {
        _buttons.push('contact');
      }

      if (proposal?.stripe?.stripe_user_id && proposal?.clientPayableAmount && !proposal.payment) {
        _buttons.push('stripe_user_id');
      } else if (proposal?.stripe?.type === 'custom payment link' && proposal?.clientPayableLink) {
        _buttons.push('stripe_custom');
      }

      if (!proposal?.disableSig && (!proposal?.signature || !proposal?.signature?.date)) {
        _buttons.push('signature');
      }

      setButtons(_buttons);

      const { enablePayment, clientPayableAmount, payment, signature } = proposal;
      if (enablePayment === 'before_sign' && clientPayableAmount !== null && payment === null) {
        setShowMakePayment(true);
      } else if (enablePayment === 'after_sign' && signature !== null) {
        setShowMakePayment(true);
      } else {
        setShowMakePayment(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposal, isClient]);

  const headersection = proposal?.draft?.header;

  let namehtml = headersection?.name;
  if (headersection?.rawname) {
    const namecs = convertFromRaw(headersection.rawname);
    namehtml = stateToHTML(namecs);
  }
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = namehtml;
  const namehtmlclean = tempDiv.textContent;

  // const processContactName = (name) => {
  //   if (name) {
  //     return name.substring(0, 18);
  //   }
  // };

  // get title
  let titlehtml =
    headersection?.title || (proposal?.project && proposal?.project?.name) || 'The  Project';
  if (headersection && headersection?.rawtitle) {
    const titlecs = convertFromRaw(headersection?.rawtitle);
    titlehtml = stateToHTML(titlecs);
    titlehtml = titlehtml?.replace(/<p><br><\/p>/g, '');
  }

  let publishShareOptions = [];
  let getBottomCallButtonVisibility = 'none';
  let getBottomContactVisibility = 'none';
  let whatsappNumber = proposal?.whatsappNumber?.replace(/\s/g, '') || '';
  let contactNumber = proposal?.contactnumber?.replace(/\s/g, '') || '';

  if (proposal?.contactOptions?.length) {
    const contactOptions = proposal.contactOptions.filter((contactOption) => contactOption.enabled);

    if (contactOptions?.length) {
      getBottomContactVisibility = 'block';
    }

    contactOptions.forEach((contactOption) => {
      if (contactOption.option === 'email') {
        publishShareOptions.push({
          type: 'email',
          title: configText('Via Email'),
          icon: <MessageIcon />,
          link: () =>
            window.open(
              `mailto:${contactOption.value || proposal.email}?body=${encodeURIComponent(
                'Proposal Link:' + publishedProposalLink()
              )}`,
              '_blank'
            ),
        });
      } else if (contactOption.option === 'call') {
        contactNumber = contactOption?.value?.replace(/\s/g, '') || contactNumber;
        publishShareOptions.push({
          type: 'call',
          title: configText('Via Call'),
          icon: <CallIcon />,
          link: () => window.open(`tel:${contactNumber}`, '_blank'),
        });
      } else if (contactOption.option === 'whatsapp') {
        whatsappNumber = contactOption?.value?.replace(/\s/g, '') || whatsappNumber;
        publishShareOptions.push({
          type: 'whatsapp',
          title: configText('Via WhatsApp'),
          icon: <WhatsappIcon />,
          link: () =>
            window.open(
              `https://${
                /Mobi|Android/i.test(navigator.userAgent) ? 'api' : 'web'
              }.whatsapp.com/send?phone=${whatsappNumber}&text=${publishedProposalLink()}`,
              '_blank'
            ),
        });
      }
    });
  } else {
    // Show call button only if contact number exists
    if (contactNumber) {
      getBottomCallButtonVisibility = proposal?.callButton || 'block';
    }
    getBottomContactVisibility = proposal?.contactButton || 'block';
    publishShareOptions = [
      {
        type: 'email',
        title: configText('Via Email'),
        icon: <MessageIcon />,
        link: () =>
          window.open(
            `mailto:${proposal.email}?body=${encodeURIComponent(
              'Proposal Link:' + publishedProposalLink()
            )}`,
            '_blank'
          ),
      },
      {
        type: 'whatsapp',
        title: configText('Via WhatsApp'),
        icon: <WhatsappIcon />,
        link: () =>
          window.open(
            `https://${
              /Mobi|Android/i.test(navigator.userAgent) ? 'api' : 'web'
            }.whatsapp.com/send?phone=${whatsappNumber}&text=${publishedProposalLink()}`,
            '_blank'
          ),
      },
    ];
    if (getBottomCallButtonVisibility === 'block') {
      publishShareOptions.unshift({
        type: 'call',
        title: configText('Via Call'),
        icon: <CallIcon />,
        link: () => window.open(`tel:${contactNumber}`, '_blank'),
      });
    }
  }

  const shouldRenderItem = (type) => {
    if (!proposal?.contactOptions?.length) {
      if (type === 'email') {
        return getBottomContactVisibility === 'none' && 'footer-inactive-list-item';
      } else if (type === 'call' || type === 'whatsapp') {
        return getBottomCallButtonVisibility === 'none' && 'footer-inactive-list-item';
      }
    }
  };

  const contactContent = () => {
    return (
      <List
        itemLayout="horizontal"
        dataSource={publishShareOptions}
        renderItem={(item) => (
          <List.Item onClick={item.link} className={shouldRenderItem(item.type)}>
            <List.Item.Meta avatar={item.icon} title={item.title} />
          </List.Item>
        )}
      />
    );
  };

  useEffect(() => {
    if (footerRef?.current) {
      setTimeout(() => {
        setFooterHeight(
          (footerRef.current?.offsetHeight || (buttons.length > 2 ? 100 : 50)) +
            (window.innerWidth < 768 ? 80 : 50)
        );
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [footerRef, window.innerWidth]);

  return (
    <Layout.Footer
      className={`publish-footer language-${proposal?.language?.toLowerCase() || 'english'}`}>
      <div className="proposal-text">
        <h1
          className="proposal-name"
          dangerouslySetInnerHTML={{
            __html: utils.changeVariable(
              proposal?.draft?.variables,
              titlehtml,
              user?.profile?.dateFormat
            ),
          }}
        />
        <h2
          className="client-name"
          style={{ textTransform: 'capitalize' }}
          dangerouslySetInnerHTML={{
            __html: utils.changeVariable(
              proposal?.draft?.variables,
              namehtml,
              user?.profile?.dateFormat
            ),
          }}
        />
      </div>

      <div ref={footerRef} className={`footer-buttons buttons-${buttons.length}`}>
        {buttons.includes('payment') && (
          <a
            href={proposal.payment?.receipt_url}
            target="_blank"
            rel="noreferrer"
            className="paid-text">
            {`Paid On: ${moment(proposal.payment.created).format(
              user?.profile?.dateFormat || 'DD/MM/YYYY'
            )}`}
          </a>
        )}

        {buttons.includes('contact') && !templateUserWixPreview && (
          <Popover content={contactContent} overlayClassName="footer-buttons-popover">
            <Button className="contact-btn">
              {`${configText('contact word') || 'Contact'} ${utils.changeVariable(
                proposal?.draft?.variables,
                namehtmlclean
              )}`}
            </Button>
          </Popover>
        )}

        {showMakePayment && buttons.includes('stripe_user_id')
          ? !buttons.includes('payment') && (
              <Button
                className="contact-btn"
                onClick={() => (isClient ? setMakePayment(true) : () => {})}>
                {configText('MAKE PAYMENT')}
              </Button>
            )
          : null}

        {buttons.includes('stripe_custom') ? (
          <Button
            className="contact-btn"
            onClick={() => {
              // don't open if flagges as spam
              if (spamUrls?.[proposal.clientPayableLink]) {
                return;
              }
              isClient ? window.open(proposal.clientPayableLink, '_blank') : () => {};
            }}>
            {configText('MAKE PAYMENT')}
          </Button>
        ) : null}

        {buttons.includes('signature') ? (
          <Button
            className="signature-button"
            onClick={() => {
              document.getElementById('proposal-signaturesection').scrollIntoView();
            }}
            style={{
              backgroundColor: proposal?.draft?.signature?.signatureBackgroundColor || '#005aff',
              color: proposal?.draft?.signature?.signatureTextColor || 'white',
            }}>
            {proposal?.draft?.signature?.btnText || configText('sign proposal button text')}
          </Button>
        ) : null}
      </div>
    </Layout.Footer>
  );
};

PublishFooter.defaultProps = {
  proposal: '',
  configText: null,
  setMakePayment: () => {},
  publishedProposalLink: () => {},
  isClient: false,
  setFooterHeight: () => {},
  templateUserWixPreview: false,
};

PublishFooter.propTypes = {
  proposal: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  configText: PropTypes.instanceOf(Object),
  setMakePayment: PropTypes.func,
  publishedProposalLink: PropTypes.func,
  isClient: PropTypes.bool,
  setFooterHeight: PropTypes.func,
  templateUserWixPreview: PropTypes.bool,
};

export default PublishFooter;
