/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.99994 8.43928L11.4393 0.999943C11.7322 0.70705 12.2071 0.70705 12.4999 0.999943C12.7928 1.29284 12.7928 1.76771 12.4999 2.0606L3.99994 10.5606L0.499943 7.0606C0.20705 6.76771 0.20705 6.29284 0.499943 5.99994C0.792837 5.70705 1.26771 5.70705 1.5606 5.99994L3.99994 8.43928Z"
      fill="currentColor"
    />
  </svg>
);

const CheckIcon = (props) => <Icon component={svg} {...props} />;

export default CheckIcon;
