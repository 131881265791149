import React from 'react';
import PropTypes from 'prop-types';

import ExternalToolbar from './ExternalToolbar';

const ToolBar = ({
  bodyFont,
  language,
  inlineToolbar,
  editorState,
  onToggle,
  onToggleBlockType,
  onToggleFontInlineStyle,
  onToggleCustomBockStyle,
  onClearFormatting,
  onSplitSection,
  dispatch,
  zoomValue,
  toggleColor,
  selectionActive,
}) => {
  return (
    <div
      className="rich-editor-header"
      style={{
        zoom: 100 / zoomValue,
      }}>
      <div className="rich-editor-toolbar">
        <ExternalToolbar
          selectionActive={selectionActive}
          inlineToolbar={inlineToolbar.show}
          language={language?.toLowerCase()}
          bodyFont={bodyFont}
          editorState={editorState}
          onToggle={onToggle}
          onToggleBlockType={onToggleBlockType}
          onToggleFontInlineStyle={onToggleFontInlineStyle}
          onToggleCustomBockStyle={onToggleCustomBockStyle}
          onClearFormatting={onClearFormatting}
          onSplitSection={onSplitSection}
          dispatch={dispatch}
          toggleColor={toggleColor}
        />
      </div>
    </div>
  );
};

ToolBar.defaultProps = {
  inlineToolbar: { show: false },
  bodyFont: '',
  zoomValue: 100,
  selectionActive: false,
};

ToolBar.propTypes = {
  bodyFont: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  language: PropTypes.string.isRequired,
  editorState: PropTypes.instanceOf(Object).isRequired,
  onToggle: PropTypes.func.isRequired,
  onToggleBlockType: PropTypes.func.isRequired,
  onToggleFontInlineStyle: PropTypes.func.isRequired,
  onToggleCustomBockStyle: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  inlineToolbar: PropTypes.instanceOf(Object),
  onClearFormatting: PropTypes.func.isRequired,
  onSplitSection: PropTypes.func.isRequired,
  zoomValue: PropTypes.number,
  selectionActive: PropTypes.bool,
};

export default ToolBar;
