import React, { useRef, useEffect, useReducer, useState } from 'react';
import { Slider, Progress, Spin, message, Menu } from 'antd';
import { useReactiveVar } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { stateFromHTML } from 'draft-js-import-html';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import Dropzone from 'react-dropzone';
import _ from 'lodash';
import { SketchPicker } from 'react-color';

import {
  Editor,
  EditorState,
  DefaultDraftBlockRenderMap,
  convertFromRaw,
  convertToRaw,
  RichUtils,
  Modifier,
  AtomicBlockUtils,
  SelectionState,
  ContentState,
  getDefaultKeyBinding,
  ContentBlock,
  genKey,
  convertFromHTML,
} from 'draft-js';

import {
  reducer,
  initialState,
  blockStyler,
  decorator,
  editorChange,
  blockRenderer,
  createCellBlock,
} from './lib';
import { ToolBar } from './components';
import { userVar, fontLoadedVar } from 'graphql/cache';
import { resizeImageForS3 } from 'utils/uploadToS3';
import {
  fontWeights,
  fontSizes,
  defaultFormFields,
  fontListEnglish,
  fontListHebrew,
} from 'pages/Proposal/constants/constants';

import RichEditorModals from './RichEditorModals/RichEditorModals';
import Input from 'components/Input';
import Uploader from 'components/Uploader';
import helpers, { loadProposalFont, scrollToSection } from 'helpers/proposal';
import FileCopier from 'components/FileCopier';
import { ELEMENTS_VARIABLES, testimonial } from 'constants/index';
import ChevronIcon from 'components/Icons/ChevronIcon';
import proposalUtils from '../Draft/utils';
import utils from './utils';
import commonUtils from 'utils/utils';
import useOutsideClick from 'hooks/useOutsideClick';
import useSuspiciousLink from 'hooks/useSuspiciousLink';

import './RichEditor.scss';
import { HyperLinkSection } from './components/HyperLinkSection/HyperLinkSection';

const { Map } = Immutable;
const { SubMenu } = Menu;
export const URL_PATTERN = /^(ftp|http|https):\/\/[^ "]+$/;
let fontLoaded = [];

const RichEditor = (props) => {
  const {
    id,
    raw,
    prop,
    config,
    configText,
    element,
    setElement,
    elementValue,
    blockRenderMap,
    content,
    htmlOptions,
    simple,
    placeholder,
    contentType,
    saveProposal,
    saveUser,
    zoomValue,
    sectionName,
    setSelectedBlock,
    updateLoaderState,
    setOpenVariablesList,
    setScrollingTo,
    sectionType,
    saveProposalDraft,
    setDisablePreview,
    dropDisabled,
    setDraggingElement,
    numberOfColumns,
    sectionSpan,
    titleSpan,
    setElementsInSection,
    wixEditor,
    activeEditorId,
    setActiveEditorId,
    isEditingModal,
    templateWixEditor,
  } = props;

  const user = useReactiveVar(userVar);
  const { checkSuspiciousUrl } = useSuspiciousLink({ proposalId: prop._id });

  const [state, dispatch] = useReducer(reducer, initialState);

  const editor = useRef(null);
  const linkInputRef = useRef();
  const linkInpuTextRef = useRef();
  const imageInputRef = useRef();
  const editorRef = useRef();

  const [upload, uploadStatus] = Uploader();
  const [showImageProgress, toggleImageProgress] = useState(false);
  const [imageName, setImageName] = useState('');
  const [readOnly, setReadOnly] = useState(false);
  const [toolbarPosition, setToolbarPosition] = useState({});
  const [shouldCheckUrl, setShouldCheckUrl] = useState(null);
  const [, setDeletedBlockKeys] = useState([]);

  const { selectedBlock } = state;
  const [copy, copyStatus] = FileCopier();
  const [bodyFont, setBodyFont] = useState(null); // store draft.bodyFont

  const focusEditor = () => {
    if (dropDisabled) {
      setReadOnly(false);
    }

    editor.current.focus();
    dispatch({ type: 'setEditorReadOnly', editorReadOnly: false });
  };

  useEffect(
    () => setSelectedBlock && setSelectedBlock(selectedBlock),
    [selectedBlock, setSelectedBlock]
  );

  useEffect(() => {
    // for simple section only
    if (simple === 0 && !_.isEqual(prop?.draft?.bodyFont, bodyFont)) {
      setBodyFont(prop.draft.bodyFont);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prop?.draft?.bodyFont]);

  useEffect(() => {
    if (bodyFont) {
      editorRef.current = state?.editorState;
      setTimeout(focusEditor, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bodyFont]);

  const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

  useEffect(() => {
    if (shouldCheckUrl) {
      checkSuspiciousUrl(shouldCheckUrl);
      setShouldCheckUrl(null);
    }

    const currentSelection = state?.editorState ? state?.editorState?.getSelection() : null;
    const currentRaw = editorRef?.current?.getCurrentContent()
      ? convertToRaw(editorRef?.current?.getCurrentContent())
      : '';

    if (
      ((currentSelection && currentSelection?.getHasFocus() && state.keyType) ||
        !currentSelection?.getHasFocus()) &&
      raw &&
      !_.isEqual(raw, currentRaw)
    ) {
      let tempEditorState = EditorState.createWithContent(
        convertFromRaw(raw, extendedBlockRenderMap),
        decorator
      );

      tempEditorState = EditorState.acceptSelection(tempEditorState, currentSelection);

      editorRef.current = tempEditorState;
      dispatch({ type: 'setEditorState', editorState: tempEditorState });
      dispatch({ type: 'setKeyType', keyType: '' });
    } else if (!editorRef.current) {
      let tempEditorState = raw
        ? EditorState.createWithContent(convertFromRaw(raw, extendedBlockRenderMap))
        : content
        ? EditorState.createWithContent(stateFromHTML(content, htmlOptions || {}))
        : EditorState.createEmpty();

      tempEditorState = EditorState.set(tempEditorState, { decorator });

      editorRef.current = tempEditorState;
      dispatch({ type: 'setEditorState', editorState: tempEditorState });
      dispatch({
        type: 'setBlockRenderMap',
        blockRenderMap: extendedBlockRenderMap,
      });
      dispatch({ type: 'setSimple', simple: simple || 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [raw]);

  // calls update draft hooks to avoid cancellation during rerendering
  const saveDraft = proposalUtils.updateDraftHook(saveProposalDraft, setDisablePreview)(
    sectionName,
    sectionType
  );

  const handleEditorChange = ({ es, force = false, update, scrollActive }) => {
    if ((update === 'table' || update === 'form') && updateLoaderState) {
      updateLoaderState(true);
    }
    editorChange({
      editorState: es,
      props: { ...props, saveDraft },
      dispatch,
      editorRef,
      updateSelection: utils.updateSelection,
      force,
      editorCallback: () => {
        if ((update === 'table' || update === 'form') && updateLoaderState) {
          updateLoaderState(false);
        }
        if (scrollActive) {
          scrollToSection({ editorState: es });
        }
      },
    });
  };

  const handleTextInsert = async (editorState, insertAfterBlock) => {
    const contentState = editorState?.getCurrentContent();
    const contentBlock = contentState?.getBlockMap();

    if (contentBlock?.size < 2 || insertAfterBlock) {
      const newBlock = new ContentBlock({
        key: genKey(),
        type: 'unstyled',
        text: '',
        characterList: Immutable.List(),
      });
      let newBlockMap;

      if (insertAfterBlock) {
        // Split the blocks
        const blocksBefore = contentBlock.toSeq().takeUntil(function (v) {
          return v === currentBlock;
        });

        const blocksAfter = contentBlock
          .toSeq()
          .skipUntil(function (v) {
            return v === currentBlock;
          })
          .rest();

        const currentBlock = contentState.getBlockForKey(editorState.getSelection().getEndKey());

        const newBlocks = [
          [currentBlock.getKey(), currentBlock],
          [
            newBlock.key,
            new ContentBlock({
              key: newBlock.key,
              type: 'unstyled',
              text: '',
              characterList: Immutable.List(),
            }),
          ],
        ];
        newBlockMap = blocksBefore.concat(newBlocks, blocksAfter).toOrderedMap();
      } else {
        newBlockMap = contentState.getBlockMap().set(newBlock.key, newBlock);
      }

      const newEditorState = await EditorState.push(
        editorState,
        ContentState.createFromBlockArray(newBlockMap.toArray())
          .set('selectionBefore', contentState.getSelectionBefore())
          .set('selectionAfter', contentState.getSelectionAfter())
      );

      const newSelection = new SelectionState({
        anchorKey: newBlock.key,
        anchorOffset: 0,
        focusKey: newBlock.key,
        focusOffset: 0,
        hasFocus: true,
      });

      editorState = await EditorState.acceptSelection(newEditorState, newSelection);
    } else {
      editorState = await RichUtils.insertSoftNewline(
        RichUtils.insertSoftNewline(editorState || state.editorState)
      );
    }

    handleEditorChange({
      es: editorState,
      scrollActive: true,
    });
  };

  const _toggleBlockType = async (
    blockType,
    customBlock = false,
    editorState,
    isActive = false
  ) => {
    editorState = editorState || state.editorState;

    const selectionState = editorState.getSelection();
    let contentState = editorState.getCurrentContent();
    const startKey = selectionState?.getStartKey();
    const contentBlock = contentState?.getBlockForKey(startKey);

    const data = contentBlock?.getData();

    const needRemoval = isActive !== blockType ? true : false;

    if (customBlock) {
      if (data?.size && isActive === blockType) {
        contentState = Modifier.setBlockData(contentState, selectionState, Immutable.Map({}));
        editorState = EditorState.push(editorState, contentState, 'change-block-data'); //EditorState.createWithContent(contentState);
      } else {
        contentState = Modifier.setBlockData(
          contentState,
          selectionState,
          blockType === 'none' ? Immutable.Map({}) : Immutable.Map({ className: blockType })
        );
        editorState = EditorState.push(editorState, contentState, 'change-block-data'); //EditorState.createWithContent(contentState);
      }
      blockType = blockType === 'none' ? '' : customBlock;
    } else {
      if (data.size) {
        contentState = Modifier.setBlockData(contentState, selectionState, Immutable.Map({}));
        editorState = EditorState.push(editorState, contentState, 'change-block-data'); // EditorState.createWithContent(contentState);
      }
    }

    if (
      (blockType === 'ordered-list-item' || blockType === 'unordered-list-item') &&
      isActive !== 'unstyled' &&
      needRemoval
    ) {
      editorState = await RichUtils.toggleBlockType(editorState, isActive);
    } else if (
      (blockType === 'ordered-list-item' || blockType === 'unordered-list-item') &&
      isActive !== customBlock &&
      blockType !== isActive &&
      isActive !== 'unstyled' &&
      needRemoval
    ) {
      const { editorState: newEditorState } = utils.insertEmptyBlock('before', editorState);
      editorState = newEditorState;
    }

    handleEditorChange({
      es: await RichUtils.toggleBlockType(
        EditorState.forceSelection(editorState, selectionState),
        blockType
      ),
      force: true,
    });
  };

  const handleImageInsert = (editorState) => {
    dispatch({ type: 'setDropEditorState', dropEditorState: editorState || state.editorState });
    imageInputRef.current.click();
  };

  const handleInsertFromMedia = async (src, editorState) => {
    // todo:  handle other files(which are not image), progress
    if (editorState) {
      dispatch({ type: 'setDropEditorState', dropEditorState: editorState });
    }

    editorState = editorState || state.editorState;

    const url = await copy({
      fromUrl: src,
      prefix: `props/${prop._id}`,
    });

    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('image', 'IMMUTABLE', {
      src: url,
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    handleEditorChange({
      es: AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' '),
      scrollActive: true,
    });
  };

  const handleInsertVideo = (editorState) => {
    dispatch({ type: 'setVideoDialog', videoDialog: editorState || state.editorState });
  };

  const handleInsertGallery = async (editorState) => {
    // this.props.startDraftLoad();
    editorState = editorState || state.editorState;

    const data = [];
    const contentState = editorState.getCurrentContent();

    const contentStateWithEntity = contentState.createEntity('gallery', 'MUTABLE', { data }); // ,
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const es = await AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
    handleEditorChange({
      es,
      scrollActive: true,
    });
  };

  const handleInsertTable = async (editorState) => {
    let newEditorState = editorState || state.editorState;

    const data = [];
    const row = 3;
    const column = 3;

    for (let r = 0; r < row; r++) {
      data[r] = {};
      data[r].id = r + 1;
      for (let c = 0; c < column; c++) {
        data[r][c + 1] = '-';
      }
    }

    const contentState = newEditorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('table', 'MUTABLE', {
      data,
      config: { topRowColor: 'lightgrey', rowColor: 'white' },
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const es = await AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');

    handleEditorChange({
      es,
      update: 'table',
      scrollActive: true,
    });

    dispatch({ type: 'setDropEditorState', dropEditorState: '' });
  };

  const handleInsertTestimonial = async (editorState) => {
    let newEditorState = editorState || state.editorState;

    const contentState = newEditorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('testimonial', 'MUTABLE', {
      data: [testimonial.data, testimonial.data, testimonial.data],
      config: {},
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const es = await AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');

    handleEditorChange({
      es,
      update: 'testimonial',
      scrollActive: true,
    });

    dispatch({ type: 'setDropEditorState', dropEditorState: '' });
  };

  const handleInsertForm = async (editorState) => {
    editorState = editorState || state.editorState;

    const data = [...defaultFormFields];
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('form', 'MUTABLE', {
      data: data,
      config: { editable: true, columns: 1 },
      language: prop?.language?.toLowerCase(),
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const es = await AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
    handleEditorChange({
      es,
      update: 'form',
      scrollActive: true,
    });
  };

  const handleInsertPriceMiles = async (type, editorState) => {
    if (type === 'price') {
      const isDropable = commonUtils.calculatePricingDropable(titleSpan, sectionSpan);
      if (!isDropable) {
        return;
      }
    }

    const es = await utils.insertTeXBlock(editorState || state.editorState, type);

    editorRef.current = es;
    dispatch({ type: 'setLiveTeXEdits', liveTeXEdits: Map() });
    dispatch({ type: 'setEditorState', editorState: es });
  };

  const handleInsertDivider = async (editorState) => {
    editorState = editorState || state.editorState;

    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('divider', 'IMMUTABLE', {});
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });

    const es = await AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
    handleEditorChange({
      es,
      scrollActive: true,
    });
  };

  const handleInsertHTML = (editorState) => {
    dispatch({
      type: 'setHtmlEdit',
      htmlEdit: { htmlCode: '', editorState: editorState || state.editorState },
    });
  };

  const insertImage = async (event) => {
    event.preventDefault();
    const { files } = event.target;

    if (files && !files.length) return;

    const { url } = await upload(files[0], `props/${prop._id}/section`);
    const editorState = state.dropEditorState || state.editorState;

    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('image', 'IMMUTABLE', {
      src: url,
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const es = await AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');

    handleEditorChange({
      es,
      scrollActive: true,
    });
    dispatch({ type: 'setDropEditorState', dropEditorState: '' });
    event.target.value = null;
  };

  const dropImage = async (files) => {
    const [file] = files;
    setImageName(file.name);
    toggleImageProgress(true);

    try {
      await resizeImageForS3({ file, size: {}, path: 'sections' });

      const { url } = await upload(file, `props/${prop._id}/section`);
      const { editorState } = state;

      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity('image', 'IMMUTABLE', {
        src: url,
      });
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const es = await AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');

      handleEditorChange({
        es,
        scrollActive: true,
      });
    } catch (error) {
      message.error(error.message);
    }
    toggleImageProgress(false);
  };

  const handleDirectImageInsert = async (src) => {
    const url = await copy({
      fromUrl: src,
      prefix: `props/${prop._id}`,
    });
    const { editorState } = state;

    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('image', 'IMMUTABLE', {
      src: url,
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const es = await AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');

    handleEditorChange({
      es,
      scrollActive: true,
    });
  };

  useEffect(() => {
    if (selectedBlock && element) {
      if (element === 'text') handleTextInsert();
      else if (element === 'blockquote') _toggleBlockType(element);
      else if (element === 'image') handleImageInsert();
      else if (element === 'video') handleInsertVideo();
      else if (element === 'gallery') handleInsertGallery();
      else if (element === 'table') handleInsertTable();
      else if (element === 'testimonial') handleInsertTestimonial();
      else if (element === 'form') handleInsertForm();
      else if (element === 'price') handleInsertPriceMiles('price');
      else if (element === 'miles') handleInsertPriceMiles('miles');
      else if (element === 'divider') handleInsertDivider();
      else if (element === 'add-media-image') handleDirectImageInsert(elementValue);
      else if (element === 'html') handleInsertHTML();
      setElement('');
    } else if (element) {
      setElement('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element, elementValue]);

  // Link starts here
  const removeLink = () => {
    const { editorState } = state;
    const selection = editorState.getSelection();

    if (!selection.isCollapsed()) {
      handleEditorChange({ es: RichUtils.toggleLink(editorState, selection, null) });
    }
  };

  const promptForLink = () => {
    const { editorState } = state;
    const selection = editorState.getSelection();

    if (!selection.isCollapsed()) {
      dispatch({ type: 'setDisplayLinkInput', displayLinkInput: true });
      dispatch({ type: 'setLinkValue', linkValue: '' });
      dispatch({ type: 'setSelectionState', selectionState: selection });
      setTimeout(() => linkInputRef.current.focus(), 0);
    }
  };

  const promptForVariable = (editorState, onlyOpenToolBar, variableName) => {
    variableName = variableName.replace('{{', '');
    variableName = variableName.replace('}}', '');
    const [category] = variableName.split('.');

    if (onlyOpenToolBar) {
      setScrollingTo(category);
    }

    setScrollingTo(category);

    if (!onlyOpenToolBar) {
      const selection = editorState.getSelection();
      dispatch({ type: 'setSelectionState', selectionState: selection });
      // dispatch({ type: 'setEditorReadOnly', editorReadOnly: true });
      dispatch({ type: 'setDisplayVariableInput', displayVariableInput: true });
    }

    setOpenVariablesList(true);
  };

  const promptForTextLink = () => {
    const { editorState } = state;

    dispatch({ type: 'setDisplayLinkTextInput', displayLinkTextInput: true });
    dispatch({ type: 'setLinkValue', linkValue: '' });
    dispatch({ type: 'setLinkTextValue', linkTextValue: '' });
    dispatch({ type: 'setSelectionState', selectionState: editorState.getSelection() });

    setTimeout(() => linkInpuTextRef.current.focus(), 0);
  };

  const handleTextLinkInsert = (e) => {
    e.preventDefault();

    const { editorState } = state;
    const contentState = editorState.getCurrentContent();
    const selection = state.selectionState || editorState.getSelection();
    const text = state.linkTextValue.trim();
    let { linkValue: urlValue } = state;

    // create new content with text
    const newContent = Modifier.insertText(contentState, selection, text);

    urlValue = urlValue.trim();
    if (urlValue.indexOf('http') !== 0) {
      urlValue = `http://${urlValue}`;
    }

    // create new link entity
    const newContentWithEntity = newContent.createEntity('LINK', 'MUTABLE', {
      url: urlValue,
    });
    const entityKey = newContentWithEntity.getLastCreatedEntityKey();

    // create new selection with the inserted text
    const anchorOffset = selection.getAnchorOffset();
    const newSelection = new SelectionState({
      anchorKey: selection.getAnchorKey(),
      anchorOffset,
      focusKey: selection.getAnchorKey(),
      focusOffset: anchorOffset + text.length,
    });

    // and aply link entity to the inserted text
    const newContentWithLink = Modifier.applyEntity(newContentWithEntity, newSelection, entityKey);

    // create new state with link text
    const withLinkText = EditorState.push(editorState, newContentWithLink, 'insert-characters');

    // // now lets add cursor right after the inserted link
    const withProperCursor = EditorState.forceSelection(
      withLinkText,
      newContent.getSelectionAfter()
    );

    handleEditorChange({ es: withProperCursor });

    dispatch({ type: 'setDisplayLinkTextInput', displayLinkTextInput: false });

    // // update the editor with all changes
    // this.setState({editorState: withProperCursor });
    setShouldCheckUrl(urlValue);
  };

  const saveLink = (e) => {
    e.preventDefault();
    let { linkValue: urlValue } = state;
    const { editorState } = state;

    urlValue = urlValue.trim();
    if (urlValue.indexOf('http') !== 0) {
      urlValue = `http://${urlValue}`;
    }

    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
      url: urlValue,
    });

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const es = RichUtils.toggleLink(
      editorState,
      state.selectionState || editorState.getSelection(),
      entityKey
    );

    handleEditorChange({ es });

    dispatch({ type: 'setDisplayLinkInput', displayLinkInput: false });
    dispatch({ type: 'setLinkValue', linkValue: '' });
    dispatch({ type: 'setSelectionState', selectionState: '' });

    setShouldCheckUrl(urlValue);
  };

  const renderUrlInput = () => {
    const style = _.extend({}, state.inlineToolbar.position);

    return (
      <div style={style} className="link-input-box">
        <Input
          ref={linkInputRef}
          type="text"
          containerclass="link-input"
          placeholder="Enter link"
          onChange={(e) => dispatch({ type: 'setLinkValue', linkValue: e.target.value })}
          onKeyDown={(e) => e.which === 13 && saveLink(e)}
          onClick={(e) => e.stopPropagation()}
          id="urlInput"
          autoFocus
        />
      </div>
    );
  };

  const renderTextUrlInput = () => {
    const style = _.extend({}, state.currentCursorOffset.position);
    return (
      <div style={style} className="link-input-box link-text-and-url">
        <Input
          style={{ position: 'relative' }}
          ref={linkInpuTextRef}
          type="text"
          placeholder="Enter text"
          onChange={(e) =>
            dispatch({
              type: 'setLinkTextValue',
              linkTextValue: e.target.value,
            })
          }
          onKeyDown={(e) => e.which === 13 && handleTextLinkInsert(e)}
          onClick={(e) => e.stopPropagation()}
          id="urlTextInput"
          autoFocus
        />
        <Input
          style={{ position: 'relative' }}
          type="text"
          placeholder="Enter link"
          onChange={(e) => dispatch({ type: 'setLinkValue', linkValue: e.target.value })}
          onKeyDown={(e) => e.which === 13 && handleTextLinkInsert(e)}
          onClick={(e) => e.stopPropagation()}
          id="urlInput1"
          autoFocus
        />
      </div>
    );
  };

  // Link ends here

  // Color starts here
  const promptColorBox = (type = 'COLOR') => {
    // type = COLOR or BACKGROUND
    const { editorState } = state;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      dispatch({ type: 'setDisplayColorPicker', displayColorPicker: type });
      dispatch({
        type: 'setEditorReadOnly',
        editorReadOnly: true,
      });
    }
  };

  const clearInlineColorStyles = (es, type) => {
    const selection = es.getSelection();
    const contentState = es.getCurrentContent();
    const styles = [];
    // regex for COLOR = rgba should not have bg- infront of rgba
    // regex for BACKGROUND = it will match bg-rgba
    const testPattern = type === 'COLOR' ? /^(?!.*\bbg-.*\brgba\b).*\brgba\b/m : /\b(bg-rgba)\b/m;

    if (selection.isCollapsed()) {
      es.getCurrentInlineStyle().forEach(
        (style) => style && testPattern.test(style) && styles.push(style)
      );
    } else {
      let key = selection.getStartKey();
      let startOffset = selection.getStartOffset();
      const endKey = selection.getEndKey();
      const endOffset = selection.getEndOffset();

      while (true) {
        const lastRound = key === endKey;
        const block = contentState.getBlockForKey(key);
        const offsetEnd = lastRound ? endOffset : block.getLength();
        const characterList = block.getCharacterList();
        for (let offsetIndex = startOffset; offsetIndex < offsetEnd; offsetIndex++)
          characterList
            .get(offsetIndex)
            .getStyle()
            .forEach((style) => style && testPattern.test(style) && styles.push(style));
        if (lastRound) break;
        key = contentState.getKeyAfter(key);
        startOffset = 0;
      }
    }

    const contentWithoutStyles = _.reduce(
      styles,
      (newContentState, style) =>
        Modifier.removeInlineStyle(newContentState, es.getSelection(), style),
      es.getCurrentContent()
    );
    return EditorState.push(es, contentWithoutStyles, 'change-inline-style');
  };

  const toggleColor = (toggledColor, event, type) => {
    event && event.preventDefault() && event.stopPropagation();
    const { editorState } = state;

    const currentEditorState = clearInlineColorStyles(editorState, type);
    const currentStyle = editorState.getCurrentInlineStyle();
    let selectedColor = '';

    if (toggledColor) {
      selectedColor = `rgba(${toggledColor.rgb.r}, ${toggledColor.rgb.g}, ${toggledColor.rgb.b}, ${toggledColor.rgb.a})`;
    } else {
      if (type === 'COLOR') {
        // for text color
        selectedColor =
          currentStyle.filter((value) => value?.toString()?.startsWith('rgba')).last() || '#4a4a4a';
      } else {
        // for background
        selectedColor =
          currentStyle.filter((value) => value?.toString()?.startsWith('bg-')).last() ||
          'rgba(255,255,255,1)';
      }
    }

    if (type === 'BACKGROUND' && !selectedColor.startsWith('bg-')) {
      selectedColor = `bg-${selectedColor}`;
    }

    _toggleInlineStyle({
      es: currentEditorState,
      inlineStyle: selectedColor,
    });

    /*
    below code is working - it will save style as entityMap as below but problem rendering
    if (type === 'BACKGROUND') {
      "entityMap": {
          "0": {
            "type": "BACKGROUND",
            "mutability": "IMMUTABLE",
            "data": { "color": "rgba(241, 19, 19, 1)" }
          },
        }

      const contentState = currentEditorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity('BACKGROUND', 'IMMUTABLE', {
        color: selectedColor,
      });
  
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const es = RichUtils.toggleLink(
        currentEditorState,
        state.selectionState || currentEditorState.getSelection(),
        entityKey
      );
  
      handleEditorChange({ es });
    }
    */
  };

  const closeColor = () => {
    dispatch({ type: 'setDisplayColorPicker', displayColorPicker: false });
    dispatch({ type: 'setEditorReadOnly', editorReadOnly: false });
  };
  // Color ends here

  const _toggleCustomBockStyle = ({ blockType, type }) => {
    let { editorState } = state;
    let selection = editorState.getSelection();
    let startKey = selection.getStartKey();
    let endKey = selection.getEndKey();
    let currentContent = editorState.getCurrentContent();
    let prevBlockTypes = RichUtils.getCurrentBlockType(editorState);
    let newBlockType = '';
    let blocksToUpdate = [];
    let blockKey = startKey;
    while (blockKey !== endKey) {
      blocksToUpdate.push(blockKey);
      blockKey = currentContent.getKeyAfter(blockKey);
    }
    blocksToUpdate.push(endKey);

    prevBlockTypes = prevBlockTypes.split(' ');

    let directionBlockType = prevBlockTypes.find((prevBlockType) =>
      prevBlockType.match(/direction/)
    );
    let alignBlockType = prevBlockTypes.find((prevBlockType) => prevBlockType.match(/align/));
    let heightBlockType = prevBlockTypes.find((prevBlockType) => prevBlockType.match(/height/));
    let intentBlockType = prevBlockTypes.find((prevBlockType) => prevBlockType.match(/intent/));
    let listBlockType = prevBlockTypes.find((prevBlockType) => prevBlockType.match(/list-item/));

    let newContentState = currentContent;
    // Update className for each block
    if (type === 'align') {
      if (blockType === alignBlockType) alignBlockType = '';
      else if (blockType !== 'unstyled') alignBlockType = blockType;
      else alignBlockType = '';
    } else if (type === 'direction') {
      if (directionBlockType && blockType !== 'unstyled') {
        if (directionBlockType.match(/ltr/)) {
          directionBlockType = 'editor-direction-rtl';
        } else {
          directionBlockType = 'editor-direction-ltr';
        }
      } else if (blockType !== 'unstyled') directionBlockType = 'editor-direction-rtl';
      else directionBlockType = '';
    } else if (type === 'height') {
      if (listBlockType) {
        blocksToUpdate.forEach((blockKey) => {
          let block = newContentState.getBlockForKey(blockKey);
          let blockData = block.getData();
          prevBlockTypes = prevBlockTypes.filter(
            (className) => !className.startsWith('line-height__')
          );
          if (blockType !== 'unstyled') {
            prevBlockTypes.push(blockType);
          }

          // Merge updated data into the block
          let newData = blockData.set('className', prevBlockTypes.join(' '));
          let newBlock = block.merge({ data: newData });

          // Update the content state with the modified block
          newContentState = newContentState.merge({
            blockMap: newContentState.getBlockMap().set(blockKey, newBlock),
          });
        });
      } else if (blockType === heightBlockType) heightBlockType = '';
      else if (blockType !== 'unstyled') heightBlockType = blockType;
      else heightBlockType = '';
    } else if (type === 'intent') {
      let intentCount = intentBlockType?.split('-')[2] || 0;
      const language = prop?.language?.toLowerCase() || 'english';

      if (intentCount < 26 && intentCount >= 0) {
        if (blockType.match(/left/))
          language?.toLowerCase() === 'hebrew' || language?.toLowerCase() === 'arabic'
            ? ++intentCount
            : --intentCount;
        else if (blockType.match(/right/))
          language?.toLowerCase() === 'hebrew' || language?.toLowerCase() === 'arabic'
            ? --intentCount
            : ++intentCount;
      }

      intentBlockType =
        language?.toLowerCase() === 'hebrew' || language?.toLowerCase() === 'arabic'
          ? `intent-right-${intentCount}`
          : `intent-left-${intentCount}`;
    }

    newBlockType = `${alignBlockType || ''}  ${directionBlockType || ''} ${heightBlockType || ''} ${
      intentBlockType || ''
    } ${listBlockType || ''}`;

    // Update the editor state with the modified content
    const isLineHeight = type === 'height' && listBlockType;

    handleEditorChange({
      es: isLineHeight
        ? EditorState.push(editorState, newContentState, newBlockType)
        : RichUtils.toggleBlockType(editorState, newBlockType),
    });
  };

  // Styles toogle starts here
  const _toggleInlineStyle = ({ inlineStyle, es, withoutText }) => {
    if (inlineStyle === 'LINK') {
      removeLink();
      if (withoutText) promptForTextLink();
      else promptForLink();
    } else if (inlineStyle === 'COLOR' || inlineStyle === 'BACKGROUND') {
      promptColorBox(inlineStyle);
    } else if (inlineStyle === 'right-intent' || inlineStyle === 'left-intent') {
      _toggleCustomBockStyle({
        blockType: inlineStyle,
        type: 'intent',
      });
    } else {
      handleEditorChange({ es: RichUtils.toggleInlineStyle(es || state.editorState, inlineStyle) });
    }
  };

  const _toggleFontInlineStyle = ({ inlineStyle, type, fontFamilies }) => {
    const { editorState } = state;
    const selection = editorState.getSelection();

    let nextContentState = '';

    if (type === 'family') {
      // Let's just allow one font at a time. Turn off all active fonts.
      fontFamilies = fontFamilies.map((fontList) => fontList.family);
      nextContentState = fontFamilies.reduce((contentState, font) => {
        return Modifier.removeInlineStyle(contentState, selection, font);
      }, editorState.getCurrentContent());
    } else if (type === 'weight') {
      // Let's just allow one weight at a time. Turn off all active weight.
      nextContentState = fontWeights.reduce((contentState, weight) => {
        return Modifier.removeInlineStyle(contentState, selection, `${weight}`);
      }, editorState.getCurrentContent());
    } else if (type === 'size' || type === 'increase-size' || type === 'decrease-size') {
      // Let's just allow one size at a time. Turn off all active size.
      nextContentState = fontSizes.reduce((contentState, size) => {
        return Modifier.removeInlineStyle(contentState, selection, size);
      }, editorState.getCurrentContent());
    }

    if (type === 'increase-size' || type === 'decrease-size') {
      const _currentStyle = editorState.getCurrentInlineStyle();

      let fontSizeIndex = fontSizes.indexOf('16px');

      // eslint-disable-next-line no-unused-vars
      _currentStyle.find((style) => {
        const index = fontSizes.indexOf(style);
        if (index > -1) {
          fontSizeIndex = index;
          return true;
        }
        return false;
      });

      if (type === 'increase-size') {
        fontSizeIndex = Math.min(fontSizeIndex + 1, fontSizes.length - 1);
      } else if (type === 'decrease-size') {
        fontSizeIndex = Math.max(fontSizeIndex - 1, 0);
      }
      inlineStyle = fontSizes[fontSizeIndex];
    }

    let nextEditorState = EditorState.push(editorState, nextContentState, 'change-inline-style');
    const currentStyle = editorState.getCurrentInlineStyle();

    // Unset style override for current style.
    if (selection.isCollapsed()) {
      nextEditorState = currentStyle.reduce((currentState, font) => {
        return RichUtils.toggleInlineStyle(currentState, font);
      }, nextEditorState);
    }

    // If the style is being toggled on, apply it.
    if (!currentStyle.has(inlineStyle)) {
      nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, `${inlineStyle}`);
    }

    handleEditorChange({ es: nextEditorState });
  };

  const _clearFormatting = () => {
    const { editorState } = state;

    const selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const focusKey = selectionState.getFocusKey();
    const currentContent = editorState.getCurrentContent();
    const currentBlock = currentContent.getBlockForKey(anchorKey);
    const selectionStart = selectionState.getStartOffset();
    const selectionEnd = selectionState.getEndOffset();

    if (anchorKey === focusKey) {
      const selectedText = currentBlock.getText().slice(selectionStart, selectionEnd);
      const contentWithoutStyles = Modifier.replaceText(
        editorState.getCurrentContent(),
        selectionState,
        selectedText,
        null
      );

      const newEditorState = EditorState.push(
        editorState,
        contentWithoutStyles,
        'change-inline-style'
      );
      handleEditorChange({ es: RichUtils.toggleBlockType(newEditorState, '') });
    } else {
      let headerRaw = '';
      let rawBlocks = [];
      if (sectionName === 'header') {
        let shouldBreak = false;
        for (const key of Object.keys(prop?.draft[sectionName])) {
          if (key.indexOf('raw') > -1) {
            for (const block of prop?.draft[sectionName][key].blocks) {
              if (block.key === focusKey || block.key === anchorKey) {
                shouldBreak = true;
                rawBlocks = JSON.parse(JSON.stringify(prop?.draft[sectionName][key].blocks));
                headerRaw = key;
                break;
              }
            }
          }
          if (shouldBreak) {
            break;
          }
        }
      } else {
        rawBlocks = JSON.parse(JSON.stringify(prop?.draft[sectionName]?.raw?.blocks));
      }
      let shouldSelect = false;

      if (selectionState.getIsBackward()) {
        for (let i = 0; i < rawBlocks.length; i++) {
          if (focusKey === rawBlocks[i].key) {
            shouldSelect = true;
            const inlineStyleRanges = [];
            for (let j = 0; j < rawBlocks[i].inlineStyleRanges.length; j++) {
              if (rawBlocks[i].inlineStyleRanges[j].offset < selectionStart) {
                if (
                  rawBlocks[i].inlineStyleRanges[j].offset +
                    rawBlocks[i].inlineStyleRanges[j].length >
                  selectionStart
                ) {
                  inlineStyleRanges.push({
                    ...rawBlocks[i].inlineStyleRanges[j],
                    length:
                      rawBlocks[i].inlineStyleRanges[j].offset +
                      rawBlocks[i].inlineStyleRanges[j].length -
                      selectionStart,
                  });
                } else {
                  inlineStyleRanges.push(rawBlocks[i].inlineStyleRanges[j]);
                }
              }
            }
            rawBlocks[i].inlineStyleRanges = inlineStyleRanges;
            if (rawBlocks[i].type !== 'atomic') {
              rawBlocks[i].type = 'unstyled';
            }
          } else if (rawBlocks[i].key === anchorKey) {
            shouldSelect = false;
            const inlineStyleRanges = [];
            for (let j = 0; j < rawBlocks[i].inlineStyleRanges.length; j++) {
              if (rawBlocks[i].inlineStyleRanges[j].offset > selectionEnd) {
                inlineStyleRanges.push(rawBlocks[i].inlineStyleRanges[j]);
              } else if (
                rawBlocks[i].inlineStyleRanges[j].offset +
                  rawBlocks[i].inlineStyleRanges[j].length >
                selectionEnd
              ) {
                inlineStyleRanges.push({
                  ...rawBlocks[i].inlineStyleRanges[j],
                  offset: selectionEnd,
                });
              }
            }
            rawBlocks[i].inlineStyleRanges = inlineStyleRanges;
            if (rawBlocks[i].type !== 'atomic') {
              rawBlocks[i].type = 'unstyled';
            }
          } else if (shouldSelect) {
            rawBlocks[i].inlineStyleRanges = [];
            if (rawBlocks[i].type !== 'atomic') {
              rawBlocks[i].type = 'unstyled';
            }
          }
        }
      } else {
        for (let i = 0; i < rawBlocks.length; i++) {
          if (anchorKey === rawBlocks[i].key) {
            shouldSelect = true;
            const inlineStyleRanges = [];
            for (let j = 0; j < rawBlocks[i].inlineStyleRanges.length; j++) {
              if (rawBlocks[i].inlineStyleRanges[j].offset < selectionStart) {
                if (
                  rawBlocks[i].inlineStyleRanges[j].offset +
                    rawBlocks[i].inlineStyleRanges[j].length >
                  selectionStart
                ) {
                  inlineStyleRanges.push({
                    ...rawBlocks[i].inlineStyleRanges[j],
                    length:
                      rawBlocks[i].inlineStyleRanges[j].offset +
                      rawBlocks[i].inlineStyleRanges[j].length -
                      selectionStart,
                  });
                } else {
                  inlineStyleRanges.push(rawBlocks[i].inlineStyleRanges[j]);
                }
              }
            }
            rawBlocks[i].inlineStyleRanges = inlineStyleRanges;
            if (rawBlocks[i].type !== 'atomic') {
              rawBlocks[i].type = 'unstyled';
            }
          } else if (rawBlocks[i].key === focusKey) {
            shouldSelect = false;
            const inlineStyleRanges = [];
            for (let j = 0; j < rawBlocks[i].inlineStyleRanges.length; j++) {
              if (rawBlocks[i].inlineStyleRanges[j].offset > selectionEnd) {
                inlineStyleRanges.push(rawBlocks[i].inlineStyleRanges[j]);
              } else if (
                rawBlocks[i].inlineStyleRanges[j].offset +
                  rawBlocks[i].inlineStyleRanges[j].length >
                selectionEnd
              ) {
                inlineStyleRanges.push({
                  ...rawBlocks[i].inlineStyleRanges[j],
                  offset: selectionEnd,
                });
              }
            }
            rawBlocks[i].inlineStyleRanges = inlineStyleRanges;
            if (rawBlocks[i].type !== 'atomic') {
              rawBlocks[i].type = 'unstyled';
            }
          } else if (shouldSelect) {
            rawBlocks[i].inlineStyleRanges = [];
            if (rawBlocks[i].type !== 'atomic') {
              rawBlocks[i].type = 'unstyled';
            }
          }
        }
      }

      let newEditorState = EditorState.createWithContent(
        convertFromRaw({
          blocks: rawBlocks,
          entityMap:
            sectionName === 'header'
              ? prop?.draft[sectionName][headerRaw]?.entityMap
              : prop?.draft[sectionName]?.raw?.entityMap,
        })
      );

      handleEditorChange({ es: RichUtils.toggleBlockType(newEditorState, '') });
    }
  };

  const _onSplitSection = async () => {
    updateLoaderState(true);
    const { editorState } = state;
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();

    let splitedContentState = Modifier.splitBlock(contentState, selectionState);
    let selectionAfter = splitedContentState.getSelectionAfter();

    let startBlock = selectionAfter.getStartKey();

    let blockAsArray = splitedContentState.getBlocksAsArray();

    let newBlock = [];
    let oldBlock = [];

    let ifReached = false;

    blockAsArray.forEach((block) => {
      if (ifReached || block.key === startBlock) {
        newBlock.push(block);
        ifReached = true;
      } else {
        oldBlock.push(block);
      }
    });

    let newContentState = ContentState.createFromBlockArray(newBlock);
    let oldContentState = ContentState.createFromBlockArray(oldBlock);

    const sectionId = uuidv4();

    // const newsection = {
    //   title: '<div></div>',
    //   text: '<div></div>',
    //   subtitle: '<div></div>',
    //   raw: convertToRaw(newContentState)
    // };

    const sectionOrder = helpers.findSectionOrder({
      proposal: prop,
      sectionId,
      sectionName,
      position: helpers.findNewSectionPosition({
        wrapperType: 'normal',
        sectionAlignment: 'below',
      }),
    });

    await saveProposal(
      {
        'draft.sectionorder': sectionOrder,
        [`draft.${sectionId}.raw`]: convertToRaw(newContentState),
        [`draft.${sectionName}.raw`]: convertToRaw(oldContentState),
      },
      () => {
        const newEditorState = EditorState.createWithContent(oldContentState);
        handleEditorChange({ es: newEditorState });
        updateLoaderState(false);
      }
    );
  };
  // Styles toogle ends here

  const onEdit = (x, type) => {
    let texobj;
    if (x && x.v) {
      texobj = null;
      if (x.v === -42) {
        // new pricing block
        dispatch({ type: 'setPriceEdit', priceEdit: x });
      } else if (x.v === -43) {
        // new miles block
        dispatch({ type: 'setTimeEdit', timeEdit: x });
      } else if (type === 'gallery') {
        dispatch({ type: 'setGalleryEdit', galleryEdit: x });
      } else if (type === 'html') {
        dispatch({ type: 'setHtmlEdit', htmlEdit: x });
      } else if (Array.isArray(x.v)) {
        dispatch({ type: 'setTableEdit', tableEdit: x });
      } else {
        try {
          texobj = JSON.parse(x.v);
        } catch (exx) {
          // console.log(exx);
        }
        if (texobj && texobj.deliverables) {
          dispatch({ type: 'setPriceEdit', priceEdit: x });
        } else if (texobj && texobj.milestones) {
          dispatch({ type: 'setTimeEdit', timeEdit: x });
        } else if (texobj && texobj.superPowers) {
          dispatch({ type: 'setSuperEdit', superEdit: x });
        }
      }
    }
  };

  // Render custom font color
  const customStyleFn = (styles) => {
    const output = {};
    const fontFamilies = Object.keys(state?.styleMap || {});
    const fontToBeLoad = [];

    // find font, color, background etc
    styles.forEach((value) => {
      const styleValue = value?.toString();

      // find fonts
      if (!fontLoadedVar()) {
        if (fontFamilies.includes(styleValue) && !fontLoaded.includes(styleValue)) {
          fontToBeLoad.push(styleValue);
          fontLoaded.push(styleValue);
        }
      }

      // find color
      if (styleValue?.startsWith('rgba')) {
        output.color = styleValue;
      }
      // find background color
      if (styleValue?.startsWith('bg-')) {
        output.backgroundColor = styleValue.substring(3);
      }
    });

    if (fontToBeLoad.length && !fontLoadedVar()) {
      let fontList = fontListEnglish;

      if (
        prop?.language?.toLowerCase() === 'hebrew' ||
        prop?.language?.toLowerCase() === 'arabic'
      ) {
        fontList = fontListHebrew;
      }

      const prelist = fontList.filter((fontList) => fontToBeLoad.includes(fontList.family));

      loadProposalFont(prelist, prop?.language);
    }

    return output;
  };

  const handleMoveBlock = async ({ newEditorState, block, selection }) => {
    try {
      const es = await AtomicBlockUtils.moveAtomicBlock(newEditorState, block, selection);
      focusEditor();
      handleEditorChange({ es });
    } catch (error) {
      message.error('Block cannot be moved next to itself');
    }
  };

  const handleKeyBindingFn = (e) => {
    if (e.shiftKey && e.keyCode === 13) {
      return 'shift+enter';
    } else if (e.ctrlKey || e.metaKey) {
      if (e.keyCode === 75) {
        // ctrl + k = insert link

        e.preventDefault();
        _toggleInlineStyle({
          withoutText: state.inlineToolbar.show ? false : true,
          inlineStyle: 'LINK',
        });
        return 'handled';
      } else if (e.keyCode === 220) {
        // ctrl + \ = clear formatting
        _clearFormatting();
        return 'handled';
      } else if (e.keyCode === 88 && e.shiftKey) {
        // ctrl + shift + x = strike through
        _toggleInlineStyle({ inlineStyle: 'STRIKETHROUGH' });
        return 'handled';
      } else if ((e.keyCode === 90 && e.shiftKey) || e.keyCode === 89) {
        e.preventDefault();
        dispatch({ type: 'setKeyType', keyType: e.shiftKey ? 'redo' : 'undo' });
        return 'handled';
      } else if (e.keyCode === 90) {
        e.preventDefault();
        dispatch({ type: 'setKeyType', keyType: 'undo' });
        return 'handled';
      } else if (e.keyCode === 89) {
        e.preventDefault();
        dispatch({ type: 'setKeyType', keyType: 'redo' });
        return 'handled';
      } else if (e.keyCode === 83) {
        e.preventDefault();
        handleEditorChange({ es: state.editorState });
        return 'handled';
      } else if (e.shiftKey) {
        if (e.keyCode === 188) {
          // ctrl + shift + , = reduce font size
          _toggleFontInlineStyle({ inlineStyle: 12, type: 'decrease-size', fontFamilies: [] });
          return 'handled';
        } else if (e.keyCode === 190) {
          // ctrl + shift + . = increase font size
          _toggleFontInlineStyle({ inlineStyle: 18, type: 'increase-size', fontFamilies: [] });
          return 'handled';
        } else if (e.keyCode === 69 || e.keyCode === 82 || e.keyCode === 76 || e.keyCode === 74) {
          // ctrl + shift + (e,j,l,r) = text alignment

          e.preventDefault();
          const align = {
            69: 'editor-align-center',
            74: 'editor-align-justify',
            76: 'editor-align-left',
            82: 'editor-align-right',
          };
          _toggleCustomBockStyle({ blockType: align[e.keyCode], type: 'align' });
          return 'handled';
        }
      }
    } else if (e.key === 'Tab' || e.keyCode === 9) {
      e.preventDefault();
      /*
      NOTE : dont allow user to enter multiple tab on list which will 
      make the bullets 1. and 1.0.1 or 1.0.0.1 (with 1 or 2 depth skip)
      */
      const selection = state.editorState.getSelection();
      const currentBlockKey = selection.getStartKey();
      const currentContent = state.editorState.getCurrentContent();

      const blockBefore = currentContent.getBlockBefore(currentBlockKey);
      const currentBlock = currentContent.getBlockForKey(currentBlockKey);

      /*
      if current block is list AND
      ( if previous block is not list OR
      current block depth > previous block depth dont allow tab )
      */
      if (
        currentBlock?.getType().endsWith('list-item') &&
        (!blockBefore.getType().endsWith('list-item') ||
          currentBlock?.depth > (blockBefore?.depth ?? 0))
      ) {
        return 'handled';
      }

      utils.onTab({
        e,
        editorRef,
        editorChange,
        editorState: state.editorState,
        props: { ...props, saveDraft },
        dispatch,
        onToggleCustomBockStyle: _toggleCustomBockStyle,
        tabType: e.shiftKey ? 'left-intent' : 'right-intent',
      });
      return 'handled';
    }

    return getDefaultKeyBinding(e);
  };

  // const saveTempDraft = proposalUtils.updateDraftHook(saveProposalDraft, setDisablePreview);

  const handleEditorDrop = (selection, e) => {
    if (dropDisabled) {
      return 'handled';
    }

    const elementType = e.getText();
    let newEditorState = EditorState.acceptSelection(state.editorState, selection);
    let insertAfterBlock = false;

    if (prop.importedProposal && elementType === 'add-text') {
      const endKey = selection.getEndKey();

      const selectedElement = document.querySelector(`[data-offset-key="${endKey}-0-0"]`); // get element where the new element is dropped
      const clientRect = selectedElement?.getBoundingClientRect();

      let newSelection;
      if (clientRect?.top > 0) {
        // add new element to the before the current section
        const { editorState, newBlockKey } = utils.insertEmptyBlock('before', newEditorState);

        newEditorState = editorState;

        newSelection = new SelectionState({
          anchorKey: newBlockKey,
          anchorOffset: 0,
          focusKey: newBlockKey,
          focusOffset: 0,
          isBackward: true,
          hasFocus: true,
        });
      } else {
        // add element after the current section
        newSelection = new SelectionState({
          anchorKey: endKey,
          anchorOffset: 1,
          focusKey: endKey,
          focusOffset: 1,
        });
        insertAfterBlock = true;
      }
      newEditorState = EditorState.forceSelection(newEditorState, newSelection);
    }
    // Can be used in future to move elements between sections
    // const dropSectionName = e.data.getData('sectionName');

    // if (dropSectionName) {
    //   const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);
    //   let tempEditorState = EditorState.createWithContent(
    //     convertFromRaw(prop.draft[dropSectionName].raw, extendedBlockRenderMap),
    //     decorator
    //   );
    //   const newTempEditorContentState = tempEditorState.getCurrentContent();
    //   const newTempEditorBlockMap = newTempEditorContentState.blockMap.delete(elementType);
    //   const newTempEditorContentWithoutEmptyBlock = newTempEditorContentState.set(
    //     'blockMap',
    //     newTempEditorBlockMap
    //   );
    //   const editorStateWithoutBlock = EditorState.push(
    //     tempEditorState,
    //     newTempEditorContentWithoutEmptyBlock,
    //     'remove-block'
    //   );

    //   saveTempDraft(dropSectionName, 'raw')(editorStateWithoutBlock, () => {});
    //   const block = tempEditorState.getCurrentContent().getBlockForKey(elementType);
    //   handleMoveBlock({ newEditorState, block, selection });
    // }

    const block = newEditorState.getCurrentContent().getBlockForKey(elementType);

    if (!block) {
      switch (elementType) {
        case 'add-text':
          handleTextInsert(newEditorState, insertAfterBlock);
          return 'handled';
        case 'add-divider':
          handleInsertDivider(newEditorState);
          return 'handled';
        case 'add-qoute':
          _toggleBlockType('blockquote', prop.importedProposal ? 'blockquote' : '', newEditorState);
          return 'handled';
        case 'add-image':
          handleImageInsert(newEditorState);
          return 'handled';
        case 'add-gallery':
          handleInsertGallery(newEditorState);
          return 'handled';
        case 'add-video':
          handleInsertVideo(newEditorState);
          return 'handled';
        case 'add-table':
          handleInsertTable(newEditorState);
          return 'handled';
        case 'add-testimonial':
          handleInsertTestimonial(newEditorState);
          return 'handled';
        case 'add-form':
          handleInsertForm(newEditorState);
          return 'handled';
        case 'add-price':
          handleInsertPriceMiles('price', newEditorState);
          return 'handled';
        case 'add-goals':
          handleInsertPriceMiles('miles', newEditorState);
          return 'handled';
        case 'add-html':
          handleInsertHTML(newEditorState);
          return 'handled';
        default:
          if (elementType?.startsWith('toolbar-library-media-')) {
            const src = e.data.getData('src');
            handleInsertFromMedia(src, newEditorState);
            return 'handled';
          }

          return false;
      }
    } else {
      handleMoveBlock({ newEditorState, block, selection });
      return 'handled';
    }
  };

  const handlePastedText = (text, html) => {
    const selection = state.editorState.getSelection();
    const currentContent = state.editorState.getCurrentContent();

    if (html) {
      const blocks = convertFromHTML(html);
      const newContent = Modifier.replaceWithFragment(
        currentContent,
        selection,
        ContentState.createFromBlockArray(blocks, blocks.entityMap).getBlockMap()
      );
      handleEditorChange({
        es: EditorState.push(state.editorState, newContent, 'insert-characters'),
        loaderInActive: true,
      });
      return true;
    }
    return false;

    /*
     * @todo: check
     *
    if (!selection.anchorOffset) {
      const newContent = Modifier.insertText(currentContent, selection, text);

      handleEditorChange({
        es: EditorState.push(state.editorState, newContent, 'insert-characters'),
      });
      return true;
    } else {
      return false;
    }
    */
  };

  const handleInsertVariable = (e) => {
    const text = `${e.keyPath[1]}.${e.keyPath[0]}}}`;
    const { editorState } = state;
    const selection = state.selectionState || editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const newContent = Modifier.insertText(contentState, selection, text);
    const withVariableText = EditorState.push(editorState, newContent, 'insert-characters');
    const withProperCursor = EditorState.forceSelection(
      withVariableText,
      newContent.getSelectionAfter()
    );
    handleEditorChange({ es: withProperCursor });

    dispatch({ type: 'setDisplayVariableInput', displayVariableInput: false });
    // dispatch({ type: 'setEditorReadOnly', editorReadOnly: false });
  };

  const handleGlobalClick = (e) => {
    if (state.displayColorPicker && state.editorReadOnly) {
      if (state.colorOpen) {
        // if updated in last 400ms then return no need to close
        // probably switched from COLOR to BACKGROUND
        if (state?.updated + 400 > Date.now()) {
          return;
        }

        if (e.target.id.indexOf('rc-editable-input') === -1) {
          closeColor();

          dispatch({
            type: 'setColorOpen',
            colorOpen: false,
          });
        }
      } else {
        dispatch({
          type: 'setColorOpen',
          colorOpen: true,
        });
      }
    }
    setActiveEditorId(id);
  };

  useEffect(() => {
    if (activeEditorId !== id) {
      closeColor();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeEditorId, id]);

  if (!editorRef.current) {
    return null;
  }

  const isActive = state?.editorState?.getSelection().getHasFocus() || state.lineHeightInput;

  return (
    <div
      className={`rich-editor ${activeEditorId === id ? 'active' : 'inactive'}`}
      id={id}
      onClick={handleGlobalClick}>
      {state.displayLinkInput && renderUrlInput()}
      {state.displayHyperlinkInput && (
        <HyperLinkSection dispatch={dispatch} {...state.displayHyperlinkInput} />
      )}
      {state.displayLinkTextInput && renderTextUrlInput(id)}
      {state.displayColorPicker && (
        <RenderColorPicker
          state={state}
          // REMARK - not sure editor in header/cover section loses focus on clicking color
          isActive={
            id.startsWith('header_') ? !state.editorState?.getSelection()?.isCollapsed() : isActive
          }
          // isActive={isActive}
          toggleColor={toggleColor}
          onToggle={_toggleInlineStyle}
          setActiveEditorId={setActiveEditorId}
        />
      )}

      {state.displayVariableInput && (
        <RenderVariableInput
          state={state}
          prop={prop}
          handleInsertVariable={handleInsertVariable}
          active={isActive && activeEditorId === props.id}
          toolbarPosition={toolbarPosition}
          setToolbarPosition={setToolbarPosition}
        />
      )}

      <RichEditorModals
        prop={prop}
        user={user}
        config={config}
        state={state}
        dispatch={dispatch}
        handleEditorChange={handleEditorChange}
        saveProposal={saveProposal}
        saveUser={saveUser}
        setOpenVariablesList={setOpenVariablesList}
        focusEditor={focusEditor}
        checkSuspiciousUrl={checkSuspiciousUrl}
      />
      {activeEditorId === id &&
      ((selectedBlock && state.simple < 1) ||
        (state.inlineToolbar.show && state.simple < 2 && isActive)) ? (
        <ToolBar
          selectionActive={!state?.editorState?.getSelection().isCollapsed()}
          bodyFont={prop?.draft?.bodyFont}
          language={prop?.language?.toLowerCase() || 'english'}
          selectedBlock={selectedBlock}
          inlineToolbar={state.inlineToolbar}
          editorState={state.editorState}
          dispatch={dispatch}
          onToggle={_toggleInlineStyle}
          onToggleBlockType={_toggleBlockType}
          onToggleFontInlineStyle={_toggleFontInlineStyle}
          onToggleCustomBockStyle={_toggleCustomBockStyle}
          onClearFormatting={_clearFormatting}
          onSplitSection={_onSplitSection}
          zoomValue={zoomValue}
          toggleColor={toggleColor}
        />
      ) : null}
      {contentType === 'image' && !raw ? (
        <Dropzone onDrop={dropImage} accept="image/jpeg, image/png, image/svg+xml, image/bmp">
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                className="image-drop-section"
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => {
                  e.preventDefault();
                  const elementType = e.dataTransfer.getData('text');
                  const src = e.dataTransfer.getData('src');

                  if (elementType?.startsWith('toolbar-library-media-') && src) {
                    handleInsertFromMedia(src, state.editorState);
                  }
                }}>
                {showImageProgress && (
                  <div className="logo-upload-progress">
                    <p className="progress-title">Uploading Image...</p>
                    <p className="upload-image-name" title={imageName}>
                      {imageName}
                    </p>
                    <div className="spacer" />
                    <Slider value={uploadStatus.percent} tooltipVisible />
                  </div>
                )}
                <div
                  {...getRootProps()}
                  className={`dropzone ${isDragActive && 'dropzone--isActive'}`}>
                  <input {...getInputProps()} />
                  {isDragActive ? <p>Drop image here...</p> : <p>Drop your Image Here</p>}
                </div>
              </div>
            );
          }}
        </Dropzone>
      ) : (
        <div
          onClick={focusEditor}
          id={`draft-editor-${id}`}
          // NOTE : on adding onDragOver, effectAllowed = "none" wont work
          // onDragOver={(e) => e.preventDefault()}
          onDragEnter={(e) => {
            e.preventDefault();

            if (dropDisabled) {
              e.dataTransfer.effectAllowed = 'none';
              if (!readOnly) {
                // make readonly
                setReadOnly(true);
              }
            }
          }}>
          <Editor
            ref={editor}
            readOnly={dropDisabled ? readOnly : state.editorReadOnly}
            spellCheck
            customStyleMap={state.styleMap}
            customStyleFn={customStyleFn}
            blockRenderMap={state.blockRenderMap}
            handleDrop={handleEditorDrop}
            blockRendererFn={(block) =>
              blockRenderer({
                setElementsInSection,
                sectionType: contentType,
                numberOfColumns,
                setDraggingElement,
                sectionSpan,
                block,
                state,
                wixEditor,
                templateWixEditor,
                dispatch,
                setDeletedBlockKeys,
                props: { ...props, saveDraft },
                editorRef,
                editorChange,
                upload,
                updateLoaderState,
                isEditingModal,
                onEdit: (x, type) =>
                  onEdit(
                    {
                      v: x.v,
                      save: (nv, saveSuccess) => {
                        x.save(nv);
                        setTimeout(() => saveDraft(x.editorState, saveSuccess), 300);
                      },
                      cancel: x.cancel,
                    },
                    type
                  ),
                handleImageInsert,
                handleInsertVideo,
              })
            }
            blockStyleFn={blockStyler}
            editorState={editorRef.current}
            placeholder={placeholder || configText('write here', true)}
            handleKeyCommand={(command) =>
              utils.handleKeyCommand({
                command,
                editorState: state.editorState,
                editorRef,
                editorChange,
                props: { ...props, saveDraft },
                dispatch,
                createCellBlock,
                updateSelection: utils.updateSelection,
              })
            }
            keyBindingFn={handleKeyBindingFn}
            onChange={(editorState) => {
              const currentContentState = state.editorState.getCurrentContent();
              const newContentState = editorState.getCurrentContent();

              return editorChange({
                editorState,
                props: { ...props, saveDraft },
                editorRef,
                dispatch,
                updateSelection: utils.updateSelection,
                promptForVariable,
                setOpenVariablesList,
                setScrollingTo,
                setActiveEditorId,
                focusChanged: currentContentState === newContentState,
              });
            }}
            handlePastedText={handlePastedText}
          />
        </div>
      )}

      <input
        type="file"
        ref={imageInputRef}
        style={{ display: 'none' }}
        onChange={insertImage}
        accept="image/*"
      />

      {
        /*
          in 2,3,4 column, when a  .ant-col is hovered its z-index is kept higher 
          then other .ant-col to show the image toolbar on top of other images
          when showing uploading overlay, 
          force unset z-index from .ant-col which causes flickering on hover 
          since the overlay is rendered inside .ant-col
        */
        uploadStatus.status === 'uploading' && (
          <style>
            {`.draft-editor .simple-section .simple-section-container .simple-section-content .ant-col  {
            z-index: unset!important;`}
          </style>
        )
      }

      {uploadStatus.status === 'uploading' && (
        <Progress className="rich-upload-loader" type="circle" percent={uploadStatus.percent} />
      )}
      {copyStatus && <Spin className="rich-copy-spinner" size="large" />}
    </div>
  );
};

RichEditor.defaultProps = {
  id: '',
  prop: '',
  raw: '',
  config: '',
  element: '',
  blockRenderMap: null,
  content: '',
  htmlOptions: '',
  sectionName: '',
  simple: '',
  placeholder: '',
  contentType: '',
  setElement: () => {},
  saveProposal: () => {},
  saveUser: () => {},
  zoomValue: 100,
  updateLoaderState: () => {},
  saveProposalDraft: () => {},
  setSavingDraftProposal: () => {},
  numberOfColumns: 1,
  wixEditor: false,
  templateWixEditor: false,
};

RichEditor.propTypes = {
  id: PropTypes.string,
  element: PropTypes.string,
  prop: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.instanceOf(null)]),
  raw: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  config: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  blockRenderMap: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  content: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  htmlOptions: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  sectionName: PropTypes.string,
  simple: PropTypes.number,
  placeholder: PropTypes.string,
  contentType: PropTypes.string,
  setElement: PropTypes.func,
  saveProposal: PropTypes.func,
  zoomValue: PropTypes.number,
  saveUser: PropTypes.func,
  updateLoaderState: PropTypes.func,
  saveProposalDraft: PropTypes.func,
  setSavingDraftProposal: PropTypes.func,
  setDisablePreview: PropTypes.func.isRequired,
  numberOfColumns: PropTypes.number,
  sectionSpan: PropTypes.number,
  titleSpan: PropTypes.number,
  setElementsInSection: PropTypes.func,
  wixEditor: PropTypes.bool,
  isEditingModal: PropTypes.bool,
  templateWixEditor: PropTypes.bool,
};

export default RichEditor;

const RenderVariableInput = ({
  state,
  prop,
  handleInsertVariable,
  active,
  toolbarPosition,
  setToolbarPosition,
}) => {
  let position = { ...state.inlineToolbar.position };

  // if left/right > 0, it means correct position, so save it for future use
  if ((position?.left > 0 || position?.right > 0) && !_.isEqual(position, toolbarPosition)) {
    setToolbarPosition(position);
  }

  if (position?.left < 0 || position?.right < 0) {
    // if left/right < 0, it means wrong position, so use position from cache
    position = { ...toolbarPosition };
  }

  const variables = prop?.draft?.variables || ELEMENTS_VARIABLES;

  return (
    <Menu
      className={`variables-menu ${prop.language || 'english'}`}
      style={{
        position: 'absolute',
        zIndex: '10',
        width: '10em',
        color: '#05034D',
        boxShadow: '0px 5px 20px rgba(4, 3, 49, 0.15)',
        borderRadius: '4px !important',
        display: active ? 'block' : 'none',
        ...position,
      }}
      onClick={(e) => handleInsertVariable(e)}
      expandIcon={<ChevronIcon className={`variables-menu-chevron-icon ${prop.language}`} />}>
      {Object.keys(variables).map((value) => {
        return (
          <SubMenu
            popupClassName={`variables-submenu-items ${prop.language}`}
            className={`variables-menu-items ${prop.language}`}
            key={value}
            title={value}>
            {Object.keys(variables[value]).map((variable) => {
              return (
                <Menu.Item className={`variables-menu-items ${prop.language}`} key={variable}>
                  {variable}
                </Menu.Item>
              );
            })}
          </SubMenu>
        );
      })}
    </Menu>
  );
};

const RenderColorPicker = ({ state, toggleColor, isActive, onToggle, setActiveEditorId }) => {
  const colorRef = useRef(null);

  const handleWixClickCondition = (event) => true;

  useOutsideClick(
    colorRef,
    () => {
      // make no editor active
      setActiveEditorId('');
    },
    handleWixClickCondition
  );

  if (isActive && state.displayColorPicker) {
    const TABS = [
      {
        style: 'COLOR',
        text: 'Text',
      },
      {
        style: 'BACKGROUND',
        text: 'Highlight',
      },
    ];

    const { editorState } = state;
    // const selectionCoords = getSelectionCoords(props.id);
    const currentStyle = editorState.getCurrentInlineStyle();
    let color = '';

    if (state.displayColorPicker === 'COLOR') {
      color =
        currentStyle.filter((value) => value?.toString()?.startsWith('rgba')).last() || '#4a4a4a';
    } else {
      color =
        currentStyle.filter((value) => value?.toString()?.startsWith('bg-')).last() ||
        'rgba(255,255,255,1)';
      if (color?.startsWith('bg-')) {
        color = color.substring(3);
      }
    }

    return (
      <div className="color-picker" ref={colorRef} onClick={(e) => e.stopPropagation()}>
        <div className="color-tabs">
          {TABS.map((tab) => (
            <div
              key={tab.text}
              onClick={(e) => {
                e.stopPropagation();
                onToggle({ inlineStyle: tab.style });
              }}
              className={`color-tab ${state.displayColorPicker === tab.style ? 'active' : ''}`}>
              {tab.text}
            </div>
          ))}
        </div>
        <SketchPicker
          color={color}
          onChange={(toggledColor, e) => {
            e.stopPropagation();
            toggleColor(toggledColor, e, state.displayColorPicker);
          }}
        />
      </div>
    );
  }

  return null;
};
