import { getVisibleSelectionRect } from 'draft-js';
import _ from 'lodash';

const findParent = (el, parentSelector /* optional */) => {
  // If no parentSelector defined will bubble up all the way to *document*
  if (parentSelector === undefined) {
    parentSelector = document;
  }

  let p = el.parentNode;

  let isPresent = 0;
  while (p && p !== parentSelector && isPresent === 0) {
    const o = p;
    if (o.id === parentSelector) {
      isPresent = 1;
    }
    p = o.parentNode;
  }

  return isPresent;
};

const getSelectionRange = () => {
  let t = '';
  if (window?.getSelection) {
    t = window.getSelection();
  } else if (document?.getSelection) {
    t = document.getSelection();
  } else if (document?.selection) {
    t = document.selection.createRange().text;
  }
  if (t.rangeCount === 0) return null;
  return t ? t?.getRangeAt(0) : null;
};

const getSelectedBlockElement = (range, id) => {
  let node = range.startContainer;
  const parentEl = range.commonAncestorContainer;

  if (!findParent(parentEl, id)) return null;

  do {
    const nodeIsDataBlock = node.getAttribute ? node.getAttribute('data-block') : null;
    if (nodeIsDataBlock) return node;
    node = node.parentNode;
  } while (node !== null);
  return null;
};

const getSelectionCoords = (id) => {
  const editorBounds = document.getElementById(id)?.getBoundingClientRect();
  if (!editorBounds) {
    return { offsetLeft: 0, offsetTop: 0, offsetRight: 0, offsetBottom: 0 };
  }
  let therect = null;
  const srange = getSelectionRange();
  if (srange && srange.getClientRects) {
    const rects = srange.getClientRects();
    _.each(rects, function (r) {
      if (r.width !== 0 && !therect) {
        therect = r;
      }
    });
  }
  therect = therect || getVisibleSelectionRect(window);
  if (!therect) {
    return null;
  }
  const rangeBounds = therect;
  // const rangeWidth = rangeBounds.right - rangeBounds.left;
  // const rangeHeight = rangeBounds.bottom - rangeBounds.top;

  const offsetLeft = rangeBounds.left - editorBounds.left - 142 / 2; // + (rangeWidth / 2) ;  /* 72px is width of inline toolbar */
  const offsetRight = editorBounds.right - rangeBounds.right - 142 / 2; // + (rangeWidth / 2) ;  /* 72px is width of inline toolbar */

  // 42px is height of inline toolbar (35px) + 5px center triangle and 2px for spacing
  const offsetTop = rangeBounds.top - editorBounds.top - 30;

  return { offsetLeft, offsetTop, offsetRight };
};

export { getSelectionRange, getSelectedBlockElement, getSelectionCoords };
