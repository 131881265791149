/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M11.0467 3.13908L2.37481 5.38499C0.904539 5.76578 0.0213319 7.26635 0.402116 8.73662L7.98702 38.0232C8.3678 39.4935 9.86838 40.3767 11.3387 39.9959L24.4881 36.5904C25.9584 36.2096 26.8416 34.709 26.4608 33.2387L20.4927 10.195C20.3486 9.63838 20.0336 9.14094 19.592 8.77265L13.4976 3.68941C12.817 3.12177 11.9046 2.91689 11.0467 3.13908Z"
        fill="#5D5AB5"
      />
      <path
        d="M23.4076 1H8.75C7.23122 1 6 2.23122 6 3.75V33.25C6 34.7688 7.23122 36 8.75 36H30.25C31.7688 36 33 34.7688 33 33.25V10.728C33 10.008 32.7176 9.31668 32.2135 8.80259L25.3711 1.82462C24.8539 1.29719 24.1463 1 23.4076 1Z"
        fill="#7876C1"
      />
      <rect x="12" y="20" width="16" height="2" rx="1" fill="#C7BCE4" />
      <rect x="12" y="24" width="16" height="2" rx="1" fill="#C7BCE4" />
      <rect x="12" y="28" width="8" height="2" rx="1" fill="#C7BCE4" />
      <circle cx="15" cy="11" r="3" fill="#FF9492" />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="6"
        y="1"
        width="27"
        height="37">
        <path
          d="M23.4116 1H8.75C7.23122 1 6 2.23121 6 3.75V35.0682C6 36.587 7.23122 37.8182 8.75 37.8182H30.25C31.7688 37.8182 33 36.587 33 35.0682V10.6896C33 9.96729 32.7158 9.27395 32.2088 8.75943L25.3703 1.81979C24.8535 1.2953 24.1479 1 23.4116 1Z"
          fill="#7876C1"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M24.5625 8.18702V-2.42529L38.0625 9.56202H25.9375C25.1781 9.56202 24.5625 8.94641 24.5625 8.18702Z"
          fill="#C7BCE4"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ProposalIcon = (props) => <Icon component={svg} {...props} />;

export default ProposalIcon;
