import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Input, Row } from 'antd';
import './HyperLinkSection.scss';
import { HyperLinkIcon } from 'components/Icons';
import { URL_PATTERN } from '../../RichEditor';

export const HyperLinkSection = ({ link, position = {}, dispatch, onSubmit }) => {
  const [url, setUrl] = useState('');
  const [urlError, setUrlError] = useState('');
  const [isValidUrl, setIsValidUrl] = useState(false);

  useEffect(() => {
    window.addEventListener('click', onCancel);
    if (link) {
      setUrl(link);
    }
    setIsValidUrl(!link || URL_PATTERN.test(link));

    return () => {
      window.removeEventListener('click', onCancel);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link]);

  const onSave = () => {
    if (!!url && !url.match(URL_PATTERN)) {
      setUrlError('error');
      return;
    }
    onSubmit(url);
    onCancel();
  };

  const onUrlChange = (e) => {
    setUrl(e.target.value);
    setIsValidUrl(e.target.value === '' || URL_PATTERN.test(e.target.value));
  };

  const onCancel = () => {
    dispatch({
      type: 'setDisplayHyperlinkInput',
      displayHyperlinkInput: false,
    });
  };

  return (
    <>
      <div
        className="hyperlink-content"
        style={{ ...position }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}>
        <Row wrap={false} className="hyperlink-wrappper" gutter={20}>
          <Col style={{ width: '340px' }}>
            <Input
              className={`hyperlink-input ${urlError}`}
              addonBefore={<HyperLinkIcon />}
              value={url}
              autoFocus
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              onKeyDown={(e) => e.which === 13 && onSave()}
              onChange={onUrlChange}
              placeholder="www.yourlink.com"
            />
          </Col>
          <Button
            className={`hyperlink-btn ${isValidUrl && 'btn-valid'}`}
            type="primary"
            disabled={!isValidUrl}
            onClick={onSave}>
            Save
          </Button>
        </Row>
      </div>
    </>
  );
};

HyperLinkSection.propTypes = {
  link: PropTypes.string,
  onSubmit: PropTypes.func,
  dispatch: PropTypes.func,
  position: PropTypes.object,
};
