import React, { useContext } from 'react';
import { CachePersistor } from 'apollo3-cache-persist';
import { useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import images from 'constants/images';
import Path from 'routes/path';
import AppContext from 'context/index';
import { isLoggedInVar, userProfileVar, userVar, proposalsVar } from 'graphql/cache';

import './AdminHeaderBar.scss';

const { Header } = Layout;

const AdminHeaderBar = ({ location }) => {
  const { selectedAdminTab, setSelectedAdminTab } = useContext(AppContext);

  const client = useApolloClient();
  const persistor = new CachePersistor({
    cache: client.cache,
    storage: window.localStorage,
  });

  const history = useHistory();

  const signOut = async (e) => {
    isLoggedInVar(false);
    userProfileVar('');
    userVar('');
    proposalsVar('');
    await client.clearStore();
    await persistor.purge();
    localStorage.clear();
    history.push(Path.LOGIN);
  };

  return (
    <>
      {location.pathname === Path.ADMIN ? (
        <Header className="header">
          <Menu mode="horizontal">
            <Menu.Item key="home">
              <NavLink to="/">
                <img src={images.LOGO} alt="prospero-logo" height="25" />
              </NavLink>
            </Menu.Item>
            <Menu.Item key="dashboard">
              <NavLink to={Path.DASHBOARD}>User Dashboard</NavLink>
            </Menu.Item>
            <Menu.Item key="users">
              <div
                onClick={() => {
                  setSelectedAdminTab('users');
                }}
                className={selectedAdminTab === 'users' ? 'admin-nav-btn active' : 'admin-nav-btn'}>
                Users
              </div>
            </Menu.Item>
            <Menu.Item key="props">
              <div
                onClick={() => {
                  setSelectedAdminTab('props');
                }}
                className={selectedAdminTab === 'props' ? 'admin-nav-btn active' : 'admin-nav-btn'}>
                Proposals
              </div>
            </Menu.Item>
            <Menu.Item key="trans">
              <div
                onClick={() => {
                  setSelectedAdminTab('trans');
                }}
                className={selectedAdminTab === 'trans' ? 'admin-nav-btn active' : 'admin-nav-btn'}>
                Transactions
              </div>
            </Menu.Item>
            <Menu.Item key="paypal">
              <div
                onClick={() => {
                  setSelectedAdminTab('paypal');
                }}
                className={
                  selectedAdminTab === 'paypal' ? 'admin-nav-btn active' : 'admin-nav-btn'
                }>
                Paypal Transactions
              </div>
            </Menu.Item>
            <Menu.Item key="kpi">
              <div
                onClick={() => {
                  setSelectedAdminTab('kpi');
                }}
                className={selectedAdminTab === 'kpi' ? 'admin-nav-btn active' : 'admin-nav-btn'}>
                Kpis
              </div>
            </Menu.Item>
            <Menu.Item key="config">
              <div
                onClick={() => {
                  setSelectedAdminTab('config');
                }}
                className={
                  selectedAdminTab === 'config' ? 'admin-nav-btn active' : 'admin-nav-btn'
                }>
                Config
              </div>
            </Menu.Item>
            <Menu.Item key="gallery">
              <div
                onClick={() => {
                  setSelectedAdminTab('gallery');
                }}
                className={
                  selectedAdminTab === 'gallery' ? 'admin-nav-btn active' : 'admin-nav-btn'
                }>
                Gallery
              </div>
            </Menu.Item>
            <Menu.Item key="affiliates">
              <div
                onClick={() => {
                  setSelectedAdminTab('affiliates');
                }}
                className={
                  selectedAdminTab === 'affiliates' ? 'admin-nav-btn active' : 'admin-nav-btn'
                }>
                Affiliates
              </div>
            </Menu.Item>
            <Menu.Item key="digital">
              <div
                onClick={() => {
                  setSelectedAdminTab('digital');
                }}
                className={
                  selectedAdminTab === 'digital' ? 'admin-nav-btn active' : 'admin-nav-btn'
                }>
                Digital Ocean
              </div>
            </Menu.Item>
            <Menu.Item key="mails">
              <div
                onClick={() => {
                  setSelectedAdminTab('mails');
                }}
                className={selectedAdminTab === 'mails' ? 'admin-nav-btn active' : 'admin-nav-btn'}>
                Signature Mails
              </div>
            </Menu.Item>
            <Menu.Item key="wixrev">
              <div
                onClick={() => {
                  setSelectedAdminTab('wixrev');
                }}
                className={
                  selectedAdminTab === 'wixrev' ? 'admin-nav-btn active' : 'admin-nav-btn'
                }>
                Wix Rev
              </div>
            </Menu.Item>
            <Menu.Item key="spam-links">
              <div
                onClick={() => {
                  setSelectedAdminTab('spam-links');
                }}
                className={
                  selectedAdminTab === 'spam-links' ? 'admin-nav-btn active' : 'admin-nav-btn'
                }>
                Spam links
              </div>
            </Menu.Item>
          </Menu>

          <div className="admin-nav-end-wrapper">
            <div className="admin-nav-btn" onClick={signOut}>
              Logout
            </div>
          </div>
        </Header>
      ) : null}
    </>
  );
};

AdminHeaderBar.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default AdminHeaderBar;
