import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Popover, Input, Divider, Button } from 'antd';

import { CloseIcon, TrashIcon, DeleteIcon } from 'components/Icons';
import TagColorSelector from './TagColorSelector';

// edit tags
const EditTagCheckboxs = ({
  className,
  tags,
  handleEditTag,
  newColor,
  setColor,
  openDeletePopover,
  setOpenDeletePopover,
  deleteTagFromProposal,
  isSearching,
  searchedIds,
}) => {
  if (!tags?.length) {
    return null;
  }

  return (
    <div className={`${className || ''} proposal-tags-list tags-list`}>
      {tags.map((tagData) => (
        <EditTagCheckbox
          key={tagData._id}
          {...tagData}
          handleEditTag={handleEditTag}
          newColor={newColor}
          setColor={setColor}
          openDeletePopover={openDeletePopover}
          setOpenDeletePopover={setOpenDeletePopover}
          deleteTagFromProposal={deleteTagFromProposal}
          isSearching={isSearching}
          searchedIds={searchedIds}
        />
      ))}
    </div>
  );
};

EditTagCheckboxs.propTypes = {
  className: PropTypes.string,
  tags: PropTypes.array,
  newColor: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  setColor: PropTypes.func,
  handleEditTag: PropTypes.func,
  openDeletePopover: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  setOpenDeletePopover: PropTypes.func,
  deleteTagFromProposal: PropTypes.func,
};

export default EditTagCheckboxs;

const EditTagCheckbox = ({
  _id,
  name,
  color,
  type,
  handleEditTag,
  openDeletePopover,
  setOpenDeletePopover,
  deleteTagFromProposal,
  isSearching,
  searchedIds,
}) => {
  const inputRef = useRef(null);
  const [newName, setName] = useState(name);
  const [newColor, setColor] = useState(color);
  const [newType, setType] = useState(type);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      inputRef.current.blur();
      updateName();
    }
  };

  const updateName = () => {
    if (newName && newName !== name) {
      handleEditTag(_id, newName, undefined, undefined);
    }
  };

  const updateColor = (color, type) => {
    handleEditTag(_id, undefined, color, type);
    setColor(color);
    setType(type);
  };

  if (isSearching && !searchedIds.includes(_id)) {
    return null;
  }

  return (
    <div className="edit-tag-list">
      {/* <DragSmallIcon className="drag-icon" /> */}
      <div className="name">
        <Popover
          trigger="click"
          placement="left"
          className="edit-tag-popover"
          onVisibleChange={() => {
            setColor(false);
          }}
          content={
            <TagColorSelector
              color={newColor}
              setColor={setColor}
              handleEditTag={handleEditTag}
              updateColor={updateColor}
            />
          }>
          <div
            key={_id}
            className="edit-tag-color-circle"
            style={{
              border: `2px solid ${newColor}`,
              background: newType === 'fill' ? newColor || color : '',
            }}
          />
        </Popover>
        <Divider className="edit-tag-list-divider" type="vertical" />
        <Input
          bordered={false}
          className="edit-tag-name"
          placeholder="Tag name"
          value={newName}
          onChange={(e) => {
            setName(e.target.value.substring(0, 25));
          }}
          ref={inputRef}
          onKeyDown={handleKeyDown}
          onBlur={updateName}
        />
      </div>
      <Popover
        trigger="click"
        placement="right"
        visible={openDeletePopover === _id}
        onVisibleChange={() => {
          setOpenDeletePopover(_id);
        }}
        content={
          <div className="delete-tag">
            <CloseIcon className="close-delete-tag" onClick={() => setOpenDeletePopover('')} />
            <TrashIcon className="delete-tag-icon" />
            <p className="delete-tag-text">Delete "{name}" tag ?</p>

            <Button
              type="primary"
              className="large-btn secondary-btn"
              onClick={() => deleteTagFromProposal(_id)}>
              Delete Permanently
            </Button>
          </div>
        }>
        <DeleteIcon className="delete-icon" />
      </Popover>
    </div>
  );
};
