import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { useApolloClient, useReactiveVar } from '@apollo/client';
import { CachePersistor } from 'apollo3-cache-persist';
import { Row, Avatar, Menu, Dropdown, Button, Space } from 'antd';
import { useHistory } from 'react-router-dom';

import SignOutIcon from 'components/Icons/SignOutIcon';
import Path from 'routes/path';
import images from 'constants/images';
import utils from 'utils/utils';
import { getUserShortName } from 'helpers/users';
import {
  isLoggedInVar,
  userProfileVar,
  userVar,
  proposalsVar,
  adminProfileVar,
  adminVar,
} from 'graphql/cache';

import './AccountMenu.scss';

const AccountMenu = ({ upgradeValue, isNotActive, user }) => {
  const { teamId } = user || {};
  const profile = useReactiveVar(userProfileVar);
  const client = useApolloClient();
  const persistor = new CachePersistor({
    cache: client.cache,
    storage: window.localStorage,
  });

  const [menuVisible, setMenuVisibility] = useState(false);
  const history = useHistory();

  const checkImpersonateOrAdmin = () => {
    if (user?.isAdmin || localStorage.getItem('proxy-user-id')) return true;
    return false;
  };

  const handleClick = async (e) => {
    isLoggedInVar(false);
    userProfileVar('');
    userVar('');
    proposalsVar('');
    utils.deleteCookie('instanceId');
    await client.clearStore();
    await persistor.purge();
    localStorage.clear();
    history.push(Path.LOGIN);
  };

  const handleUpgradeClick = async (e) => {
    history.push(Path.PLANS);
  };

  const mismatchMenu = (
    <Menu className="account-menu">
      <Menu.Item onClick={handleClick}>
        <Space size="middle">
          Sign out <SignOutIcon />
        </Space>
      </Menu.Item>
    </Menu>
  );

  const menuProfile = (
    <Menu
      className="account-menu"
      onClick={(e) => {
        setMenuVisibility(false);
        switch (e.key) {
          case 'plans':
            return history.push(Path.PLANS);
          case 'affiliate':
            return history.push(Path.AFFILIATE);
          case 'account':
            return history.push(Path.PROFILE);
          case 'teams':
            return history.push(Path.USERSTEAM);
          case 'activity':
            return history.push(Path.ACTIVITY);
          case 'admin':
            if (!user?.isAdmin) {
              const adminData = adminVar();
              if (adminData !== null) {
                userVar(adminVar());
                userProfileVar(adminProfileVar());
                adminVar(null);
                adminProfileVar(null);
                localStorage.removeItem('proxy-token');
                localStorage.removeItem('proxy-user-id');
              }
            }
            return history.push(Path.ADMIN);

          default:
        }
      }}>
      {checkImpersonateOrAdmin() && <Menu.Item key="admin">Admin</Menu.Item>}

      <Menu.Item key="account">Profile</Menu.Item>
      {!teamId && <Menu.Item key="plans">Plans</Menu.Item>}
      {!teamId && <Menu.Item key="teams">Users</Menu.Item>}
      <Menu.Item key="activity">Activity</Menu.Item>
      {!teamId && <Menu.Item key="affiliate">Affiliate Program</Menu.Item>}
      <Menu.Divider />

      <Menu.Item onClick={handleClick}>
        <Space size="middle">
          Sign out <SignOutIcon />
        </Space>
      </Menu.Item>
      {((upgradeValue > 0 && !isNotActive) || isNotActive) && !teamId && (
        <Menu.Item>
          <div className="upgrade-message">
            <span className="upgrade-info">
              Everyday, Prospero is used by thousands of freelancers and agencies like you to close
              deals with their prospects. We want to help you become successful by creating one of
              the best easy-to-use proposal creation tools in the world.
              <br />
              <br />
              Prosper with Prospero today!
            </span>

            <Button className="upgrade-button" onClick={handleUpgradeClick}>
              {' '}
              Upgrade Now!
            </Button>
          </div>
          <div className="arrow-down" />

          <div className="user">
            <div className="user-image">
              <Avatar src={images.FOUNDER} size={32} />
            </div>

            <div>
              <span className="user-title"> A Message from Tomer</span>
              <span className="user-position">Co-founder</span>
            </div>
          </div>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <li>
      <Dropdown
        overlay={window.location.pathname === Path.INVALID_CONNECTION ? mismatchMenu : menuProfile}
        placement="bottomLeft"
        overlayClassName="account-menu-wrapper"
        onVisibleChange={setMenuVisibility}
        trigger="click">
        <Row align="middle">
          <a aria-hidden="true">
            {profile?.image ? (
              <Avatar src={profile.image} size={40} />
            ) : (
              <Avatar size={40}>{getUserShortName({ name: profile?.name })}</Avatar>
            )}
            <span className="user-details">
              <span className="user-name">
                Hi, {profile?.name?.substring(0, 30)}
                {profile?.name?.length > 30 ? '..' : ''}
              </span>
              {menuVisible ? <CaretUpOutlined /> : <CaretDownOutlined />}
            </span>
          </a>
        </Row>
      </Dropdown>
    </li>
  );
};

AccountMenu.propTypes = {
  user: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  upgradeValue: PropTypes.number.isRequired,
  isNotActive: PropTypes.bool,
};

AccountMenu.defaultProps = {
  user: '',
  isNotActive: false,
};

export default AccountMenu;
