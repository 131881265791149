import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Layout, Form, Input, Row, Col, Button, message } from 'antd';
import { useMutation } from '@apollo/client';

import Testimonials from 'components/Testimonial/Testimonial';
import Path from 'routes/path';
import { USER_FORGOT_PASSWORD } from 'graphql/mutations/userMutations';

import './ForgotPassword.scss';

const { Content } = Layout;

const ForgotPassword = () => {
  const [form] = Form.useForm();

  const [forgotPassword] = useMutation(USER_FORGOT_PASSWORD, {
    async onCompleted(data) {
      message.success('Reset password link sent successfully');
      form.resetFields();
    },
    onError() {
      message.success('Reset password link sent successfully');
      form.resetFields();
    },
  });

  useEffect(() => {
    document.title = 'Prospero - Forgot Password';
  });

  const doSubmit = (values) => {
    const { email } = values;
    if (email) {
      forgotPassword({
        variables: {
          email: email.toLowerCase(),
        },
      });
    }
  };

  return (
    <Layout className="forgot">
      <Content>
        <Row className="forgot-container">
          <Testimonials page="ForgotPassword" />
          <Col className="forgot-left" xs={24} lg={14} xl={14}>
            <div className="forgot-wrapper">
              <Row className="header-forgot" justify="end">
                <div className="forgot-link">
                  Don&apos;t have an account? &nbsp;
                  <NavLink to={Path.SIGNUP}>Sign Up</NavLink>
                </div>
              </Row>
              <div className="content-wrapper">
                <h1 className="heading">Forgot password?</h1>
                <div className="heading-note">
                  Enter the email address you used to create your Prospero account. We'll send you a
                  password reset email.
                </div>
                <div className="color-divider m-0" />
                <Form layout="vertical email-input" form={form} onFinish={doSubmit}>
                  <Form.Item
                    label="Email"
                    name="email"
                    labelCol={false}
                    rules={[
                      { required: true, message: 'Please input your email' },
                      {
                        type: 'email',
                        message: 'Please input a valid email',
                      },
                    ]}>
                    <Input />
                  </Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    block
                    className="forgot-btn">
                    <span className="spacer" />
                    <span className="text-content">SEND RESET EMAIL</span>
                  </Button>
                </Form>
                <div className="redirect-back">
                  Take me back to <NavLink to={Path.LOGIN}>Login</NavLink>
                </div>
              </div>

              <div className="footer">
                <a
                  href="https://www.iubenda.com/privacy-policy/50076459"
                  target="_blank"
                  rel="noreferrer">
                  Privacy
                </a>
                <a href="https://goprospero.com/gdpr" target="_blank" rel="noreferrer">
                  GDPR
                </a>
                <a href="https://goprospero.com/terms" target="_blank" rel="noreferrer">
                  Terms
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

ForgotPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default ForgotPassword;
