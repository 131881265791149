import { gql } from '@apollo/client';

export const ADD_TAG = gql`
  mutation addTag($pid: [String!], $name: String!, $color: String!, $type: String!) {
    addTag(pid: $pid, name: $name, color: $color, type: $type) {
      _id
      name
      type
      color
      pid
    }
  }
`;

export const UPDATE_TAG = gql`
  mutation updateTag($_id: String!, $name: String!, $color: String!, $type: String!) {
    updateTag(_id: $_id, name: $name, color: $color, type: $type) {
      _id
      name
      type
      color
    }
  }
`;

export const DELETE_TAG = gql`
  mutation deleteTag($_id: String!) {
    deleteTag(_id: $_id) {
      _id
    }
  }
`;
