import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Divider, Button, Tooltip } from 'antd';

import {
  SectionAddIcon,
  PlusCircleIcon,
  ChevronDownIcon,
  SearchLightIcon,
  VariablesEditIcon,
  BackArrowIcon,
} from 'components/Icons';

import './ProposalItemTag.scss';
import TagCheckboxes from './TagCheckboxes';
import EditTagCheckboxs from './EditTagCheckboxs';
import { escapeRegExp } from 'utils/xss';

const ManageTags = ({
  setCreateTag,
  proposalTags,
  allUncheckedTags,
  editTags,
  setEditTags,
  handleEditTag,
  selectedTagColor,
  setSelectedTagColor,
  openDeletePopover,
  setOpenDeletePopover,
  deleteTag,
  handleTagChecked,
  visibleTags,
  searchText,
  setSearchText,
  searchedCheckedIds,
  setSearchedCheckedIds,
  searchedUncheckedIds,
  setSearchedUncheckedIds,
}) => {
  const searchInputRef = useRef(null);
  const noResult =
    (searchText && !searchedCheckedIds.length && !searchedUncheckedIds.length) ||
    (!searchText && !proposalTags.length && !allUncheckedTags.length);

  useEffect(() => {
    if (visibleTags && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [visibleTags, searchInputRef]);

  const searchTags = (e) => {
    const text = e.target.value;
    setSearchText(text);

    if (!text?.length) {
      return;
    }

    // const regex = new RegExp(text.split(" ").join("|"), "i");
    const regex = new RegExp(escapeRegExp(text), 'i');

    setSearchedCheckedIds(searchArray(proposalTags, regex));
    setSearchedUncheckedIds(searchArray(allUncheckedTags, regex));
  };

  const searchArray = (arr, regex) => {
    return arr.filter((tag) => regex.test(tag.name)).map((tag) => tag._id);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (noResult) {
        // for to create tag screen
        setCreateTag(searchText || '');
      }
    }
  };

  // not editing, searching and checked tags not found
  // not editing, not searching and checked tags not found
  const editDisabled =
    !editTags && (searchText ? !searchedCheckedIds.length : !editTags && !proposalTags.length);

  const editButton = (
    <Button
      type="primary"
      size="small"
      disabled={editDisabled}
      className={`small-btn grey-btn edit-tags-icon ${editTags ? 'active' : 'inactive'}`}
      onClick={() => {
        if (!noResult) {
          setEditTags(!editTags);
        }
      }}
      icon={<VariablesEditIcon />}
    />
  );

  if (!visibleTags) {
    return null;
  }

  return (
    <div className="tags-card" onClick={(e) => e.stopPropagation()}>
      <div className="tag-header">
        <div className="tag-title">Manage Tags</div>
        <div className="action-buttons">
          <Button
            type="primary"
            size="small"
            className="small-btn grey-btn add-tag-icon"
            onClick={() => setCreateTag(searchText || '')}
            icon={<PlusCircleIcon />}>
            New
          </Button>

          {editTags ? (
            <Button
              type="primary"
              size="small"
              className="small-btn grey-btn "
              onClick={() => setEditTags(false)}
              icon={<BackArrowIcon className="back-tag-icon" />}>
              Back
            </Button>
          ) : editDisabled ? (
            editButton
          ) : (
            <Tooltip title="Edit tags">{editButton}</Tooltip>
          )}
        </div>
      </div>

      <Input
        className="search-tags"
        allowClear
        bordered={false}
        maxLength={25}
        placeholder="Search or create new tags"
        value={searchText}
        onChange={searchTags}
        onKeyDown={handleKeyDown}
        prefix={<SearchLightIcon className="search-icon" />}
        ref={searchInputRef}
      />

      <Divider className="tags-divider" />

      <div className="tags-card-body">
        {noResult && (
          <div className="create-new-tag">
            {searchText && (
              <p className="add-new-tag-text">
                Do you want to create new &nbsp;<b>"{searchText}"</b>&nbsp; tag ?
              </p>
            )}

            <Button
              type="primary"
              size="middle"
              className="medium-btn secondary-btn create-new-tag-button"
              onClick={() => {
                setCreateTag(searchText);
              }}
              icon={<SectionAddIcon className="create-tag-button-icon" />}>
              Create Tag
            </Button>
          </div>
        )}

        {
          // checked tag in edit mode
          editTags ? (
            <EditTagCheckboxs
              className="tagsList"
              tags={proposalTags}
              handleEditTag={handleEditTag}
              openDeletePopover={openDeletePopover}
              setOpenDeletePopover={setOpenDeletePopover}
              isSearching={searchText}
              searchedIds={searchedCheckedIds}
              deleteTagFromProposal={deleteTag}
              newColor={selectedTagColor}
              setColor={setSelectedTagColor}
            />
          ) : (
            <>
              {/* checked tags in non edit more*/}
              <TagCheckboxes
                className="tagsList"
                tags={proposalTags}
                handleTagChecked={handleTagChecked}
                defaultChecked={true}
                isSearching={searchText}
                searchedIds={searchedCheckedIds}
              />

              {
                // selected tags and unselected tags separator
                ((searchText && searchedCheckedIds.length && searchedUncheckedIds.length) ||
                  (!searchText && proposalTags.length > 0 && allUncheckedTags.length > 0)) && (
                  <div className="add-tags-separator">
                    <ChevronDownIcon className="add-tags-chevron" />
                    <span className="add-tags-text">Add more tags</span>
                  </div>
                )
              }

              {/* unchecked tags */}
              <TagCheckboxes
                className="all-tags-list"
                tags={allUncheckedTags}
                handleTagChecked={handleTagChecked}
                isSearching={searchText}
                searchedIds={searchedUncheckedIds}
              />
            </>
          )
        }
      </div>
    </div>
  );
};

ManageTags.propTypes = {
  setCreateTag: PropTypes.func,
  searchText: PropTypes.string,
  searchedTags: PropTypes.object,
  tagsList: PropTypes.object,
};

export default ManageTags;
