import React from 'react';
import PropTypes from 'prop-types';

import { TickIcon } from 'components/Icons';
import { colors } from 'constants/tags_color';

// color selector screen for tag
const TagColorSelector = ({ color, updateColor }) => {
  return (
    <div className="create-tag-color-list edit-popover">
      {colors.map((colorData) => {
        const isSelected = color ? color === colorData.color : color === colorData.color;

        return (
          <div
            key={`${colorData.color}-${colorData.type}`}
            className={`color-circle ${isSelected ? 'selected' : ''}`}
            style={{
              border: `2px solid ${colorData.color}`,
              ...(colorData.type === 'fill' && { background: colorData.color }),
            }}
            onClick={() => {
              updateColor(colorData.color, colorData.type);
            }}>
            {isSelected && (
              <div className="check-mark">
                <TickIcon />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

TagColorSelector.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  updateColor: PropTypes.func,
};

export default TagColorSelector;
