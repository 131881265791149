import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15 0C15.5523 0 16 0.447715 16 1V11C16 11.5523 15.5523 12 15 12H11V14H14V15H2V14H5V12H1C0.447715 12 0 11.5523 0 11V1C0 0.447715 0.447715 0 1 0H15ZM10 12H6V14H10V12ZM15 11V1H1V11H15Z"
      fill="#000624"
    />
  </svg>
);

const WixDesktopIcon = (props) => <Icon component={svg} {...props} />;

export default WixDesktopIcon;
