import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Layout } from 'antd';
import { NavLink, useHistory } from 'react-router-dom';

import Upgrade from '../Upgrade';
import NotificationItem from '../NotificationItem';
import UsersActivity from 'pages/UsersActivity';
import AccountMenu from '../AccountMenu';
import Path from '../../routes/path';
import images from 'constants/images';
import './HeaderBar.scss';

const { Header } = Layout;

const daystrial = process.env.REACT_APP_DAYS_TRAIL || 14;

const PublicNav = () => (
  <ul className="right-menu">
    <li key="login" className="nav-item">
      <span className="nav-link">
        <NavLink to="/login">Login</NavLink>
      </span>
    </li>

    <li key="signup" className="nav-item">
      <span className="nav-link">
        <NavLink to="/signup">Signup</NavLink>
      </span>
    </li>
  </ul>
);

const LoggedInNav = ({ user }) => {
  const history = useHistory();

  const onPlanClick = () => {
    history.push('/plans');
  };

  const trialTime = () => {
    if (!user) {
      return 666;
    }
    return daystrial - moment().diff(moment(user.stopperAnchor || user.createdAt), 'days');
  };

  let everPayed,
    didCancel = undefined;
  everPayed = user?.paymentStatus?.everPayed;
  didCancel = user?.paymentStatus?.didCancel;
  let shouldShow = true;
  if (
    user?.teamId ||
    (everPayed && !didCancel) ||
    (typeof user?.paymentStatus?.activeProfileId === 'undefined' &&
      typeof user?.paymentStatus?.activeProfileStartDate === 'undefined')
  ) {
    shouldShow = false;
  }

  return (
    <ul className="right-menu">
      {window.location.pathname !== Path.INVALID_CONNECTION ? (
        <>
          <Upgrade
            onPlanClick={onPlanClick}
            upgradeValue={trialTime()}
            isNotActive={user?.paymentStatus?.canLock && !user?.teamId}
            everPayed={user?.paymentStatus?.everPayed}
            shouldShow={shouldShow}
          />
          <UsersActivity isHeader={true} />

          {user && <NotificationItem />}

          <div className="seprator" />
        </>
      ) : null}

      <AccountMenu
        user={user}
        isNotActive={user?.paymentStatus?.canLock}
        upgradeValue={trialTime()}
      />
    </ul>
  );
};

const HeaderBar = ({ loggedIn, location, user }) => (
  <>
    {location.pathname !== Path.SIGNUP &&
    location.pathname !== Path.LOGIN &&
    location.pathname !== Path.FORGOT_PASSWORD &&
    location.pathname !== Path.RESET_PASSWORD &&
    location.pathname !== Path.ADMIN &&
    !location.pathname.includes('/w/') ? (
      <Header className="header">
        <div className="logo">
          {window.location.pathname !== Path.INVALID_CONNECTION ? (
            <NavLink to="/">
              <img src={images.LOGO} alt="prospero-logo" height="20" />
            </NavLink>
          ) : (
            <img src={images.LOGO} alt="prospero-logo" height="20" />
          )}
        </div>
        {loggedIn ? <LoggedInNav user={user} /> : <PublicNav />}
      </Header>
    ) : location.pathname.includes('/w/') ? (
      <Header className="header">
        <div className="logo-small">
          <NavLink to="/">
            <img src={images.LOGO_SMALL} alt="prospero-logo" height="20" />
          </NavLink>
        </div>
      </Header>
    ) : null}
  </>
);

LoggedInNav.defaultProps = {
  user: '',
};

LoggedInNav.propTypes = {
  user: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
};

HeaderBar.defaultProps = {
  user: '',
};

HeaderBar.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
};

export default HeaderBar;
