import { gql } from '@apollo/client';

export const CONTENT_LIBRARY_ADD_CHILD = gql`
  mutation addChildMutation($id: String!, $key: String!, $child: ContentChildInput!) {
    addChild(id: $id, key: $key, child: $child)
  }
`;

export const CONTENT_LIBRARY_ADD_ITEM = gql`
  mutation addContentItem(
    $key: String!
    $fromKey: String!
    $item_id: String!
    $id: String!
    $newTemplateName: String
    $type: String!
    $createTemplate: Boolean
  ) {
    addItem(
      key: $key
      item_id: $item_id
      id: $id
      fromKey: $fromKey
      newTemplateName: $newTemplateName
      type: $type
      createTemplate: $createTemplate
    )
  }
`;

export const CONTENT_LIBRARY_DELETE_CHILD = gql`
  mutation deleteChildMutation($id: String!, $key: String!) {
    deleteChild(id: $id, key: $key)
  }
`;

// export const CONTENT_LIBRARY_DELETE_ITEM = gql`
//   mutation addItemMutation($id: String!, $key: String!) {
//     addItem(id: $id, key: $key)
//   }
// `;

export const CONTENT_LIBRARY_RENAME_CHILD = gql`
  mutation addItemMutation($id: String!, $key: String!, $name: String!) {
    renameChild(id: $id, key: $key, name: $name)
  }
`;

export const UPDATE_CONTENT_LIBRARY = gql`
  mutation updateContentItemMutation($id: String!, $type: String!, $item: ContentItemInput!) {
    updateContentItem(id: $id, type: $type, item: $item)
  }
`;

export const UPLOAD_AND_ATTACH_THUMBNAIL = gql`
  mutation ($thumbnailUrl: String!, $id: String!, $type: String!) {
    uploadAndAttachThumbnail(thumbnailUrl: $thumbnailUrl, id: $id, type: $type)
  }
`;

export const ADD_LIBRARY_COVER = gql`
  mutation ($cover: CoverInput!, $folderKey: String!) {
    addLibraryCover(cover: $cover, folderKey: $folderKey)
  }
`;

export const ADD_LIBRARY_MEDIA = gql`
  mutation ($media: MediaInput!, $folderKey: String!) {
    addLibraryMedia(media: $media, folderKey: $folderKey)
  }
`;

export const ADD_LIBRARY_SECTION = gql`
  mutation ($section: LibraryInput!, $folderKey: String!, $language: String!, $isHeader: Boolean) {
    addLibrarySection(
      section: $section
      folderKey: $folderKey
      language: $language
      isHeader: $isHeader
    )
  }
`;

export const UPDATE_LIBRARY_TEMPLATE_MUTATION = gql`
  mutation ($template: TemplatesInput!, $topic: String!) {
    updateTemplate(topic: $topic, template: $template) {
      _id
      pid
      uid
      auid
      name
      state
      draft
      email
      client {
        contact
        name
        desc
        address
        text
        email
      }
      seenTime
      seenCount
      lastSeen
      openLogs {
        date
        clientIP
        duration
        watched
        deviceInfo {
          deviceType
          deviceOS
          deviceBrowser
          deviceName
          deviceAgent
        }
      }
      date
      project {
        name
        type
        othertype
        name
        desc
        goals
      }
      version
      sawyou
      pricing
      deliverables
      milestones
      sawmilestones
      superPowers
      curr
      priceSeperator
      decimalPrecision
      language
      expiryDate
      expiryMessage
      currency
      callButton
      contactButton
      contactnumber
      clientPayableAmount
      clientPayableLabel
      clientPayableCurrency
      clientPayableLink
      scripts
      chatwayScripts
      stripe
      payment {
        status
        amount
        currency
        payment_intend
        created
      }
      published
      disableSig
      signatureEmails
      selectedOption
      prevState
      payedfor
      redirectTo
      redirectAfter
      sigOptions
      contactOptions {
        option
        value
        enabled
      }
      sigtype
      signature {
        name
        myname
        additionalSignature
        signatureBackgroundColor
        signatureTextColor
        additionalSignatureBackgroundColor
        additionalSignatureTextColor
        email
        date
        clientIP
        device
      }
      attachments {
        name
        type
        url
        size
      }
      isEditing {
        name
        uid
        tabID
      }
      editEvent
      wixProposalId
      wixTemplateId
      wixPricing {
        lineItems {
          quantity
          name
          description
          price
          pricesBreakdown {
            totalPriceAfterTax
            taxDetails {
              taxRate
              taxableAmount
              taxable
              totalTax
              taxName
              rateBreakdown {
                rate
                taxableAmount
                tax
              }
            }
            totalDiscount
            price
            priceBeforeDiscounts
            lineItemPrice
            fullPrice
          }
          itemType {
            preset
          }
        }
        appliedDiscounts {
          discountType
          lineItemIds
        }
        additionalFees {
          name
          priceBeforeTax
          taxDetails {
            taxRate
            taxableAmount
            taxable
            totalTax
            taxName
            rateBreakdown {
              rate
              taxableAmount
              tax
            }
          }
          price
        }
        calculatedTotals {
          priceSummary {
            total
            subtotal
            discount
            additionalFees
          }
        }
        paymentSettings {
          type
          singlePaymentSchedule {
            dueDate
            dueDateOffset
            issueDate
            deposit {
              percentage
              amount
            }
            paymentStatus
            amountPaid
          }
          multiplePaymentSchedule {
            items {
              dueDate
              dueDateOffset
              issueDate
              deposit {
                percentage
                amount
              }
              issueDateOffset
              title
              percentage
              amount
              paymentStatus
              amountPaid
            }
          }
        }
        currencyCode
        locale {
          country
          language
        }
      }
    }
  }
`;

export const UPDATE_LIBRARY_SECTION_MUTATION = gql`
  mutation ($section: LibraryInput!) {
    updateLibrary(section: $section) {
      _id
      title
      uid
      auid
      libraryTitle
      subtitle
      raw
      subrawtitle
      rawtitle
      text
      starred
      createdAt
      thumbnail
      language
      isHeader
      by
      color
      contact
      cover
      email
      logo
      name
      rawby
      rawcontact
      rawemail
      rawname
      rawsubtitle
      titleStyle
      clientname
      originalState
      headerConfig
      hidecover
    }
  }
`;

export const CONTENT_LIBRARY_SECTION_DELETE = gql`
  mutation ($_id: String!, $folderKey: String) {
    deleteLibrary(_id: $_id, folderKey: $folderKey)
  }
`;

export const CONTENT_LIBRARY_TEMPLATE_DELETE = gql`
  mutation ($_id: String!, $folderKey: String) {
    deleteTemplate(_id: $_id, folderKey: $folderKey)
  }
`;

export const CONTENT_LIBRARY_COVERS_DELETE = gql`
  mutation ($_id: String!, $folderKey: String) {
    deleteCover(_id: $_id, folderKey: $folderKey)
  }
`;

export const CONTENT_LIBRARY_MEDIA_DELETE = gql`
  mutation ($_id: String!, $folderKey: String) {
    deleteMedia(_id: $_id, folderKey: $folderKey)
  }
`;
