import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <rect x="34" y="21" width="28" height="2" transform="rotate(-180 34 21)" fill="#5D5AB5" />
      <rect x="19" y="35" width="29" height="2" transform="rotate(-90 19 35)" fill="#5D5AB5" />
      <ellipse cx="3" cy="20" rx="3" ry="3" transform="rotate(-90 3 20)" fill="#C7BCE4" />
      <ellipse cx="37" cy="20" rx="3" ry="3" transform="rotate(-90 37 20)" fill="#C7BCE4" />
      <ellipse cx="20" cy="3" rx="3" ry="3" transform="rotate(-90 20 3)" fill="#C7BCE4" />
      <ellipse cx="20" cy="37" rx="3" ry="3" transform="rotate(-90 20 37)" fill="#C7BCE4" />
      <circle cx="20" cy="20" r="11" fill="#7876C1" />
      <circle cx="20" cy="20" r="3" fill="#C7BCE4" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const IntegrationIcon = (props) => <Icon component={svg} {...props} />;

export default IntegrationIcon;
