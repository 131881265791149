import utils from 'utils/utils';

const { S3Image } = utils;

const images = {
  LOGO: S3Image('application/logo/', 'logo.svg'),
  LOGO_SMALL: S3Image('application/logo/', 'logo-small.svg'),
  WIX_ICON: S3Image('application/integrations/', 'wix.svg'),
  STRIPE_ICON: S3Image('application/integrations/', 'stripe.png'),
  ZAPIER_ICON: S3Image('application/integrations/', 'zapier.png'),
  INTEGROMAT_ICON: S3Image('application/integrations/', 'makeintegromat.svg'),
  FRESHBOOKS_ICON: S3Image('application/integrations/', 'freshbooks.png'),
  MONDAY_ICON: S3Image('application/integrations/', 'monday.svg'),
  XERO_ICON: S3Image('application/integrations/', 'xero.svg'),
  MORNING_INVOICE_ICON: S3Image('application/integrations/', 'morninginvoice.svg'),
  SLACK_ICON: S3Image('application/integrations/', 'slackLogo.svg'),
  QUICKBOOKS_ICON: S3Image('application/integrations/', 'quickbooks.png'),
  QUICKBOOKS_LOGO: S3Image('application/integrations/', 'quickbooksLogo.png'),
  QUICKBOOKS_CONNECT_DEFAULT: S3Image('application/integrations/', 'QuickBook_Connect_Default.png'),
  QUICKBOOKS_CONNECT_HOVER: S3Image('application/integrations/', 'QuickBook_Connect_Hover.png'),
  CODES_ICON: S3Image('application/integrations/', 'codes.png'),
  NEW_USER: S3Image('application/integrations/', 'chatway-new-user.png'),
  EXISTING_USER: S3Image('application/integrations/', 'chatway-existing-user.png'),
  PAYPAL_LOGO: S3Image('application/', 'paypal.png'),
  PROPOSAL_FROM_SCRATCH: S3Image('application/', 'proposal-scratch.png'),
  PAYPAL_FROM_TEMPLATES: S3Image('application/', 'proposal-templates.png'),
  FOUNDER: S3Image('application/', 'founder.png'),
  TESTIMONIAL_1: S3Image('application/testimonial/', 'testifier-1.png'),
  TESTIMONIAL_2: S3Image('application/testimonial/', 'testifier-2.png'),
  TESTIMONIAL_3: S3Image('application/testimonial/', 'testifier-3.png'),
  TESTIMONIAL_4: S3Image('application/testimonial/', 'testifier-4.png'),
  TESTIMONIAL_5: S3Image('application/testimonial/', 'testifier-5.png'),
  TESTIMONIAL_6: S3Image('application/testimonial/', 'testifier-6.png'),
  USER_ACTIVITY: S3Image('application/', 'user-activity.png'),
  PLAN: S3Image('application/', 'Hadar+Chen.png'),
  USER_EMPTY: S3Image('application/', 'user-empty.png'),
  SIGNUP_BACKGROUND: S3Image('images/', 'signup-background.jpg'),
  USERWAY_ICON: S3Image('application/integrations/', 'userway.png'),
};

export default images;
