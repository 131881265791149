import React, { useContext, useEffect } from 'react';
import AppContext from 'context/index';
import {
  TransactionDetails,
  UserDetails,
  LanguageConfig,
  Gallery,
  PaypalDetails,
  KpiDetails,
  AffiliateDetails,
  DigitalOcean,
  ProposalDetails,
  SignatureMails,
  WixRev,
  SpamLinks,
} from './components';

import './Admin.scss';

const Admin = () => {
  const { selectedAdminTab } = useContext(AppContext);

  useEffect(() => {
    document.title = 'Prospero - Admin';
  });

  return (
    <div className="admin-content container">
      {selectedAdminTab === 'users' ? <UserDetails /> : null}
      {selectedAdminTab === 'props' ? <ProposalDetails /> : null}
      {selectedAdminTab === 'trans' ? <TransactionDetails /> : null}
      {selectedAdminTab === 'config' ? <LanguageConfig isConfigReady={true} /> : null}
      {selectedAdminTab === 'gallery' ? <Gallery /> : null}
      {selectedAdminTab === 'paypal' ? <PaypalDetails /> : null}
      {selectedAdminTab === 'kpi' ? <KpiDetails /> : null}
      {selectedAdminTab === 'affiliates' ? <AffiliateDetails /> : null}
      {selectedAdminTab === 'digital' ? <DigitalOcean /> : null}
      {selectedAdminTab === 'mails' ? <SignatureMails /> : null}
      {selectedAdminTab === 'wixrev' ? <WixRev /> : null}
      {selectedAdminTab === 'spam-links' ? <SpamLinks /> : null}
    </div>
  );
};

export default Admin;
