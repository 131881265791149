import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Layout, Form, Input, Row, Col, Button, message } from 'antd';
import { useMutation } from '@apollo/client';

import Testimonials from 'components/Testimonial/Testimonial';
import Path from 'routes/path';
import { USER_RESET_PASSWORD } from 'graphql/mutations/userMutations';

import utils from 'utils/utils';
import 'pages/ForgotPassword/ForgotPassword.scss';

const { Content } = Layout;

const ResetPassword = ({ match: { params }, history }) => {
  const pageName = history.location.pathname.indexOf('update') ? 'Set New' : 'Reset';
  const [newPassword, setNewPassword] = useState({
    value: '',
  });
  const [confirmPassword, setConfirmPassword] = useState({
    value: '',
  });
  const [form] = Form.useForm();

  const [saveNewPassword] = useMutation(USER_RESET_PASSWORD, {
    async onCompleted(data) {
      if (data.resetPassword) {
        history.push('/login');
      } else {
        message.error(`${pageName} Password Link Expired`);
      }
    },
    onError() {
      message.error(`${pageName} Password Link Expired`);
    },
  });

  useEffect(() => {
    document.title = `Prospero - ${pageName} Password`;
  });

  const onFinish = async (values) => {
    if (
      values.newPassword.validateStatus === 'error' ||
      values.confirmPassword.validateStatus === 'error'
    ) {
      return;
    }

    if (values.newPassword !== values.confirmPassword) {
      setConfirmPassword({
        validateStatus: 'error',
        errorMsg: `Confirm password doesn't match new password`,
      });
    } else {
      saveNewPassword({
        variables: {
          token: params.token,
          newPassword: values.newPassword,
        },
      });
      form.resetFields();
    }
  };

  const validatePassword = (value) => {
    const errorResponse = utils.simplePasswordPolicy(value);
    if (errorResponse === null) {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    } else {
      return {
        validateStatus: 'error',
        errorMsg: errorResponse,
      };
    }
  };

  const onPasswordChange = (value) => {
    setNewPassword({
      ...validatePassword(value),
      value,
    });
  };

  const onConfirmPasswordChange = (value) => {
    setConfirmPassword({
      ...validatePassword(value),
      value,
    });
  };

  return (
    <Layout className="forgot">
      <Content>
        <Row className="forgot-container">
          <Testimonials page="ResetPassword" />
          <Col className="forgot-left" xs={24} lg={14} xl={14}>
            <div className="forgot-wrapper">
              <Row className="header-forgot" justify="space-between">
                <div>&nbsp;</div>
                <div className="forgot-link">
                  Go back to <NavLink to={Path.LOGIN}>LOGIN</NavLink>
                </div>
              </Row>
              <div className="content-wrapper">
                <h1 className="heading">{pageName} password</h1>
                <div className="color-divider m-0" />
                <Form layout="vertical email-input" form={form} onFinish={onFinish}>
                  <Form.Item
                    label="New Password"
                    name="newPassword"
                    rules={[{ required: true, message: 'Password Required!' }]}
                    validateStatus={newPassword.validateStatus}
                    help={newPassword.errorMsg}>
                    <Input.Password
                      value={newPassword.value}
                      onChange={(e) => onPasswordChange(e.target.value)}
                      min={8}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Confirm Password"
                    name="confirmPassword"
                    rules={[{ required: true, message: 'Confirm Password Required!' }]}
                    validateStatus={confirmPassword.validateStatus}
                    help={confirmPassword.errorMsg}>
                    <Input.Password
                      value={confirmPassword.value}
                      onChange={(e) => onConfirmPasswordChange(e.target.value)}
                      min={8}
                    />
                  </Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    block
                    className="forgot-btn">
                    <span className="spacer" />
                    <span className="text-content">{pageName.toUpperCase()} PASSWORD</span>
                  </Button>
                </Form>
              </div>
              <div className="footer">
                <a
                  href="https://www.iubenda.com/privacy-policy/50076459"
                  target="_blank"
                  rel="noreferrer">
                  Privacy
                </a>
                <a href="https://goprospero.com/gdpr" target="_blank" rel="noreferrer">
                  GDPR
                </a>
                <a href="https://goprospero.com/terms" target="_blank" rel="noreferrer">
                  Terms
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

ResetPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.instanceOf(Object).isRequired,
  }).isRequired,
};

export default ResetPassword;
