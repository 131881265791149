import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 6C2 5.44772 2.44772 5 3 5H13C13.5523 5 14 5.44772 14 6V7H2V6Z" fill="#05034D" />
    <path d="M2 7H4V8C4 8.55228 3.55228 9 3 9C2.44772 9 2 8.55228 2 8V7Z" fill="#05034D" />
    <path d="M12 7H14V8C14 8.55228 13.5523 9 13 9C12.4477 9 12 8.55228 12 8V7Z" fill="#05034D" />
    <rect x="7" y="5" width="2" height="13" fill="#05034D" />
    <rect x="5" y="17" width="6" height="2" rx="1" fill="#05034D" />
    <rect
      x="1.35889"
      y="17.2617"
      width="18.2482"
      height="3"
      rx="1.5"
      transform="rotate(-60.8661 1.35889 17.2617)"
      fill="#05034D"
      stroke="#EBEDF7"
    />
  </svg>
);

const ClearFormattingIcon = (props) => <Icon component={svg} {...props} />;

export default ClearFormattingIcon;
