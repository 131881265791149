import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Layout, Tooltip } from 'antd';
import PropTypes from 'prop-types';

import { SIDE_MENU_ITEMS, SIDE_BAR_ICONS, LOCKED_PAGES } from 'constants/index';
import useWindowDimensions from 'hooks/useWindowDimensions';
import Path from 'routes/path';

import './SideBar.scss';

const { Sider } = Layout;

const Item = ({ itemId, title, width, active, path, icon: Icon, onItemClick }) =>
  width < 1000 ? (
    <Tooltip placement="right" title={title} color="white">
      <div
        className={`sidebar-item ${active ? 'active' : ''}`}
        onClick={() => onItemClick({ selectedItemId: itemId, path })}>
        <Icon />
      </div>
    </Tooltip>
  ) : (
    <div
      className={`sidebar-item ${active ? 'active' : ''}`}
      onClick={() => onItemClick({ selectedItemId: itemId, path })}>
      <Icon />
      <div className="title">{title}</div>
    </div>
  );

const Sidebar = ({ isLocked }) => {
  const { width } = useWindowDimensions();
  const history = useHistory();
  const location = useLocation();
  const [items, setItems] = useState(SIDE_MENU_ITEMS);

  const updateSideMenu = useCallback(() => {
    const updatedItems = items.map((item) =>
      item.path === location.pathname ? { ...item, active: true } : { ...item, active: false }
    );
    setItems(updatedItems);
  }, [location]); //eslint-disable-line

  useEffect(() => {
    updateSideMenu();
  }, [location, updateSideMenu]);

  const onItemClick = ({ selectedItemId, path }) => {
    if (LOCKED_PAGES.indexOf(path) !== -1 && isLocked) {
      return;
    }

    const updatedItems = items.map((item) =>
      item.id === selectedItemId ? { ...item, active: true } : { ...item, active: false }
    );
    setItems(updatedItems);

    if (path === '/help') {
      if (window?.supportHeroWidget?.isOpened()) {
        window?.supportHeroWidget?.hide();
      } else {
        window?.supportHeroWidget?.show();
      }
    } else {
      if (window?.supportHeroWidget?.isOpened()) {
        window.supportHeroWidget.hide();
      }
      history.push(path);
    }
  };

  return (
    <>
      {window.location.pathname !== Path.INVALID_CONNECTION ? (
        location.pathname !== Path.ADMIN ? (
          <Sider className="sidebar" width={width < 1000 ? 70 : 122}>
            <div className="sidebar-wrapper">
              {items.map((item, index) => (
                <React.Fragment key={item.title}>
                  <Item
                    itemId={item.id}
                    title={item.title}
                    width={width}
                    active={item.active}
                    icon={SIDE_BAR_ICONS[item.icon]}
                    onItemClick={onItemClick}
                    path={item.path}
                  />
                  {index === 2 && <div className="color-divider" />}
                </React.Fragment>
              ))}
            </div>
          </Sider>
        ) : null
      ) : null}
    </>
  );
};

Item.defaultProps = {
  active: false,
};

Item.propTypes = {
  itemId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  icon: PropTypes.elementType.isRequired,
  active: PropTypes.bool,
  onItemClick: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
};

export default Sidebar;
