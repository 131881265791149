import { convertFromRaw } from 'draft-js';
import { v4 as uuidv4 } from 'uuid';
import { stateToHTML } from 'draft-js-export-html';
import marked from 'marked';
import WebFont from 'webfontloader';
import _ from 'underscore';
import * as lodash from 'lodash';

import { MONTH_NAMES, DEFAULT_CLIENT_DETAILS } from '../constants';
import utils from 'utils/utils';

marked.setOptions({
  renderer: new marked.Renderer(),
  gfm: true,
  tables: true,
  breaks: true,
  pedantic: false,
  sanitize: false,
  smartLists: true,
  smartypants: false,
});

const getMatches = (string, regex) => {
  const matches = [];
  let match;
  /* eslint-disable */
  while ((match = regex.exec(string))) {
    matches.push(match[0]);
  }
  /* eslint-enable */
  return matches;
};

export const findDraftObject = ({ what, proposal }) => {
  const draft = JSON.parse(JSON.stringify(proposal));
  for (const [path, value] of Object.entries(what)) {
    // eslint-disable-next-line array-callback-return
    path.split('.').reduce((obj, p, i, arr) => {
      if (arr.length - 1 === i) {
        obj[p] = value;
      } else {
        return (obj[p] = obj[p] || {});
      }
    }, draft);
  }
  return draft;
};

export const refineDraftObject = ({ what, proposal }) => {
  const draft = JSON.parse(JSON.stringify(proposal.draft));
  let returnableData = {};
  const keys = Object.keys(what);
  let proceed = false;
  for (const key of keys) {
    if (key.indexOf('draft') > -1) {
      proceed = true;
      break;
    }
  }
  if (proceed) {
    for (const [path, value] of Object.entries(what)) {
      // eslint-disable-next-line array-callback-return
      try {
        const keys = path.split('.');
        const [, head, subhead, ...arr] = keys;
        if (!head) {
          continue;
        } else if (!subhead) {
          returnableData[head] = value;
        } else {
          const obj = {};
          for (let i = 0; i < arr.length; i++) {
            if (i === arr.length - 1) {
              obj[arr[i]] = value;
            } else {
              obj[arr[i]] = {};
            }
          }
          let data = undefined;
          if (Object.keys(obj).length > 0) {
            data = {
              ...draft[head][subhead],
              ...obj,
            };
          } else {
            if (
              draft?.sectionorder?.indexOf(head) === -1 &&
              ![
                'header',
                'titleFont',
                'variables',
                'tocExclude',
                'subTitleFont',
                'bodyFont',
                'signature',
              ].includes(head)
            ) {
              draft[head] = {
                raw: {},
              };
            }
            data =
              typeof value === 'string' || typeof value === 'boolean' || typeof value === 'number'
                ? value
                : {
                    ...draft[head][subhead],
                    ...value,
                  }; // don't directly put in returnable data below, its causing forever save
          }
          returnableData[head] = {
            ...draft[head],
            [subhead]: data,
          };
        }
      } catch (err) {
        returnableData = what?.draft || draft;
        break;
      }
    }
  }
  return returnableData;
};

// Sort all the proposals
export const sortProposals = ({ allProposals }) => {
  return allProposals.slice().sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });
  // return allProposals;
};
// Group all the proposals by month and year
export const proposalsByMonthAndYear = ({ proposalDate }) => {
  const name = MONTH_NAMES[proposalDate.getMonth()];
  return `${name} ${proposalDate.getFullYear()}`;
};

// Getting the title of a prop
// Sometimes needs to use draft js functions to get the data if the Proposal was edited
// So the data is in the raw title field and is coded as draft js json
export const getProposalTitle = ({ proposal, user }) => {
  const header = proposal.draft && proposal.draft.header;
  let titlehtml = proposal.project && proposal.project.name;
  let titletext = titlehtml || '';

  if (header && header.rawtitle) {
    const titlecs = convertFromRaw(header.rawtitle);
    titlehtml = titlecs.getPlainText();
    titletext = utils.changeVariable(
      proposal?.draft?.variables,
      titlehtml,
      user?.profile?.dateFormat
    );
  } else if (header && header.title) {
    titletext = utils.changeVariable(
      proposal?.draft?.variables,
      header.title,
      user?.profile?.dateFormat
    );
  }

  titletext = titletext
    .replace(/<\s*p\s*>/gim, '')
    .replace(/<\/p>/gim, '')
    .replace(/<br>/gim, ' ')
    .replace(/&nbsp;/g, ' ')
    .replace(/\n/g, ' ');
  return titletext;
};

// getting the title from a section, sometimes in clear text
// but if edited then needs to use draftjs functions to get the data (convertfromraw and statetohtml)
export const getSectionTitle = ({ proposal, sectionName, sectionIndex }) => {
  const section = proposal?.draft && proposal?.draft[sectionName];
  let titletext = section?.title;

  if (section?.rawtitle) {
    const titlecs = convertFromRaw(section.rawtitle);
    titletext = stateToHTML(titlecs);
  } else if (section?.image) {
    if (section?.imageName) titletext = section?.imageName;
    else titletext = `Full-Width Image ${sectionIndex || 0}`;
  }

  if (!titletext) {
    return null;
  }

  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = titletext;
  return tempDiv.textContent;
};

// Getting the client name from a proposal
// Sometimes needs to use draft js functions to get the data if the Proposal was edited
// So the data is in the raw title field and is coded as draft js json

export const getClientName = ({ proposal }) => {
  const signature = proposal.draft && proposal.draft.signature;
  let clientnamehtml =
    (proposal.client && proposal.client.contact) || DEFAULT_CLIENT_DETAILS.clientName;
  let clientnametext = clientnamehtml;
  if (signature && signature.rawname) {
    const clientnamecs = convertFromRaw(signature.rawname);
    clientnamehtml = stateToHTML(clientnamecs);
    clientnametext = clientnamehtml;
  }
  return clientnametext;
};

// Get proposal seen time details
export const getSeenTimeDetails = ({ proposal }) => {
  let seenTime =
    proposal?.seenTime > 10000
      ? proposal.seenTime.toString().substring(0, 4)
      : proposal?.seenTime || 0;

  proposal?.openLogs?.forEach((openLog) => (seenTime = (openLog?.watched || 0) + seenTime));

  let minutes = seenTime ? `${Math.floor((seenTime * 1000) / 60000)}` : null;
  let seconds = seenTime ? `${(((seenTime * 1000) % 60000) / 1000).toFixed(0)}` : null;

  if (minutes?.length < 2) minutes = `0${minutes}`;
  if (seconds?.length < 2 && seconds > 10) seconds = `${seconds}0`;
  else if (seconds && seconds < 10) seconds = `0${seconds}`;

  if (minutes && seconds) {
    return {
      minutes: minutes || '00',
      seconds: seconds || '00',
    };
  } else {
    return null;
  }
};

// export const getClientNames = ({ user }) => {
//   let freshbooksClients = [];
//   if (user && user.freshbooks && user.freshbooks.clients && user.freshbooks.clients.clients) {
//     freshbooksClients = user.freshbooks.clients.clients.map((x) => ({
//       value: x.fname + (x.lname ? ` ${x.lname}` : ''),
//       extra: { data: x, type: 'freshbooks' },
//     }));
//   }
//   const freshbooksMap = keyBy(freshbooksClients, 'value');

//   let tempClientNames = _.compact(
//     _.map(
//       Props.find({}).fetch(),
//       (p) =>
//         p.client &&
//         p.client.contact &&
//         !freshbooksMap[p.client.contact] && { value: p.client.contact }
//     )
//   );

//   tempClientNames = _.unique([...tempClientNames, ...freshbooksClients], false, (x) => x.value);
//   return tempClientNames;
// };

// Check proposl is draft or not

export const checkProposalIsDraft = ({ proposal }) => {
  if (!proposal.state) {
    return true;
  }

  if (['client', 'project', 'you', 'milestones', 'deliverables'].indexOf(proposal.state) !== -1) {
    return true;
  }

  if (proposal.state === 'edit') {
    return true;
  }

  if (
    proposal.state === 'approved' ||
    proposal.state === 'downloaded' ||
    proposal.state === 'declined' ||
    proposal.state === 'signed' ||
    proposal.state === 'seen' ||
    proposal.state === 'sent' ||
    proposal.state === 'published'
  ) {
    return false;
  }

  return true;
};

// Determines if a wizard section is complete, important for the continue button to show
export const getCompletion = function ({ proposal }) {
  const clientcomplete = proposal.client && proposal.client.contact;
  const projectcomplete =
    clientcomplete &&
    proposal.project &&
    (proposal.project.type || proposal.project.othertype) &&
    proposal.project.name &&
    proposal.project.desc;
  const youcomplete = proposal.sawyou;
  const pricingcomplete =
    projectcomplete && youcomplete && proposal.pricing && proposal.pricing.strategy;
  const chosen = pricingcomplete && proposal.deliverables && proposal.deliverables.chosen;
  const chosenkeys = chosen && Object.keys(chosen);

  const delivcomplete =
    chosenkeys &&
    chosenkeys.length &&
    proposal?.deliverables?.chosen[chosenkeys[0]]?.list &&
    proposal?.deliverables?.chosen[chosenkeys[0]]?.list.length;
  const milestonescomplete = proposal.sawmilestones;
  const partycomplete = proposal.sawparty;

  const importproposalcomplete = proposal?.importedProposal;
  const draftCompleted =
    proposal?.draft && proposal.draft?.signature && proposal.draft?.sectionorder;

  return {
    clientcomplete,
    projectcomplete,
    youcomplete,
    pricingcomplete,
    delivcomplete,
    milestonescomplete,
    partycomplete,
    importproposalcomplete,
    draftCompleted,
  };
};

// Find proposal status
export const findAllProposalStatus = (proposalsStats) => {
  if (proposalsStats) {
    const { approvedCount, notDraftCount } = proposalsStats;
    const acceptanceRate =
      approvedCount && notDraftCount
        ? parseInt(((approvedCount / notDraftCount) * 100).toFixed(0))
        : 0;
    return { approvedCount, acceptanceRate };
  } else {
    return { approvedCount: 0, acceptanceRate: 0 };
  }
};

// Filter proposals based on value
export const findProposalsBySearch = ({ allProposals, searchValue }) => {
  const regex = new RegExp(lodash.escapeRegExp(searchValue), 'i');
  return allProposals.filter(
    (proposal) =>
      regex.test(proposal.name) ||
      regex.test(proposal.titletext) ||
      regex.test(proposal.clientnametext) ||
      (proposal.client && regex.test(proposal.client.contact)) ||
      (proposal.project && regex.test(proposal.project.name)) ||
      (proposal.project && regex.test(proposal.project.desc)) ||
      (proposal.project && regex.test(proposal.project.goals))
  );
};

// Filter proposals based on selected proposalstatus filter
export const findProposalsByStatusFilter = ({ allProposals, filterItems }) => {
  // If all is selected show all the proposals
  if (filterItems[0].checked) return allProposals;
  const statusFilter = [];
  filterItems.forEach((item) => {
    if (item.checked) {
      statusFilter.push(item.key);
    }
  });
  return allProposals.filter((proposal) => statusFilter.includes(proposal.state));
};

const fmt = function (d) {
  if (!d) return;
  let dd = d.getDate();
  let mm = d.getMonth() + 1;

  const yyyy = d.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${mm}/${dd}/${yyyy}`;
};

const parseFloatCurr = ({ prop, x }) => {
  if (!x) {
    return 0;
  }

  /* eslint-disable */
  if (typeof x === 'number') {
    x = toStringCurr({ prop, x });
  }
  /* eslint-enable */

  // weird case - decimal is , thowsands is . (and removed)
  // if (dotCurrs.indexOf(curr.toLowerCase()) !== -1) {
  let after = '';
  switch (prop.priceSeperator) {
    case '.':
      after = x.replace(/\./gim, 'DOT').replace(/,/gim, '.').replace(/DOT/gim, '');
      return parseFloat(after);
    case 'space':
      after = x.replace(' ', '');
      return parseFloat(after);
    default:
      // "normal" case: remove thowsands (,) if there are
      after = x.toString().replace(/,/gim, '');
      return parseFloat(after);
  }
};

// Find proposal approved date
const getApprovedDate = ({ proposal }) => {
  const propsigneddate = proposal.signature && proposal.signature.date;
  const approvedDate = propsigneddate
    ? fmt(new Date(propsigneddate))
    : proposal.approvedmanually
    ? fmt(new Date(proposal.approvedmanually))
    : null;
  return approvedDate;
};

const toStringCurr = ({ prop, x }) => {
  if (!x) {
    return 0;
  }

  if (typeof x === 'string') {
    if (prop.priceSeperator === '.') {
      x = x.replace(/\./gim, ',');
    }
    x = parseFloatCurr({ prop, x });
  }

  const strx = x.toString();
  // weird case for currency, decimal is ,
  // if (dotCurrs.indexOf(curr.toLowerCase()) !== -1) {

  if (prop.priceSeperator === '.') {
    const after = strx.replace(/\./gim, ','); // replace decimal point to , for the currency
    return after;
  }
  return strx;
};

const commafi = ({ prop, x, curr }) => {
  // var tsep = curr && (dotCurrs.indexOf(curr.toLowerCase()) !== -1) ? '.' : ',';
  // var dsep = curr && (dotCurrs.indexOf(curr.toLowerCase()) !== -1) ? ',' : '.';

  const tsep =
    curr && prop.priceSeperator === '.'
      ? '.'
      : prop.priceSeperator === 'space'
      ? ' '
      : prop.priceSeperator === 'none'
      ? ''
      : ',';
  const dsep = curr && prop.priceSeperator === '.' ? ',' : '.';

  if (x && x.toString().indexOf('.') !== -1) {
    const parts = x.toString().split('.');
    return commafi({ prop, x: parts[0], curr }) + dsep + parts[1];
  }

  return x && toStringCurr({ prop, x }).replace(/\B(?=(\d{3})+(?!\d))/g, tsep);
};

const concatcurr = ({
  prop,
  x,
  y,
  curr = '$',
  limitDec = undefined,
  roundedTotal,
  parsingDecimal,
  minimum,
}) => {
  curr = curr || '$';
  /*
   * Remove: Not needed
   *
  x = Number(x);
  */
  if (y > 1) {
    x = x * y;
  }
  x = parseFloatCurr({ prop, x });
  let sign = x < 0 ? '-' : '';

  // decimal seperator (not thowsands) so the other way around
  // var sep = curr && (dotCurrs.indexOf(curr.toLowerCase()) !== -1) ? ',' : '.';
  const sep = curr && prop.priceSeperator === '.' ? ',' : '.';
  if (!parsingDecimal) {
    if (roundedTotal) {
      if (x && toStringCurr({ prop, x }).indexOf(sep) !== -1) {
        if (typeof limitDec === 'number') {
          x = Math.abs(x).toFixed(limitDec);
        }
      } else {
        x = Math.abs(x).toFixed(0);
      }
    } else {
      x = x ? Math.abs(x).toFixed(2) : x;
      if (x) x = x.split('.').join(sep);
    }
  } else if (limitDec) {
    x = x ? Math.abs(x).toFixed(limitDec) : x;
  }

  const toLeft =
    curr && ((curr.length === 1 && curr !== '%') || curr === 'R$' || curr === 'Rp' || curr === 'RP')
      ? curr
      : '';
  const toRight = !toLeft ? ` ${curr}` : '';
  let priceValue = commafi({ prop, x, curr });

  if (minimum === 0 && sign === '-') {
    // remove the - sign, reset the number to 0
    sign = '';
    priceValue = '0';
  }
  return sign + (toLeft + priceValue + toRight);
};

const cap = (x) => x && x.replace(/\b\w/g, (l) => l.toUpperCase());
const upr = (x) => x && x.replace(/\w/gim, (l) => l.toUpperCase());

const configText = ({ x, notmarked = false, config, prop, vars = {}, disableTrim = false }) => {
  const clientName = prop.client && prop.client.contact ? prop.client.contact : 'the client';
  const companyName = prop.client && prop.client.name ? prop.client.name : clientName;
  const { name } = prop;
  const email = prop.email || 'MYEMAIL@GMAIL.COM';
  const clientEmail = prop.client && prop.client.email ? prop.client.email : '';

  // hack - whyme and pricing sections changed names and new languages have different keys now
  if (x.indexOf('whyme') !== -1 || x.indexOf('pricing') !== -1 || x.indexOf('milestones') !== -1) {
    if (!config[x]) {
      const newkey = x
        .replace('pricing', 'pricing2')
        .replace('whyme', 'whyme2')
        .replace('milestones', 'milestones2');
      if (config[newkey]) {
        x = newkey;
      }
    }
  }

  x = disableTrim ? x : x.trim('\n');

  let t = config && config[x] ? (notmarked ? config[x] : marked(config[x])) : x;

  t =
    t &&
    t
      .replace(/\[email\]/gim, email)
      .replace(/\[company_name\]/gim, vars.companyName || companyName)
      .replace(/\[designer_name\]/gim, vars.name || name)
      .replace(/\[user_name\]/gim, vars.user_name)
      .replace(/\[client_name\]/gim, vars.client_name || clientName)
      .replace(/\[client_email\]/gim, vars.client_email || clientEmail)
      .replace(/\[user_first_name\]/gim, vars.user_first_name);

  return t;
};

// pay attention to sep
const onlyNumSeperator = ({ prop, x, curr = '$', changeTotal }) => {
  curr = curr || '$';
  curr = curr.toLowerCase();

  // decimal seperator (not thowsands) so the other way around
  // const sep = curr && (dotCurrs.indexOf(curr.toLowerCase()) !== -1) ? ',' : '.';

  const sep = curr && prop.priceSeperator === '.' ? ',' : '.';
  const sepforregex = sep === '.' ? '\\.' : sep;

  if (x) {
    const m = getMatches(x, new RegExp(`[\\d${sepforregex}]`, 'gim'));
    const v = m ? parseFloatCurr({ prop, x: m.join('') }) : 0;

    if (x === sep) {
      return `0${sep}`;
    }
    if (x === '-') {
      return '-';
    }

    let ret = v;

    // there was a decimal point but we removed it (after parsefloat, for example '1.') - fix
    // ( 100% fix but for value pricing only)
    if (changeTotal && x.indexOf(sep) !== -1) {
      const bfr = getMatches(
        x.slice(0, x.indexOf(sep)),
        new RegExp(`[\\d${sepforregex}]`, 'gim')
      ).join('');
      const aft = x.slice(x.indexOf(sep)).replace(new RegExp(`${sepforregex}`, 'gim'), '');
      ret = bfr + sep + aft;
    }
    // there was a decimal point but we removed it (after parsefloat, for example '1.') - fix
    // (not 100% because it does not fix xx.00)
    else if (x.indexOf(sep) !== -1 && `${v}`.indexOf('.') === -1) {
      const bfr = x.slice(0, x.indexOf(sep));
      const aft = x.slice(x.indexOf(sep)).replace(new RegExp(`${sepforregex}`, 'gim'), '');
      ret = bfr + sep + aft;
    }
    if (x.indexOf('-') !== -1) {
      ret = `-${ret}`;
    }
    return ret;
  }
  return 0;
};

/* eslint-disable */
// Internal helper to generate a function to obtain property `key` from `obj`.
const shallowProperty = (key) => {
  return function (obj) {
    return obj == null ? void 0 : obj[key];
  };
};

// Internal helper to obtain the `length` property of an object.
const getLength = shallowProperty('length');

// Converts lists into objects. Pass either a single array of `[key, value]`
// pairs, or two parallel arrays of the same length -- one of keys, and one of
// the corresponding values. Passing by pairs is the reverse of `_.pairs`.
const objectPairs = (list, values) => {
  const result = {};
  for (let i = 0, length = getLength(list); i < length; i++) {
    if (values) {
      result[list[i]?.replace(/\./g, '')] = values[i];
    } else {
      result[list[i][0]?.replace(/\./g, '')] = list[i][1];
    }
  }
  return result;
};
/* eslint-enable */

const getProjectType = (user, prop) => {
  if (user.ptype) {
    return user.ptype;
  }
  return prop.project && prop.project.type ? prop.project.type : '';
};

const hexToRgb = function (hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const torgb = function (c, opacity = true) {
  if (c) {
    if (c.indexOf('#') === 0) {
      // eslint-disable-next-line
      let { r, g, b, a } = hexToRgb(c);
      a = opacity ? 0.4 : a;
      const rgba = `rgba(${r},${g},${b},${a})`;
      return rgba;
    }
    return c;
  }
  return 'rgba(0,0,0,0.4)';
};

const getStyleObj = (prop, type, layout) => {
  if (!prop || !prop.draft) {
    return {};
  }

  const bf = prop.draft.bodyFont || {
    fontWeight: 400,
    fontFamily: prop?.language?.toLowerCase() === 'hebrew' ? 'Assistant' : 'Poppins',
  };
  const tf = prop.draft.titleFont || {
    fontWeight: 700,
    fontFamily: prop?.language?.toLowerCase() === 'hebrew' ? 'Assistant' : 'Poppins',
  };
  const sf = prop.draft.subTitleFont || {
    fontWeight: 400,
    fontFamily: prop?.language?.toLowerCase() === 'hebrew' ? 'Assistant' : 'Poppins',
  };
  switch (type) {
    case 'title1':
      return {
        fontFamily: tf.family,
        color: tf.color1,
        fontWeight: tf.weight,
        fontSize: tf.fontSize,
      };
    case 'title2':
      return {
        fontFamily: tf.family,
        color: tf.color2,
        fontWeight: tf.weight,
        fontSize: layout === 'custom' ? tf.subtitleFontSize || tf.fontSize : tf.fontSize,
      };
    case 'title3':
      return {
        fontFamily: tf.family,
        color: tf.color3,
        fontWeight: tf.weight,
        fontSize: tf.fontSize,
      };
    case 'title4':
      return {
        fontFamily: sf.family || 'Poppins',
        color: sf.color1 || 'gray',
        fontWeight: sf.weight || 400,
        fontSize: sf.fontSize || '18px',
      };
    case 'body1':
      return {
        fontFamily: bf.family,
        color: bf.color1,
        fontWeight: bf.weight,
        fontSize: bf.fontSize,
      };
    case 'body2':
      return {
        fontFamily: bf.family,
        color: bf.color2,
        fontWeight: bf.weight,
        fontSize: bf.fontSize,
      };
    default:
      return {};
  }
};

const taxdisfix = (chosen) => {
  _.each(chosen, (v) => {
    let tax;
    let dis;

    if (v?.tax) {
      if (v?.tax?.type === -1) {
        if (!v?.discount || v?.discount?.type === 1) {
          // so we switch
          dis = v?.tax;
        }
        v.tax = null; // remove now because its really discount
      }
    }
    if (v?.discount) {
      if (v?.discount?.type === 1) {
        // means it's reall tax
        if (!v?.tax || v?.tax?.type === -1) {
          // so we switch
          tax = v?.discount;
        }
        v.discount = null; // remove now because it's really tax
      }
    }

    if (dis) {
      v.discount = dis;
    }

    if (tax) {
      v.tax = tax;
    }
  });
};

const defaultSectionOrder = ['overview', 'whyme2', 'pricing2', 'milestones2', 'whatsnext', 'terms'];

const findSectionOrder = ({ sectionName, proposal, sectionId, position }) => {
  const sectionorder = proposal.draft.sectionorder || defaultSectionOrder;
  const nearestSectionIndex = sectionorder.indexOf(sectionName);
  let updatedSectionOrder = sectionorder;

  switch (position) {
    case 'belowHeader':
      updatedSectionOrder = [sectionId, ..._.rest(sectionorder, 0)];
      break;
    case 'aboveSignature':
      updatedSectionOrder = [..._.rest(sectionorder, 0), sectionId];
      break;

    case 'aboveNormal':
      updatedSectionOrder = [
        ..._.take(sectionorder, nearestSectionIndex),
        sectionId,
        ..._.rest(sectionorder, nearestSectionIndex),
      ];
      break;

    default:
      updatedSectionOrder = [
        ..._.take(sectionorder, nearestSectionIndex + 1),
        sectionId,
        ..._.rest(sectionorder, nearestSectionIndex + 1),
      ];
      break;
  }
  return updatedSectionOrder;
};

const findNewSectionPosition = ({ wrapperType, sectionAlignment }) => {
  if (wrapperType === 'normal' && sectionAlignment === 'above') {
    return 'aboveNormal';
  }
  if (wrapperType === 'header') {
    return 'belowHeader';
  }
  if (wrapperType === 'signature') {
    return 'aboveSignature';
  }
  if (sectionAlignment === 'above') {
    return 'aboveNormal';
  }
  return 'belowNormal';
};

const moveSectionOrder = ({ sectionorder, fromIndex, toIndex }) => {
  const updatedSectionOrder = sectionorder;
  const element = sectionorder[fromIndex];
  updatedSectionOrder.splice(fromIndex, 1);
  updatedSectionOrder.splice(toIndex, 0, element);
  return updatedSectionOrder;
};

const getProposalMeta = ({ proposal }) => {
  const sectionOrder =
    proposal && proposal.draft && proposal.draft.sectionorder ? proposal.draft.sectionorder : '';
  const headerSection =
    proposal && proposal.draft && proposal.draft.header ? proposal.draft.header : '';

  let titlehtml = headerSection && headerSection.title ? headerSection.title : '';
  if (headerSection.rawtitle) {
    const titlecs = convertFromRaw(headerSection.rawtitle);
    titlehtml = titlecs.getPlainText();
  }

  let getSection = 'overview';

  getSection = sectionOrder
    ? sectionOrder.find((order) => !proposal.draft[order]?.image && proposal.draft[order]?.text)
    : getSection;

  const overviewSection =
    proposal && proposal.draft && proposal.draft[getSection] ? proposal.draft[getSection] : '';

  let description = overviewSection?.text || '';
  if (overviewSection.raw) {
    const descriptioncs = convertFromRaw(overviewSection.raw);
    description = descriptioncs.getPlainText();
  }

  return { titlehtml, description, logo: headerSection.logo };
};

const ordinalSuffixOf = (i) => {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return `${i}st`;
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`;
  }
  return `${i}th`;
};

/* eslint-disable */
const porposalPdfLinkPath = (proposalId) => {
  var origin = location.origin;
  var firstletter = 'P';
  if (origin.indexOf('localhost') !== -1) {
    firstletter = 'L';
  } else if (origin.indexOf('staging') !== -1 || origin.indexOf('beta') !== -1) {
    firstletter = 'S';
  } else if (origin.indexOf('test') !== -1) {
    firstletter = 'T';
  } else if (origin.indexOf('goprospero') !== -1) {
    firstletter = 'P';
  }
  return firstletter + proposalId.slice(0, 5);
};

const getDefaultOptions = ({ user, prelist, config, prop }) => {
  const firstTwo =
    prelist && prelist[0] && prelist[0].name && prelist[1] && prelist[1].name
      ? [
          { name: prelist[0].name, id: uuidv4(), price: 0, type: null },
          { name: prelist[1].name, id: uuidv4(), price: 0, type: null },
        ]
      : [];
  return {
    'OPTION 1': { total: '', list: firstTwo },
    'OPTION 2': {
      total: '',
      list: [
        {
          id: uuidv4(),
          name: configText({
            x: 'Everything in option 1',
            notmarked: true,
            config,
            prop,
            user: user,
            vars: {},
            disableTrim: false,
          }),
          price: 0,
          type: null,
        },
      ],
    },
    'OPTION 3': {
      total: '',
      list: [
        {
          id: uuidv4(),
          name: configText({
            x: 'Everything in option 2',
            notmarked: true,
            config,
            prop,
            user: user,
            vars: {},
            disableTrim: false,
          }),
          price: 0,
          type: null,
        },
      ],
    },
  };
};

export const scrollToSection = ({ sectionId, editorState }) => {
  if (sectionId) {
    // scroll to new section
    setTimeout(() => {
      const sectionEl = document.querySelector(`[id="${sectionId}"]`);
      if (sectionEl) {
        sectionEl.scrollIntoView({
          behavior: 'smooth',
        });
        sectionEl.classList.add('animate-simple-section');
        setTimeout(() => sectionEl.classList.remove('animate-simple-section'), 500);
      }
    }, 500);
  } else if (editorState) {
    // scroll to section element using there block key
    const contentState = editorState.getCurrentContent();
    const currentSelectionKey = contentState.getSelectionAfter()?.anchorKey;
    const elementSelectionKey = contentState.getKeyBefore(currentSelectionKey);

    setTimeout(() => {
      const sectionEl = document.querySelector(`[data-offset-key="${elementSelectionKey}-0-0"]`);

      if (sectionEl) {
        sectionEl.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        sectionEl.classList.add('animate-simple-section');
        setTimeout(() => sectionEl.classList.remove('animate-simple-section'), 500);
      }
    }, 500);
  }
};

export const loadProposalFont = (prelist, language) => {
  const fontsDefault = [];
  prelist.forEach((f) => {
    f.weights.forEach((w) => {
      fontsDefault.push({
        family: `${f.family}:${w}${
          language?.toLowerCase() === 'hebrew' && f.family !== 'Arial' ? ':hebrew' : ''
        }`,
        source: f.source,
      });
    });
  });

  const googleFonts = _.compact(fontsDefault.map((f) => f.source === 'Google' && f.family));
  const fontsToLoad = {
    custom: {
      families: ['Open Sans Hebrew'],
    },
  };

  if (googleFonts.length) {
    fontsToLoad['google'] = {
      families: googleFonts,
    };
  }

  WebFont.load(fontsToLoad);
};

export const getLanguageCode = (l) => {
  const languages = {
    dutch: 'nl',
    english: 'en-US',
    german: 'de',
    french: 'fr',
    hebrew: 'he',
  };

  return languages[l] || 'en-US';
};

export const priceToNumber = ({ prop, price, curr }) => {
  price = price.toString();
  const stringWithoutCurr = price.replace(curr, '');
  let removeSpace = stringWithoutCurr.replace(' ', '');
  // Remove non-numeric characters except dot and comma
  let numericString = removeSpace.replace(/[,\.](?=[^,\.]*[,,\.])/g, '');
  let numericValue = Number(numericString);

  if (prop.priceSeperator === '.') {
    removeSpace = numericString.replace(',', '.');
    numericValue = Number(removeSpace);
  }

  return numericValue;
};

export default {
  commafi,
  cap,
  upr,
  fmt,
  concatcurr,
  parseFloatCurr,
  configText,
  onlyNumSeperator,
  objectPairs,
  getProjectType,
  torgb,
  getStyleObj,
  taxdisfix,
  findSectionOrder,
  findNewSectionPosition,
  moveSectionOrder,
  getProposalMeta,
  ordinalSuffixOf,
  defaultSectionOrder,
  porposalPdfLinkPath,
  getDefaultOptions,
  getApprovedDate,
  priceToNumber,
};
