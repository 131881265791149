import React, { useState, useCallback, useEffect, useRef } from 'react';
import { ContentState } from 'draft-js';
import { ResizableBox } from 'react-resizable';
import PropTypes from 'prop-types';

import getCroppedImg from './helpers/cropImage';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';
import Uploader from 'components/Uploader';
import Loader from 'components/Loader';
import helpers from 'helpers';
import { ImageEditor } from './components';

import './ImageComponent.scss';
import 'react-resizable/css/styles.css';

const INFINITY = 30000;

const ImageComponent = ({ block, blockProps }) => {
  const contentState = ContentState.createFromBlockArray([block]);
  const ent = block.getEntityAt(0);
  const entity = contentState.getEntity(ent);
  const isSection = blockProps.type === 'section-image';
  const resizefactor = blockProps.resizefactor || 1;
  const [resizeWidth, setResizeWidth] = useState((blockProps.resizeWidth || 530) / resizefactor);
  const [loadedDimension, setLoadedDimension] = useState(null);
  const imageInputRef = useRef();

  const { data } = entity;
  const imgContent = helpers.formatS3Link(data?.src);
  const [config, setConfig] = useState({
    editable: true,
    size: { width: resizeWidth, height: INFINITY, ratio: 1 },
    alignment:
      blockProps.language === 'hebrew' || blockProps.language === 'arabic' ? 'right' : 'left',
    border: data?.config?.border || {},
    shadow: data?.config?.shadow || {},
  });
  const [hyperlink, setHyperlink] = useState(data?.hyperlink || '');

  const [editMode, setEditMode] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [activeWidth, setActiveWidth] = useState({
    id: 0,
    width: 1,
    height: 1,
  });
  const [opacity, setOpacity] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(imgContent);
  const [shadow, setShadow] = useState(config?.shadow || {});
  const [border, setBorder] = useState(config?.border || {});
  const [isSaving, setIsSaving] = useState(false);
  const [hideImage, setHideImage] = useState(false);

  const [upload] = Uploader();

  const optionList = [
    { id: 0, width: 1, height: 1, label: '1:1' },
    { id: 1, width: 3, height: 4, label: '3:4' },
    { id: 2, width: 4, height: 3, label: '4:3' },
    { id: 3, width: 0, height: 0, label: 'Full' },
  ];

  useEffect(() => {
    if (data?.config) {
      setConfig(JSON.parse(JSON.stringify(data.config)));
    }
  }, [data]);

  useEffect(() => {
    if (isSection) {
      setResizeWidth(900);
    } else {
      // NOTE: on blockProps.titleFontPosition change useEffect is not happening
      const { titleFontPosition } = blockProps;
      // on changing title font position change max width
      const _width = ['top', 'top-middle', 'none'].includes(titleFontPosition)
        ? 796
        : titleFontPosition === 'large'
        ? 664
        : 530;
      setResizeWidth(_width / resizefactor);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockProps.titleFontPosition, resizefactor]);

  useEffect(() => {
    // on section layout chnage
    if (resizeWidth && !isSection) {
      setConfig((prev) => {
        const newConfig = { ...prev };

        // when column width changes and width of old image
        // is bigger than new column width change old width & height
        if (!newConfig?.size?.width || newConfig.size.width > resizeWidth) {
          if (!newConfig?.size) {
            newConfig.size = {};
          }
          newConfig.size.width = resizeWidth;

          if (newConfig.size?.ratio) {
            newConfig.size.height = newConfig.size.width / newConfig.size.ratio;
          } else {
            let ratio = 1;
            if (prev?.size?.width) {
              ratio = getRatio(prev.size.width, prev.size.height);
            } else if (loadedDimension?.width) {
              ratio = getRatio(loadedDimension.width, loadedDimension.height);
            }
            newConfig.size.height = newConfig.size.width / ratio;
          }
        }

        return newConfig;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resizeWidth]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const saveImage = useCallback(
    async (file) => {
      const entityKey = block.getEntityAt(0);
      if (entityKey) {
        const { url } = await upload(file, `props/${blockProps.proposalId}/section`);
        const newData = JSON.parse(JSON.stringify(data));
        newData.src = url;
        contentState.replaceEntityData(entityKey, { ...newData });
        blockProps.update();
        setCroppedImage(url);
        setEditMode(false);
        setIsSaving(false);
      } else {
        setIsSaving(false);
      }
    },
    [block, blockProps, data, upload, contentState]
  );

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imgContent, croppedAreaPixels, rotation, opacity);
      saveImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation, imgContent, opacity, saveImage]);

  const handleRemove = () => blockProps?.onRemove(block.getKey());

  const handleEdit = (value = true) => setEditMode(typeof value === 'object' ? true : value);

  const handleBlur = () => blockProps.handleEditComponent(false);

  const onSave = () => {
    setIsSaving(true);
    showCroppedImage();
  };

  const saveShadow = (value) => {
    const entityKey = block.getEntityAt(0);
    if (entityKey && value) {
      const newData = data;
      newData.config = JSON.parse(JSON.stringify({ ...newData.config, shadow: value }));
      contentState.replaceEntityData(entityKey, { ...newData });
      blockProps.update(true);
    }
  };

  const saveBorder = (value) => {
    const entityKey = block.getEntityAt(0);
    if (entityKey && value) {
      const newData = data;
      newData.config = JSON.parse(JSON.stringify({ ...newData.config, border: value }));
      contentState.replaceEntityData(entityKey, { ...newData });
      blockProps.update(true);
    }
  };

  const onReplace = () => {
    imageInputRef.current.click();
  };

  const onImageSelect = async (event) => {
    event.preventDefault();
    const { files } = event.target;
    if (files && !files.length) return;
    const entityKey = block.getEntityAt(0);
    if (entityKey) {
      const { url } = await blockProps.upload(files[0], `props/${blockProps.proposalId}/section`);
      const newData = JSON.parse(JSON.stringify(data));
      newData.src = url;
      delete newData?.config?.size;
      contentState.replaceEntityData(entityKey, {
        ...newData,
      });
      setHideImage(true);
      blockProps.update();
      delete config?.size;
      setConfig({ ...config });
      setCroppedImage(url);
      setHideImage(false);
    } else {
      setIsSaving(false);
    }
  };

  const boxShadow = `rgba(${shadow?.hueColor?.rgb?.r || 0},${shadow?.hueColor?.rgb?.g || 0},${
    shadow?.hueColor?.rgb?.b || 0
  },${shadow?.alphaColor?.rgb?.a || 1}) ${shadow.xOffset || 0}px ${shadow.yOffset || 0}px ${
    shadow.blur || 0
  }px ${shadow.spread || 0}px`;

  const borderStyle =
    `${border.borderWidth || 0}px solid ${border.borderColor?.hex || 'black'}` || 'none';
  const borderRadiusStyle = `${border.borderRadius}px` || '0px';

  const saveResize = (value, _config) => {
    const entityKey = block.getEntityAt(0);
    if (entityKey && value) {
      const newData = data || {};
      if (_config) {
        newData.config = _config;
      } else {
        if (newData.config === undefined) {
          newData.config = {};
        }
        newData.config.size = JSON.parse(
          JSON.stringify({
            ...config.size,
            width: value?.width,
            height: value?.height,
            ratio: getRatio(value?.width, value?.height),
          })
        );
      }
      contentState.replaceEntityData(entityKey, { ...newData });
      blockProps.update(true);
    }
  };

  const onResize = (e, resizeData) => {
    e.preventDefault();

    setConfig((prev) => {
      const height = resizeData.size.height || prev.size?.height;

      return {
        ...prev,
        size: {
          ...prev.size,
          width: resizeData.size.width || prev.size?.width,
          height,
          // maxHeight: height,
        },
      };
    });
  };

  const onResizeStart = (e) => {
    e.preventDefault();
    setEditMode(false);
  };

  const onResizeStop = (e, resizeData) => {
    e.preventDefault();
    saveResize(resizeData.size, config);
  };

  const handleImageAlignment = (alignment) => {
    const entityKey = block.getEntityAt(0);
    if (entityKey && alignment) {
      const newData = data;
      if (!data?.config?.size) {
        newData.config = { ...data.config, size: config.size };
      }
      newData.config = JSON.parse(JSON.stringify({ ...newData.config, alignment: alignment }));
      contentState.replaceEntityData(entityKey, { ...newData });
      setConfig({ ...newData.config, alignment: alignment });
      blockProps.update(true);
    }
  };

  const getRatio = (width, height) => {
    return parseFloat((width / height).toFixed(2));
  };

  const handleOnLoad = (e) => {
    const { offsetHeight: height, offsetWidth: width } = e.target;
    setLoadedDimension({ height, width });

    if (!data?.config?.size?.width || !data?.config?.size?.height) {
      // w x h , 100 x 50 = ratio 2
      // w = 40
      // h = ? W/ratio

      const _config = {
        ...config,
        editable: true,
        size: {
          width,
          height,
          maxHeight: height,
          ratio: getRatio(width, height), // preserve the original aspect ratio
        },
        alignment:
          data?.config?.alignment ||
          (blockProps.language === 'hebrew' || blockProps.language === 'arabic' ? 'right' : 'left'),
      };

      setConfig(_config);
      saveResize(_config.size, _config);
      optionList[3] = { ...optionList[3], width, height };
    }
  };

  const handleHyperLinkSave = (value) => {
    const entityKey = block.getEntityAt(0);
    if (entityKey) {
      const newData = data;
      newData.hyperlink = value;
      contentState.replaceEntityData(entityKey, { ...newData });
      setHyperlink(value);
      blockProps.update(true);
    }
  };

  // find width
  let width = isSection
    ? 900
    : (config?.size?.width && window?.screen?.width > 480) ||
      (window?.screen?.width < 480 && config?.size?.width < 360)
    ? config?.size?.width || resizeWidth
    : INFINITY;
  width = Math.min(window.innerWidth - 62, resizeWidth, width);

  // find ratio
  let ratio = isSection
    ? 1
    : config?.size?.ratio
    ? config.size.ratio
    : getRatio(config?.size?.width || resizeWidth, config?.size?.height || 400);

  // find height
  let height = isSection ? config?.size?.height || INFINITY : width / ratio;

  const maxWidth = resizeWidth;
  const maxHeight = isSection ? INFINITY : maxWidth / ratio;

  return (
    <>
      <ComponentWrapper
        sectionName={blockProps.sectionName}
        setDraggingElement={blockProps.setDraggingElement}
        showDrag={isSection ? false : true}
        showActionButtons={true}
        showImageActions={true}
        config={config}
        shadow={shadow}
        hyperlink={hyperlink}
        border={border}
        showEdit={!editMode}
        showDelete={true}
        isDraggable={true}
        blockKey={block.getKey()}
        remove={handleRemove}
        onEdit={imgContent.endsWith('.gif') ? null : handleEdit}
        showSave={editMode}
        onSave={onSave}
        onCancel={() => handleEdit(false)}
        onBlur={handleBlur}
        onShadowChange={setShadow}
        saveShadow={saveShadow}
        onBorderChange={setBorder}
        saveBorder={saveBorder}
        onReplace={onReplace}
        componentType="Image"
        handleEditComponent={blockProps.handleEditComponent}
        isSaving={isSaving}
        onHyperLinkSave={handleHyperLinkSave}
        handleImageAlignment={handleImageAlignment}
        setDropDisabled={blockProps.setDropDisabled}
        dispatch={blockProps.dispatch}
        isResizable
        componentWrapperStyle={editMode ? {} : { width: width, height: height + 35 }}>
        {editMode ? (
          <div className="image-component-container img-249">
            {isSaving && <Loader overlay />}
            <ImageEditor
              optionList={optionList}
              imgContent={imgContent}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              opacity={opacity}
              activeWidth={activeWidth}
              setCrop={setCrop}
              setRotation={setRotation}
              onCropComplete={onCropComplete}
              setZoom={setZoom}
              setOpacity={setOpacity}
              setActiveWidth={setActiveWidth}
            />
          </div>
        ) : !imgContent ? (
          <div className="gallery-container" onClick={blockProps.editComponent}>
            <div className="gallery-image-container">
              <div className="gallery-uploader">
                <div>Upload Image</div>
              </div>
            </div>
          </div>
        ) : (
          <ResizableBox
            draggableOpts={{ offsetParent: document.body, grid: [2, 2] }}
            lockAspectRatio={isSection ? false : true}
            axis={'both'}
            width={width}
            height={height}
            onResize={onResize}
            onResizeStart={onResizeStart}
            onResizeStop={onResizeStop}
            resizeHandles={
              isSection
                ? ['s']
                : blockProps.language === 'hebrew' || blockProps.language === 'arabic'
                ? ['sw']
                : ['se']
            }
            minConstraints={
              config?.size?.width > config?.size?.height
                ? [(config?.size?.width / config?.size?.height) * 50, 50]
                : [50, (config?.size?.height / config?.size?.width) * 50]
            }
            maxConstraints={[maxWidth, isSection ? INFINITY : maxHeight]}
            className={`image-component-container img-311 ${
              height === INFINITY ? 'infinity-height' : ''
            }`}>
            {!hideImage && (
              <img
                src={croppedImage}
                onLoad={handleOnLoad}
                alt="cropped"
                style={{
                  width: isSection ? '100%' : `calc(100% - 2px)`,
                  aspectRatio:
                    (isSection && height === INFINITY) ||
                    (!isSection && config?.size?.height === INFINITY)
                      ? 'inherit'
                      : isSection
                      ? `${width} / ${height - 2}`
                      : `${config?.size?.width || 900} / ${config?.size?.height - 1}`,
                  boxShadow: boxShadow,
                  border: borderStyle,
                  borderRadius: borderRadiusStyle,
                }}
              />
            )}
          </ResizableBox>
        )}
      </ComponentWrapper>
      <input
        type="file"
        ref={imageInputRef}
        style={{ display: 'none' }}
        onChange={onImageSelect}
        accept="image/*"
      />
    </>
  );
};

ImageComponent.propTypes = {
  block: PropTypes.instanceOf(Object).isRequired,
  blockProps: PropTypes.instanceOf(Object).isRequired,
};

export default ImageComponent;

/* put on image wrap when enabling width editing: style={{width: data.width || '30%' }} */
