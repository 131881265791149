import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.70706 6.00002L6.85474 10.1482C7.05 10.3435 7.05 10.6601 6.85474 10.8553C6.65947 11.0506 6.34289 11.0506 6.14763 10.8553L0.792847 5.50002L6.14763 0.153283C6.34289 -0.0419796 6.65947 -0.0419796 6.85474 0.153283C7.05 0.348545 7.05 0.665127 6.85474 0.860389L2.70706 5.00002H15V6.00002H2.70706Z"
      fill="#000624"
    />
  </svg>
);

const ArrowLeftIcon = (props) => <Icon component={svg} {...props} />;

export default ArrowLeftIcon;
