import { gql } from '@apollo/client';

export const DELETE_PROPOSAL_MUTATION = gql`
  mutation DeleteProposalMutation($pid: String!, $topic: String!, $remove: Boolean) {
    deleteProposal(topic: $topic, pid: $pid, remove: $remove)
  }
`;

export const UPDATE_PROPOSAL_MUTATION = gql`
  mutation updateProposalMutation(
    $proposal: ProposalInput!
    $updateType: String!
    $topic: String!
  ) {
    updateProposal(topic: $topic, updateType: $updateType, proposal: $proposal) {
      _id
      pid
      uid
      auid
      name
      state
      draft
      email
      channel
      client {
        contact
        name
        desc
        address
        text
        email
      }
      seenTime
      seenCount
      lastSeen
      openLogs {
        date
        clientIP
        duration
        watched
        deviceInfo {
          deviceType
          deviceOS
          deviceBrowser
          deviceName
          deviceAgent
        }
      }
      date
      project {
        name
        type
        othertype
        name
        desc
        goals
      }
      version
      sawyou
      pnum
      pricing
      deliverables
      milestones
      sawmilestones
      superPowers
      curr
      priceSeperator
      decimalPrecision
      language
      expiryDate
      expiryMessage
      currency
      callButton
      contactButton
      contactnumber
      enablePayment
      clientPayableAmount
      clientPayableLabel
      clientPayableCurrency
      clientPayableLink
      userway
      scripts
      chatway
      chatwayScripts
      stripe
      payment {
        status
        amount
        currency
        payment_intend
        created
      }
      published
      disableSig
      signatureEmails
      selectedOption
      prevState
      payedfor
      redirectTo
      redirectAfter
      proposalRedirectAfter
      proposalRedirectTo
      sigOptions
      contactOptions {
        option
        value
        enabled
      }
      sigtype
      signature {
        name
        myname
        additionalSignature
        signatureBackgroundColor
        signatureTextColor
        additionalSignatureBackgroundColor
        additionalSignatureTextColor
        email
        date
        clientIP
        device
      }
      attachments {
        name
        type
        url
        size
      }
      isEditing {
        name
        uid
        tabID
      }
      wixConnectedInstance
      importedProposal
      fromUserTemplate
      spamUrls {
        url
        blocked
      }
      wixProposalId
      isBasicTemplate
      wixPricing {
        lineItems {
          quantity
          name
          description
          price
          pricesBreakdown {
            totalPriceAfterTax
            taxDetails {
              taxRate
              taxableAmount
              taxable
              totalTax
              taxName
              rateBreakdown {
                rate
                taxableAmount
                tax
              }
            }
            totalDiscount
            price
            priceBeforeDiscounts
            lineItemPrice
            fullPrice
          }
          itemType {
            preset
          }
        }
        appliedDiscounts {
          discountType
          lineItemIds
        }
        additionalFees {
          name
          priceBeforeTax
          taxDetails {
            taxRate
            taxableAmount
            taxable
            totalTax
            taxName
            rateBreakdown {
              rate
              taxableAmount
              tax
            }
          }
          price
        }
        calculatedTotals {
          priceSummary {
            total
            subtotal
            discount
            additionalFees
          }
        }
        paymentSettings {
          type
          singlePaymentSchedule {
            dueDate
            dueDateOffset
            issueDate
            deposit {
              percentage
              amount
            }
            paymentStatus
            amountPaid
          }
          multiplePaymentSchedule {
            items {
              dueDate
              dueDateOffset
              issueDate
              deposit {
                percentage
                amount
              }
              issueDateOffset
              title
              percentage
              amount
              paymentStatus
              amountPaid
            }
          }
        }
        currencyCode
        locale {
          country
          language
        }
      }
      isWixTemplate
      tags
    }
  }
`;

export const UPDATE_PROPOSAL_CLIENT_MUTATION = gql`
  mutation updateProposalClientMutation(
    $proposal: ProposalClientInput!
    $updateType: String!
    $topic: String!
    $origin: String
  ) {
    updateProposalClient(
      topic: $topic
      updateType: $updateType
      proposal: $proposal
      origin: $origin
    ) {
      _id
      pid
      uid
      auid
      name
      state
      channel
      draft
      email
      pnum
      client {
        contact
        name
        desc
        address
        text
        email
      }
      seenTime
      seenCount
      lastSeen
      openLogs {
        date
        clientIP
        duration
        watched
        deviceInfo {
          deviceType
          deviceOS
          deviceBrowser
          deviceName
          deviceAgent
        }
      }
      date
      project {
        name
        type
        othertype
        name
        desc
        goals
      }
      version
      sawyou
      pricing
      deliverables
      editEvent
      milestones
      sawmilestones
      superPowers
      curr
      priceSeperator
      decimalPrecision
      language
      expiryDate
      expiryMessage
      currency
      callButton
      contactButton
      contactnumber
      enablePayment
      clientPayableAmount
      clientPayableLabel
      clientPayableCurrency
      clientPayableLink
      scripts
      chatwayScripts
      stripe
      payment {
        status
        amount
        currency
        payment_intend
        created
      }
      published
      disableSig
      signatureEmails
      selectedOption
      prevState
      payedfor
      redirectTo
      redirectAfter
      proposalRedirectTo
      proposalRedirectAfter
      sigOptions
      contactOptions {
        option
        value
        enabled
      }
      sigtype
      signature {
        name
        myname
        additionalSignature
        signatureBackgroundColor
        signatureTextColor
        additionalSignatureBackgroundColor
        additionalSignatureTextColor
        email
        date
        clientIP
        device
      }
      attachments {
        name
        type
        url
        size
      }
      isEditing {
        name
        uid
        tabID
      }
      fromUserTemplate
      importedProposal
      spamUrls {
        url
        blocked
      }
      isBasicTemplate
      spamUrls {
        url
        blocked
      }
      wixProposalId
      wixPricing {
        lineItems {
          quantity
          name
          description
          price
          pricesBreakdown {
            totalPriceAfterTax
            taxDetails {
              taxRate
              taxableAmount
              taxable
              totalTax
              taxName
              rateBreakdown {
                rate
                taxableAmount
                tax
              }
            }
            totalDiscount
            price
            priceBeforeDiscounts
            lineItemPrice
            fullPrice
          }
          itemType {
            preset
          }
        }
        appliedDiscounts {
          discountType
          lineItemIds
        }
        additionalFees {
          name
          priceBeforeTax
          taxDetails {
            taxRate
            taxableAmount
            taxable
            totalTax
            taxName
            rateBreakdown {
              rate
              taxableAmount
              tax
            }
          }
          price
        }
        calculatedTotals {
          priceSummary {
            total
            subtotal
            discount
            additionalFees
          }
        }
        paymentSettings {
          type
          singlePaymentSchedule {
            dueDate
            dueDateOffset
            issueDate
            deposit {
              percentage
              amount
            }
            paymentStatus
            amountPaid
          }
          multiplePaymentSchedule {
            items {
              dueDate
              dueDateOffset
              issueDate
              deposit {
                percentage
                amount
              }
              issueDateOffset
              title
              percentage
              amount
              paymentStatus
              amountPaid
            }
          }
        }
        currencyCode
        locale {
          country
          language
        }
      }
      isWixTemplate
    }
  }
`;

export const CREATE_PROPOSAL_MUTATION = gql`
  mutation createProposal(
    $pid: String!
    $topic: String!
    $duplicate: DuplicateProposalInput
    $template: TemplateProposalInput
    $importedProposal: JSON
    $additionalInfo: AdditionalProposalInput
  ) {
    createProposal(
      pid: $pid
      topic: $topic
      duplicate: $duplicate
      template: $template
      importedProposal: $importedProposal
      additionalInfo: $additionalInfo
    ) {
      _id
      pid
      uid
      cid
      name
      date
      pnum
    }
  }
`;

export const CHECK_VIDEO_OEMBED_MUTATION = gql`
  mutation checkVideoOembedMutation($link: String!) {
    handleOembed(link: $link)
  }
`;

export const ADD_PROPOSAL_ATTACHMENTS = gql`
  mutation addProposalAttachmentMutation(
    $proposalId: String!
    $attachments: [ProposalAttachmentInput!]!
    $topic: String!
    $type: String!
  ) {
    addProposalAttachment(
      topic: $topic
      attachments: $attachments
      proposalId: $proposalId
      type: $type
    ) {
      _id
      attachments {
        name
        type
        url
        size
      }
    }
  }
`;

export const DELETE_PROPOSAL_ATTACHMENT = gql`
  mutation deleteProposalAttachmentMutation($proposalId: String!, $index: Float!, $topic: String!) {
    deleteProposalAttachment(topic: $topic, proposalId: $proposalId, index: $index) {
      _id
      attachments {
        name
        type
        url
        size
      }
    }
  }
`;

export const DELETE_PROPOSAL_FILES = gql`
  mutation deleteProposalFiles($urls: [String!]!) {
    deleteProposalFiles(urls: $urls)
  }
`;
