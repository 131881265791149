import React, { memo, useState, useEffect, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col } from 'antd';
import images from 'constants/images';
import './Testmonial.scss';
import Path from 'routes/path';

const testimonialContent = [
  {
    owner: 'Lizzie Karmi, CEO, n.e.f - Business automation',
    content: `From the first day I started using Prospero I couldn't believe it - my proposals' conversion rates increased significantly! Love how easy it is to generate clear and winning proposals.`,
    image: images.TESTIMONIAL_1,
  },
  {
    owner: 'Hadar Chen, Co-founder, Hunt Digital Marketing',
    content: `Prospero is helping us to achieve exhilarating and invigorating results. 
    We're receiving more qualified leads and higher conversion rates, 
    followed by an explicit WOW reaction from our clients. 
    It's a great boost to any business and it's easy to use!`,
    image: images.TESTIMONIAL_3,
  },
  {
    owner: 'Hadar Chen, Co-founder, Hunt Digital Marketing',
    content: `Prospero is helping us to achieve exhilarating and invigorating results.
      We're receiving more qualified leads and higher conversion rates,
      followed by an explicit WOW reaction from our clients.
      It's a great boost to any business and it's easy to use!`,
    image: images.TESTIMONIAL_4,
  },
  {
    owner: 'Almog Ben Atar, Co-founder, Hunt Digital',
    content: `Prospero is helping us to achieve exhilarating and invigorating results.
      We're receiving more qualified leads and higher conversion rates,
      followed by an explicit WOW reaction from our clients.
      It's a great boost to any business and it's easy to use!`,
    image: images.TESTIMONIAL_5,
  },
  {
    owner: 'Itay Verchik, CEO & Marketing Director, IVBS',
    content: `Since we started using Prospero, our closing percentages have risen miraculously 
    and our customers are also impressed by the designed contracts they receive.
    My team has a more orderly follow-up on the contracts and 
    we also know what works better and what less well thanks to Prospero.`,
    image: images.TESTIMONIAL_6,
  },
];

const generateNumber = () => {
  var randNum = [Math.floor(Math.random() * 5)];
  return randNum[0] === 2 ? generateNumber() : randNum;
};

const Testimonial = memo((fromPage) => {
  const [changeLogs, setChangeLogs] = useState([]);

  useEffect(() => {
    window.HW_config = {
      selector: '#headway-features',
      account: 'JVD2Dx',
      callbacks: {
        onWidgetReady: function (widget) {
          if (widget.changelogs) setChangeLogs(widget.changelogs);
        },
      },
    };

    const script = document.createElement('script');
    script.src = 'https://cdn.headwayapp.co/widget.js';
    script.async = true;
    script.id = 'headwayappScript';
    document.body.appendChild(script);

    return () => {
      const element = document.getElementById('headwayappScript');
      if (element) {
        element.parentElement.removeChild(element);
      }
      const badge = document.getElementById('HW_badge_cont');
      if (badge) {
        badge.parentElement.removeChild(badge);
      }
    };
  }, [fromPage]);

  let randomNumber = useMemo(() => generateNumber(), []);

  if (fromPage.page === 'ForgotPassword' || fromPage.page === 'ResetPassword') {
    randomNumber = 2;
  }
  return (
    <>
      <Col className="testimonial-container" md={10} lg={10} xl={10}>
        <>
          <div className="testimonial2">
            <div className="logo">
              <NavLink to={Path.HOME}>
                <img src={images.LOGO} alt="prospero-logo" height="25" />
              </NavLink>
            </div>
            <div className="testimonial-wrapper">
              <div className="testimonial-image">
                <div className="testimonial-cover-block">
                  <div className="testimonial-cover">
                    <img
                      src={testimonialContent[randomNumber].image}
                      alt="prospero-testifier"
                      height="260"
                    />
                  </div>
                </div>
              </div>
              <div className="testimonial-quote">
                <p>&nbsp;</p>
                <div className="testimonial-message">
                  {testimonialContent[randomNumber].content}
                </div>
                <div className="testimonial-by">{testimonialContent[randomNumber].owner}</div>
              </div>
              <div className="new-features">
                <h4 className="heading">{changeLogs.length ? 'New Features' : ''}</h4>
                <Col span={changeLogs.length ? 24 : 0}>
                  <Row id="headway-features">
                    {changeLogs.length ? (
                      <Col>
                        {changeLogs.map((log, logIndex) => {
                          return (
                            <Row key={logIndex} className="testWord">
                              <Col className="leftWord">
                                <a href={log.url} target="_blank" rel="noreferrer">
                                  {log.title}
                                </a>
                                <span className="testBody">{log.content.short}</span>
                              </Col>
                            </Row>
                          );
                        })}
                      </Col>
                    ) : null}
                  </Row>
                </Col>
              </div>
            </div>
          </div>
        </>
      </Col>
    </>
  );
});

export default Testimonial;
