import removeTeXBlock from './removeTeXBlock';

const removeTeX = async ({
  blockKey,
  state,
  props,
  dispatch,
  editorChange,
  updateSelection,
  updateLoaderState,
  editorRef,
}) => {
  const { liveTeXEdits } = state;
  if (updateLoaderState) {
    updateLoaderState(true);
  }
  const es = await removeTeXBlock({ editorState: editorRef.current, blockKey });

  editorRef.current = es;
  if (!Array.isArray(blockKey)) {
    dispatch({
      type: 'setLiveTeXEdits',
      liveTeXEdits: liveTeXEdits.remove(blockKey),
    });
  }
  dispatch({ type: 'setEditorState', editorState: es });
  dispatch({
    type: 'setEditorReadOnly',
    editorReadOnly: false,
  });

  editorChange(
    {
      editorState: es,
      props,
      dispatch,
      updateSelection,
      editorRef,
    },
    () => {
      if (updateLoaderState) {
        updateLoaderState(false);
      }
    }
  );
};

export default removeTeX;
